import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const Headline = styled.div`
font-size: 24pt;
font-weight: bold;
`;

export default function HeadlineFunction({children}) {
    return (
      <>
        <Headline>
            {children}
        </Headline>
      </> 
        
      );
}
