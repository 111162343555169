import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import { Link } from "react-router-dom";

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);

  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;
flex-direction: column;
`;

const TextHeader = styled.h2`
margin-top: 40px;
text-align: center;
`;

const TextHeader2 = styled.h3`
    margin-top: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const Text = styled.p`
    line-height: 100%;
`;

const Text2 = styled.div`
    
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 18px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 80%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

export default function EB8() {

    return (
        <>
        <PageWrapper2>
          
          <TextHeader> 
            Für wen ist das ReLife-Coaching
          </TextHeader> 
          <TextBox>
            <Text>Das ReLife Coaching ist für dich geeignet wenn...</Text>   
            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>
                        du deine Gesundheit selbst in die Hand nehmen möchtest und bereit bist Verantwortung für dein Handeln zu übernehmen.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du bereit bist, dein bisheriges Wissen zu hinterfragen, alte Überzeugungen zu verwerfen und offen bist Neues zu lernen.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du bereit bist 100% deiner Energie zu geben, um wirkliche Veränderung zu schaffen.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du motiviert bist deinen Körper & Geist tiefer zu verstehen und gerne deinen Horizont erweiterst.  
                    </Text>
                </Schritt1>
                </Schritte>
                <br />
                <br />
                <Text>Das ReLife Coaching ist <b><u>nicht</u></b> für dich geeignet, wenn…</Text>
                <Schritte>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du nach einer schnellen Lösung für deine Probleme suchst ohne groß etwas dafür zu tun.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du es bevorzugst in der „Opfer-Rolle“ zu bleiben und jede Option nutzt deine Verantwortung an externe Umstände abzugeben. 
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du nur kurzfristige Erfolge erzielen willst.
                    </Text>
                </Schritt1>
            </Schritte>
          </TextBox> 
          <TextBox>
            <TextHeader>Mache jetzt den ersten Schritt.</TextHeader>
            <Text2>
            Wir sind hier um dir zu helfen - den ersten Schritt musst jedoch du gehen.<br />
            Wenn du dich dazu entscheidest mit uns zu arbeiten, geben wir 120%, damit du deine Ziele erreichst.
            <br /> <br />
            Damit wir unseren Kunden die höchste Qualität garantieren können, sind die Plätze unseres Coachings streng limitiert!<br />
            In unserer Zusammenarbeit öffnen wir dir die Türen zu einer ganz neuen Welt.<br />
            Deine Perspektive auf Gesundheit wird sich für immer verändern!
            <br /><br />
            Beweise den Mut Veränderung in dein Leben zu bringen & erfahre was es bedeutet dein volles Potential zu leben!
            <br /><br />
            Buche jetzt dein unverbindliches Erstgespräch uns lass uns gemeinsam herausfinden, wie wir dich auf diesem Weg begleiten können!
            <br /><br />
            </Text2>
          </TextBox>
          <TextBox>
            <TextHeader>Unser Versprechen an dich.</TextHeader>
            <Text2>
                Mit ReLife haben wir es uns zur Lebensaufgabe gemacht, möglichst vielen Menschen zu neuer Lebensenergie und  Gesundheit zu verhelfen. - Hier nehmen wir uns Zeit für dich und deine Bedürfnisse. 
                <br /><br />
                Wir sind der Überzeugung, dass die Beziehung zwischen Coach und Klient auf Zusammenarbeit, Harmonie und Vertrauen beruhen sollte. <br />
                <br />
                <TextHeader2>”Uns ist es wichtig, dass du dich wohl fühlst!”</TextHeader2>
                <br />
                In unserem Coaching nehmen wir dich an die Hand und versprechen dir, dich dort abzuholen wo du gerade stehst. <br />
                Gemeinsam arbeiten wir in deinem Tempo & nach deinen Wünschen. 
                <br /><br />
                Wir sorgen dafür, dass dieser Prozess dir Freude bereitet und gleichzeitg nachhaltige Veränderung schafft. <br />
                <b>Das ist unser Versprechen an dich.</b> 


            </Text2>
          </TextBox>
          <br />
          <MiddleButton>
            <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenloses Erstgespräch</Button2>
           </MiddleButton>
        </PageWrapper2>
        
        
        </>
      );
}