import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import Mailinglist from '../components/MailingList';
import RootsOverview from '../components/RootsOverview';
import AboutUsOverview from '../components/AboutUsOverview';
import Referenzen from '../components/Referenzen';
import ImageSlider from "../components/ImageSlider";
import {useInView} from "react-intersection-observer"
import styled from "styled-components";

const FliegNicht = styled.div`
    background-color:beige;
    transform: translateX(-50%);
    `;

const Flieg = styled.div`
    color: #79553C;
    background-color: beige;
    transition: transform 4s ease-in translateX(0);
    animation-name: slidein;
    animation-duration: 3s;

@keyframes slidein{
    from{
        transform: translateX(-50%);
        color: transparent;
    }
    to{
        transform: translateX(0);
        color: #79553C;
    }
}
`;

    const FliegenWrapper = styled.div`
    height: 80vh;
    background-color: #FEF5EC;
    color: transparent;
    `;

function Blog() {

    const {ref: flyingText, inView: TextIsVisible} = useInView({ triggerOnce:true, rootMargin: "-20px -20px -50% -20px"});

    

  return (
    <>
    <Router>
    <ImageSlider />
    {TextIsVisible?
    <FliegenWrapper >
    <Flieg ref={flyingText}>
        <Mailinglist/>
    </Flieg>
    </FliegenWrapper> :
    <FliegenWrapper >
    <FliegNicht ref={flyingText}>
        <Mailinglist/>
    </FliegNicht>
    </FliegenWrapper>
    }
    <RootsOverview />
    <AboutUsOverview />
    <Referenzen />
    </Router>
    </>
  );
}

export default Blog;
