import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;

@media (max-width: 768px) {
    width:100%;
    line-height: 170%;
  }
`;

const TextHeader = styled.h2`
margin: 70px 0px 30px 0px;
text-align: center;

@media (max-width: 768px) {
  margin: 45px 0px 30px 0px;
  text-align: center; 
  }
`;

const Text = styled.p`

`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;


const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    width: 90%;
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 35px;
`;

const Button2 = styled.button`
  font-size: 20px;
  padding: 16px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 50%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

export default function EB4() {

    return (
        <>
        <PageWrapper2>
          
          <TextHeader> 
                 Wir garantieren für deine Erfolgserlebnisse!
          </TextHeader>            
          <TextBox>
            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>Durch unseren ganzheitlicher Ansatz & den individuelle Fokus, den wir jedem unserer Kunden schenken, garantieren wir dir deinen Erfolg.</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Wir beantworten dir zu 100% alle deiner Fragen zu Thema Ernährung. Egal wie viel Zeit es Bedarf, am Ende der Ernährungsberatung erreichst du deine Ziele.</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du erfährst eine ganz neue Perspektive auf das Thema Ernährung und wirst auf dem Weg der Veränderung Freude haben.</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>“Es gibt keine Probleme, nur Lösungen die du noch nicht gefunden hast.” Wir denken immer lösungsorientiert und finden so mithilfe unseres Hintergrundwissens immer einen Weg zu deinen Zielen!</Text>
                </Schritt1>
            </Schritte>
          </TextBox> 
        </PageWrapper2>
        
        </>
      );
}
