import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import Atmung from "../images/1Home7.1Atmung.png"
import EB9 from "../images/1ASiegel.png"


const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.75);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const PageWrapper2 = styled.div`
display: flex;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
flex-direction: column;
justify-content: center;
padding-left: 40px;

@media (max-width: 768px) {
  padding-left: 0px;
  }
`;

const TextBox2 = styled.div`
width: 90%;
display: flex;
justify-content: center;
flex-direction: column;
`;

const TextHeader = styled.h2`
margin-top: 40px;
text-align: center;
margin: 70px 0px 30px 0px;

`;

const Text = styled.p`

`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;


const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;

@media (max-width: 768px) {
  width: 60%;
  }
  `;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    width: 90%;
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;
`;

const Button2 = styled.button`
  font-size: 20px;
  padding: 16px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 50%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

export default function EB7() {

    return (
        <>
        <PageWrapper2>           
          <TextBox>
          <TextHeader> 
                 Weil wir Erfolg garantieren, machen wir keine halben Sachen!
          </TextHeader> 
            <Text>
                Um <b>nachhaltige Erfolge</b> zu erzielen, musst du die entscheidende Grundlagen verstehen und lernen sie in deinem Alltag anzuwenden – <b>Das braucht Zeit.</b><br/>
                Aus diesem Grund bieten wir bewusst keine Einzeltermine an. 
            </Text>
            <Text>
                Damit du kein Risiko hast, kannst du nach dem erste Coaching-Termin ohne Bedingungen auf unsere Geld-zurück-Garantie zurückgreifen.
            </Text>
          </TextBox>
          <ImgBox>   
            <ImageWrapper >
            <Image  alt="FU1" src={EB9}></Image> 
            </ImageWrapper>  
          </ImgBox> 
        </PageWrapper2>
        
        </>
      );
}
