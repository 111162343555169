import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";
import EB5 from "../images/2EB5.jpg"
import EB6 from "../images/2EB6.jpg"


const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
    
  }
`;

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;

@media (max-width: 768px) {
    width:100%;
    line-height: 170%;
  }
`;

const TextBox2 = styled.div`
width: 90%;
display: flex;
justify-content: center;
flex-direction: column;

@media (max-width: 768px) {
    text-align:start;
    width: 90%;
  }
`;

const TextHeader = styled.h2`
margin: 70px 0px 40px 0px;
text-align: center;

@media (max-width: 768px) {
    text-align:start;
    
  }
`;

const TextHeader4 = styled.h2`
margin: 10px 0px 0px 0px;
text-align: center;

@media (max-width: 768px) {
    text-align:start;
    margin-left: 20px;
    
  }
`;

const TextHeader2 = styled.h4`
`;

const TextHeader3 = styled.h2`
text-align: start;
`;


const Text = styled.p`
width: 95%;
`;

const Text2 = styled.div`
width: 95%;

@media (max-width: 768px) {
    line-height: 170%;
  }
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;


const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
      justify-content: center;
      flex-direction: column;
  }
`;

const ContentWrapper2 = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
      justify-content: center;
      flex-direction: column-reverse;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;

@media (max-width: 768px) {
  width: 80%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    width: 90%;
    box-shadow: ${farben.boxshadow};
`;

const Image2 = styled.img`
    width: 90%;
    box-shadow: ${farben.boxshadow};
    @media (max-width: 768px) {
      width: 100%;
  }
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 35px;
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 16px;
  padding: 16px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 50%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;

export default function EB2() {

    return (
        <>
        <PageWrapper>
          <TextBox2>
            <TextHeader> 
                Viele denken Sie ernähren sich bereits gesund! <br/>
                – Doch die Wahrheit ist: 
            </TextHeader>
            <TextHeader2>
                „Die Wenigsten haben das nötige Hintergrundwissen, um die entscheidende Fehler zu vermeiden & sich in unserer modernen Welt wirklich gesund zu ernähren.”
            </TextHeader2>
            <Text>Durch unsere Praxis in der Ernährungsberatung haben wir bemerkt, dass unsere Kunden die gleichen Erfahrungen & Probleme miteinander teilen. Viele haben bereits versucht sich gesundheitsbewusst zu ernähren, konnten jedoch nie langfristige & nachhaltige Erfolge erzielen!</Text>
            <Break/>
            <Text><b>Der Grund dafür:</b> Ihnen fehlte vor der Ernährungsberatung schlicht und einfach das richtige Wissen! </Text>
            <Break/>
            <Break/>
          </TextBox2>
        </PageWrapper>
        <PageWrapper2>
          
          <TextHeader4> 
              Hast du diese Erfahrung auch gemacht:
            </TextHeader4>
          <ContentWrapper>
            
          <TextBox>
            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>Du hast bereits konventionelle Diäten für mehr Energie im Alltag, zum Abnehmen oder zur Linderung von Beschwerden ausprobiert, jedoch kaum bis <b>keine nachhaltigen Resultate</b> erzielt?</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du fühlst dich manchmal orientierungslos im Chaos der vielen <b>widersprüchlichen Ernährungsempfehlungen</b>, die es da draußen gibt?</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du hast Schwierigkeiten dich an einen festen Plan zu halten, wirkliche Veränderungen in deinen Essgewohnheiten zu schaffen und suchst nach einem roten Faden, der die <b>Orientierung</b> gibt?</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du hast bereits mehrfach <b>Nahrungsergänzungsmittel</b> ausprobiert aber bist dir weiterhin unsicher, was und wie viel davon sinnvoll für dich ist?</Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du möchtest erfahren, wie der bewusste Umgang mit <b>Ernährung Spaß macht</b> und <b>dein Leben bereichert.</b></Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du hättest gerne das Grundverständnis, das dir die <b>Sicherheit gibt</b> um den Sinn neuer Ernährungstrends und Informationen für dich richtig einzuschätzen zu können. </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du möchtest dich so natürlich wie möglich ernähren & wissen, wie du die vielen krankheitsfördernde <b>Schad- und Giftstoffe</b> in deiner Nahrung reduzierst und <b>vermeidest.</b></Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>Du fragst dich auch welche <b>weit verbreiteten Fehler</b> du beim Einkauf und der Nahrungszubereitung vermeiden solltest?</Text>
                </Schritt1>
            </Schritte>
          </TextBox>
          
          <ImgBox>   
            <ImageWrapper >
            <Image  alt="FU1" src={EB5}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </ContentWrapper>  
        </PageWrapper2>
        <PageWrapper2>
          <ContentWrapper2>
          <ImgBox>   
            <ImageWrapper >
            <Image2  alt="FU1" src={EB6}></Image2> 
            </ImageWrapper>  
          </ImgBox> 
          <TextBox2>
          <TextHeader3> 
             Wie du siehst, bist du nicht alleine!
            </TextHeader3>
              <Text2>Bei der Vielzahl an widersprüchlichen Informationen und leerer Versprechen, die im Internet und den sozialen Medien kursieren, ist es völlig normal, dass du den Durchblick verlierst.</Text2>
              <br/>
              <Text2>
                Die “eine richtige Ernährung” oder die “brandneueste Diät” werden heutzutage verkauft, wie Flatrates fürs Smartphone. Keto, Low Fat, Vegan, Carnivore oder doch lieber Pescetarier. <br/>
                Hauptsache ein Schuh passt für Alle.
              </Text2>
              <br/>
              <Text2>Erfolglose Diäten mit keinen oder nur kurzweiligen Erfolgserlebnissen ohne nachhaltigen Effekt mindern deinen Glauben an wirkliche Veränderung.</Text2> <br/>
          </TextBox2>
          </ContentWrapper2>  
        </PageWrapper2>
        <PageWrapper2>
          <TextBox2>
          <TextHeader3> 
          Wir hatten die gleichen Probleme wie du!
            </TextHeader3>
              <Text2>Die meisten Menschen stoßen beim Thema Ernährung auf immer die gleichen Probleme und auch uns fehlte vor einigen Jahren noch das nötige Wissen um eine bewusste Ernährung positiv für uns zu nutzen.</Text2> 
              <Text2>
                Beide hatten wir mit unterschiedlichen Symptomen zu kämpfen. Von chronischer Müdigkeit bis hin zur Diagnose von Rheuma & täglichen Gelenkschmerzen bei geringster Belastung. 
                <br/>
                <br/>
                Dabei hat jedoch jeder neue Arztbesuch nur immer neue Fragen aufgeworfen, sodass wir uns immer tiefer selbst mit den Ursachen unserer Beschwerden auseinandergesetzt haben. 
                <Break/>
                <br/>
                Je mehr wir in Erfahrung brachten, desto mehr verstanden wir die Komplexität, sowie das riesige Potential der Beziehung zwischen Körper und Ernährung.
                <br/>
                <br/>
                Das richtige Wissen zum Thema Ernährung war auch unser erster Schritt zu einem gesundheitsbewussten Lebensstil, der schließlich zur Lösung unserer chronischen Beschwerden führte! 
                </Text2>
                <br/>
                <Break/>
            <MiddleButton> 
              <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Jetzt Infogespräch vereinbaren</Button2>
            </MiddleButton>  
          </TextBox2>
            
        </PageWrapper2>
        </>
      );
}
