import React from "react";
import styled from "styled-components";
import Background from "../images/3C1.jpg";
import BackgroundMobile from "../images/3C1Mobile.jpg";
import farben from "../components/GlobalStyles.js";
import C1 from "../components/coachingcomponents/C1.js";
import C2 from "../components/coachingcomponents/C2.js";
import C3 from "../components/coachingcomponents/C3.js";
import C4 from "../components/coachingcomponents/C4.js";
import C5 from "../components/coachingcomponents/C5.js";
import C6 from "../components/coachingcomponents/C6.js";
import C7 from "../components/coachingcomponents/C7.js";
import C8 from "../components/coachingcomponents/C8.js";
import ZitatVI from "../components/ZitatVI.js";
import Referenzen from "../components/Referenzen";
import C9 from "../components/coachingcomponents/C9";
import C10 from "../components/coachingcomponents/C10";
import C11 from "../components/coachingcomponents/C11";
import C12 from "../components/coachingcomponents/C12";
import C13 from "../components/coachingcomponents/C13";
import C14 from "../components/coachingcomponents/C14";
import C15 from "../components/coachingcomponents/C15";
import GreenDevider from "../components/GreenDeviderMobile";
import { Link } from "react-router-dom";


const PageWrapper = styled.div`
  //background-image: url("https://i.stack.imgur.com/JxR07.png");
  background-image: url(${Background});
  background-position: 0% 0%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  font-family: Sen;

  @media (max-width: 768px) {
    width:100%;
    padding: 0px 0px 20px 0px;
    background-image: url(${BackgroundMobile});
    background-position: 0% 50%;
    background-size: 100% 75%;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: center;
margin-top: 20px;
`;

const Button2 = styled.a`
text-decoration: none;
  font-size: 18px;
  border: none;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 280px;
  padding: 4px;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const WhiteBackground = styled.div`
display: flex;
flex-direction: column;
@media (max-width: 768px) {
background: linear-gradient(to bottom,transparent,5%, rgba(255, 255, 255, 0.95),10%, rgba(255, 255, 255, 1));
position: absolute;
top: 68%;
height: 100vh;
width: 100vw;
@media (max-width: 768px) {
  top: 76%;
}
  }
`;

const Breaks = styled.br`
@media (max-width: 768px) {
  display: none;
}
`;

function Coaching() {
  return (
    <PageWrapper>
    <C1 />
    <>
    <C2 />
    <C3 />
    <Breaks/>
    <Breaks/>
    <Referenzen />
    <Breaks/>
    <Breaks/>
    <ZitatVI />
    <C4 />
    <C5 />
    <C6 />
    <br/>
    <br/>
    <C7 />
    <br/>
    <br/>
    <C8 />
    <br/>
    <br/>
    <C15/>
    <br/>
    <br/>
    <C9 />
    <C10 />
    <br />
    <br />
    <C11 />
    <C12 />
    <br />
    <br />
    <br />
    <br />
    <C13 />
    <MiddleButton>
        <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenloses Erstgespräch</Button2>
    </MiddleButton>
    <br />
    <br/>
    <C14 />
    </>
    </PageWrapper>
  );
}

export default Coaching;

