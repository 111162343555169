import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import Biohacker from "../images/1Home9.1Biohacker.png"
import Sportler from "../images/1Home9.2Sportler.png"
import Beschwerden from "../images/1Home9.3Beschwerden.png"
import Headline from "./Headline.js";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background-color: transparent;
color: black;
align-items: center;
padding: 45px 20px;
margin-top: 50px;
@media (max-width: 768px) {
    width:100%;
    flex-direction: column;
    padding: 40px 0px;
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;

`;

const NewWrapper = styled.div`
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Image = styled.img`
width: 130px;
height: 130px;

&:hover {
  transform: scale(1.45);
  transition: all 0.3s linear;
}
`;



const BigText = styled.div`
font-size: 24px;
text-align: center;
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 60%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  padding: 20px 20px;;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 25vw;
@media (max-width: 768px) {
    width: 100%;
  }
`;

const Wrap = styled.span`
 @media (max-width: 768px) {
display: flex;
justify-content: center;
text-align: center;
  }
`;

export default function Zielgruppe() {
    return (
        <PageWrapper>
          <ContentWrapper>
          <Headline> 
            <Wrap>Für wen ist Relife-Coaching?</Wrap> 
          </Headline>
          <Zitatstrich/>
          <br/>
          <NewWrapper>
          <Flex>
          <Wrapper>
            <Image alt="FU1" src={Biohacker}></Image>
            <br/>
            <BigText>Biohacker und Leistungsoptimierer</BigText>
              <br></br>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={Sportler}></Image>
            <br/>
            <BigText>Hobby- und Leistungssportler</BigText>
              <br></br>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={Beschwerden}></Image>
            <br/>
            <BigText>Menschen mit chronischen Beschwerden</BigText>
            <br></br>
            </Wrapper>
            </Flex>
          <br/>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
      );
}
