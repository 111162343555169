import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C4B from "../../images/3C4.PNG";
import C5B from "../../images/3C5.png"
import { Link } from "react-router-dom";

const PageWrapper2 = styled.div`
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding-bottom: 30px;
line-height: 200%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items:center;
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;
const TextBox2 = styled.div`
width: 80%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

@media (max-width: 768px) {
  width: 80%;
  }
`;

const TextHeader3 = styled.h3`
text-align: start;
padding-left: 5%;

@media (max-width: 768px) {
    padding: 0px;
    width: 90%;
  }
`;

const TextHeader4 = styled.h3`
display:none;
text-align: start;

@media (max-width: 768px) {
    display: block;
    padding: 0px;
    width: 90%;
    text-align: center;
  }
`;


const TextHeader5 = styled.h3`
text-align: start;

@media (max-width: 768px) {
    padding: 0px;
    width: 90%;
    text-align: center;
  }
`;
const Text = styled.p`
padding-left: 5%;

@media (max-width: 768px) {
    padding: 0px;
    width: 90%;
  }
`;

const ContentWrapper = styled.div`
display: flex ;
width: 100%;
justify-content:center;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;

@media (max-width: 768px) {
  width: 60%;  
  }
`;

const Image = styled.img`
width: 80%;

@media (max-width: 768px) {
  width: 100%;
  }

`;
    
const Floated = styled.img`
    float: right;
    height: 450px;
    margin: 30px;
    margin-right: 5%;
    box-shadow: ${farben.boxshadow};

    @media (max-width: 768px) {
  float: none;
  margin: 0px;

  }

  @media (max-width: 290px) {
  float: none;
  padding: 0px;
  height: 300px;
  
  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 40%;
  box-shadow: ${farben.boxshadow};
  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  
  display:block;
  width: 80%;
  font-size: 16px;
  margin: 0px 0px 30px 0px;
  }
`;


export default function C6() {

    return (
        <>
        <PageWrapper2>
            
                <Floated src={C4B}/>
                <Text>
                    <b>Der Grund</b> für die genannten Probleme ist die <b>Summe der vielen neuartigen Umwelt- und Lebensstilfaktoren,</b> unserer modernen Lebensweise, welche deinen Körper zunehmend überlasten, seine Leistungsfähigkeit einschränken und ihm so nachhaltig schaden. <br />
                    Um wieder Zugang zur vollen Leistungsfähigkeit deines Körpers zu erhalten, musst du Schritt für Schritt die dafür entscheidenden Elemente (ROOTS) in deinem Leben optimieren. <br />
                    Nur auf diesem Weg adressierst du die Ursache deiner Beschwerden und bildest gleichzeitig das Fundament zur Entfaltung deines vollen Potentials.
                </Text>
                <TextHeader3>Nimm die Abkürzung - Lerne aus unserer Geschichte</TextHeader3>
                <Text>
                    In den letzten 6 Jahren sind wir diesen Weg selbst gegangen – von rheumatischen Gelenkentzündungen und Energielosigkeit, hin zu neuer Lebensenergie, geistiger Klarheit und körperlichen Höchstleistungen im Profisport.<br />
                    Durch unseren persönlichen Weg haben wir gelernt neueste wissenschaftliche Erkenntnisse mit dem Erfahrungsschatz unterschiedlicher Kulturen zu verbinden und nutzen dieses Wissen heute, um unsere Kunden auf ihrem ganz persönlichen Weg individuell zu begleiten. <br />
                    Indem wir in unserem Coaching, Ernährung, Bewegung, Atmung, Geist & Natur (5 Roots) miteinenader verbinden, haben wir die einmalige Möglichkeit uns der Entfaltung deiner Leistungsfähigkeit ganzheitlich zu widmen und so wirklich dein volles Potential zu entfalten. <br />
                    So hast du endlich wieder die Energie, um tägliche Herausforderungen zu meistern und deine persönlichen Ziele zu verfolgen, ohne hinter deinen eigenen Erwartungen zu bleiben.
                </Text>
                <TextHeader4>Mache jetzt den ersten Schritt und buche dein kostenloses Erstgespräch</TextHeader4>
                <ContentWrapper>
                    <TextBox2>
                    <TextHeader5>Mache jetzt den ersten Schritt und buche dein kostenloses Erstgespräch</TextHeader5>
                        <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenloses Erstgespräch</Button2>
                    </TextBox2>
                    <ImgBox>
                        <Image src={C5B} />    
                    </ImgBox>
                </ContentWrapper>
              
        </PageWrapper2>
        </>
      );
}
