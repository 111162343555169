import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
height: 100vh;
background-color: transparent;
color: White;
align-items: center;
justify-content: center;
padding: 10px 0px 0px 10px;
@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    height: 55vh;
    align-items: end;
  }
`;

const TextBox = styled.div`
width: 90%;
text-align: center;
`;

const TextHeader = styled.div`
font-size: 3.5em;
@media (max-width: 768px) {
    font-size: 2.4em;
  }
`;



const Text = styled.p`
font-size: 1.8em;
font-size: bold;
@media (max-width: 768px) {
    font-size: 1.1em;
  }
`;

const ButtonBox = styled.div`
display: flex;
justify-content: center;
width: 100%;
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: rgba(150,201,48,.9); 
  align-items: flex-start;
  width: 30%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin: auto;
  display:block;
  width: 80%;
  }
`;

export default function OverviewOverlay() {

    return (
        <PageWrapper>
          <TextBox>
            <TextHeader>COACHINGS</TextHeader>
            <Text>Deine Reise zur Selbstoptimierung<br/>beginnt hier...</Text>
            <br/>
            <ButtonBox>
              <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Kostenfreies <br/>Erstgespräch buchen</Button2>
            </ButtonBox>
          </TextBox>
        </PageWrapper>
      );
}
