import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import MoStory from "../images//Aboutus2Mo.jpg"
import JoelStory from "../images//Aboutus2Joel.jpg"
import Background from "../images/3C1.jpg";


const PageWrapper = styled.div`

display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;
position: relative;
font-family: Sen;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
  }
`;

const PageWrapper2 = styled.div`
display: flex;
background: linear-gradient(to bottom,transparent,10%, #FFFCF0,95%,transparent);
color: black;
flex-direction: column;
align-items: center;
padding: 10px 0px 30px 30px;
margin-top: 50px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
line-height: 200%;
padding: 0px 15px;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
    flex-direction:column;
  }
`;

const TextBox = styled.div`
width: 90%;
`;

const ImgBox = styled.div`
display: flex;
justify-content: center;
width: 45%;

@media (max-width: 768px) {
    display: none;
  }
`;

const ImgBox2 = styled.div`
display: none;

@media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;


const ImageWrapper = styled.div`

@keyframes rootsinfromright{
    from{
        transform: translateX(30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
    display:none;
  }

`;

const Image = styled.img`
height: 450px;
`;

const Text = styled.p`
line-height: 200%;
@media (max-width: 768px) {
  text-align: start;
  }
`;

const Button2 = styled.button`
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  margin-top: 100px;
  box-shadow: ${farben.boxshadow};
  align-self: center;
  width: 50%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin: auto;
  display:block;
  width: 80%;
  margin-top: 30px;
  }
`;

const TextHeader = styled.h1`
text-align: center;
`;

const TextHeader3 = styled.div`
font-size: 20px;
font-weight:bold;
text-align: center;
`;


export default function GridContentBox() {
  const {ref: image, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true, rootMargin: "-20px -200px -100px -20px"});

    return (
        <>
        <PageWrapper>
        
<NewWrapper>
<TextBox>
<TextHeader> 
Mo´s Story
</TextHeader>
<Text> <TextHeader3> 
    <i>„Gesundheit ist nicht verhandelbar, Gesundheit ist Leben.“</i>
    </TextHeader3></Text>
    <ImgBox2>
<Image  alt="FU1" src={MoStory}></Image> 
</ImgBox2>
<Text>
    Durch familiäre Krankheitsgeschichten und die damit einhergehende Prägung durfte ich früh tiefer in die Themen Gesundheit, Ernährung und Leistungsfähigkeit eintauchen. Später habe ich als Profisportler mein theoretisches Wissen für mich selbst anwenden und erfahren dürfen.
</Text>
<Text>
    Angetrieben von meiner Neugierde habe ich in den letzten Jahren so meine Erfahrungen aus dem Profisport durch unterschiedliche Aus- und Fortbildungen ergänzt. 
</Text>
<Text>
    Aus intrinsischem Wissensdurst heraus bestreite ich so heute jeden Tag mit der Mission ein bisschen besser zu werden. 
    Die Komplexität von Gesundheit & Glück ein bisschen besser zu verstehen als noch den Tag zuvor und so mein Wissen in der Tiefe & in der Breite fortschreitend zu erweitern um zu einer gesünderen, bewussteren und somit glücklicheren Gesellschaft beizutragen. 
</Text>
<Text>
    Mein Wunsch ist es, das eigene Wissen und die eigenen positiven Erfahrungen mit möglichst vielen Menschen zu teilen und diese auf dem Weg zu Gesundheit und einem erfüllten Leben bestmöglich zu begleiten. 
</Text>

</TextBox> 
<ImgBox>
<Image  alt="FU1" src={MoStory}></Image> 
</ImgBox>
</NewWrapper>
        </PageWrapper>
        <PageWrapper2>
        
<NewWrapper>
<TextBox>
<TextHeader> 
Joel´s Story
</TextHeader>
<Text> <TextHeader3> 
    <i>„Gesundheit ist nicht alles aber ohne Gesundheit ist alles nichts“</i>
    </TextHeader3></Text>
    <ImgBox2>
<Image  alt="FU1" src={JoelStory}></Image> 
</ImgBox2>
<Text>
    Die Begeisterung für Sport hat mich von meiner Kindheit an, über meine Jugendzeit als Handball-Leistungssportler bis hin zum Sportstudium begleitet.  Während der letzten Jahre konnte ich an mir selbst die Erfahrung machen, welchen Einfluss unser Lebensstil selbst auf einen jungen, sportlichen Körper haben kann.
</Text>
<Text>
    Als Asthmatiker und Rheumatiker befand ich mich die Hälfte meines Lebens auf der Suche nach Lösungen für meine chronischen Erkrankungen.
</Text>
<Text>
    Dank des Studiums war ich in der Lage, mich mit aktueller Ernährungs- und Trainingswissenschaft auseinander zu setzen. Der Beginn einer neuen Leidenschaft, denn die Anwendung dieser Erkenntnisse haben meine Gesundheit und damit meine Perspektive auf unseren Körper nachhaltig vollkommen verändert.
</Text>
<Text>
    Je mehr ich in Erfahrung brachte, desto mehr verstand ich die Sensibilität und zugleich das riesige Potential unseres Körpers. Um dieses wieder entfalten zu können habe ich viel ausprobiert und so auch mit der Zeit grundlegende Elemente einer natürlichen Lebensweise in meinen Alltag integriert.
</Text>
<Text>
    Der Einfluss auf meine Gesundheit lag jenseits meiner Vorstellungskraft.<br/>
    Heute möchte ich gerne meine Erfahrungen mit allen Menschen teilen, die ebenfalls den Einfluss unserer Gesundheit auf die Lebensqualität selbst erfahren wollen.
</Text>

</TextBox> 
<ImgBox>
<Image  alt="FU1" src={JoelStory}></Image> 
</ImgBox>
</NewWrapper>
        </PageWrapper2>
        </>
      );
}


          
         
          

//
<PageWrapper>

<ContentWrapper>
<NewWrapper>
<TextBox>
<TextBox></TextBox>
<Text><b>Viele Menschen erkennen nicht die frühzeitigen Warnsignale Ihres Körpers</b></Text>
<Text>Diese werden in unserer heutigen Gesellschaft leider zunehmend normalisiert, was einen stetigen Zuwachs unterschiedlicher Krankheitsbilder in unserer Gesellschaft zur Folge hat.</Text>
<Text><b>Heute ist es Realität,</b> dass fast jeder 10. Mensch in Deutschland an Depression oder Diabetes leidet, jeder 5. ist übergewichtig und die Zahl der Menschen mit Autoimmunerkrankungen (wie Rheuma, Arthritis oder Morbus Crohn) steigt jedes Jahr um 100.000.</Text>
<Text>
    <TextHeader> 
    „Das Fass läuft über“
    </TextHeader>
</Text>
<Text><b>Der Grund</b> dafür ist die <b>Summe der vielen neuartigen Umwelt- und Lebensstilfaktoren</b> unserer modernen Lebensweise, welche unseren Körper zunehmend überlasten, seine Leistungsfähigkeit einschränken und ihm so nachhaltig schaden.</Text>

</TextBox>           
<ImgBox>
<ImageWrapper ref={MoStory}>
<Image  alt="FU1" src={MoStory}></Image> 
</ImageWrapper>  
</ImgBox>
</NewWrapper>
<Text>Auf biochemischer Ebene resultieren alle negativen Einflussfaktoren in den selben gestörten Prozessen 
unseres Stoffwechsels:  Oxidativer Stress, Entzündungsprozesse sowie ein überreiztes Immunsystem.
Heute wissen wir, dass das gestörte Gleichgewicht dieser Prozesse die <b>Grundlage für unsere modernen chronischen Krankheiten</b> (Autoimmun, Arthritis Diabetes, Alzheimer & Demenz, Arteriosklerose,..) bilden.</Text>
<Text>
    <TextHeader> 
    „Es ist nie zu spät!“
    </TextHeader>
</Text>
<Text>Dein Körper hat sich über Millionen von Jahren an die <b>entscheidenden Elemente (ROOTS)</b> gewöhnt und perfekt angepasst.
Indem Du sie wieder in dein Leben integrierst, stellst du das natürliche und ursprüngliche Gleichgewicht her und erhältst wieder <b>Zugang zur vollen Leistungsfähigkeit</b> deines Körpers. </Text>
<Text>
    <b>Nur so adressiererst du die Ursache von Beschwerden und bildest gleichzeitig das Fundament zur Entfaltung deines ganzen Potentials </b>
</Text>
<Button2>Zum ReLife-Coaching</Button2>
</ContentWrapper>  

</PageWrapper>

/*
const PageWrapper = styled.div`

display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;
position: relative;
background-image: url(${Background});
background-position: 0% 0%;
background-size: cover;
background-attachment: fixed;
background-repeat: no-repeat;
font-family: Avenir;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
  }
`;
*/