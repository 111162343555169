import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
display: flex;
background: linear-gradient(to bottom,transparent,5%,#FFFCF0,95%,transparent);
color: black;
padding: 25px;
justify-content: center;
line-height: 200%;
@media (max-width: 768px) {
    width:100%;
    padding: 0px;
  }
`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
width: 85%;
`;

const Text = styled.p`

`;

const Headline = styled.div`
align-self: center;
font-size: 20pt;
margin-bottom: 15px;
text-align: center;
font-weight: bold;
`;

const Green = styled.span`
color: ${farben.green};
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 60%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, ${farben.green}, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;
`;

const Space = styled.div`
height: 90px;
`;

export default function HomeHamsterradI() {

    return (
        <PageWrapper>     
          <TextBox>
          <Space />
          <Headline> 
            <Green>
            Das moderne Gesundheits-Hamsterrad 
            </Green>
          </Headline>
          <Zitatstrich/>
          <br/>
            <Text>Vielleicht kennst du es auch…</Text>
            <Text>Es ist kein Wunder, dass du im heutigen Überfluss an Informationen zum Aufbau nachhaltiger Gesundheit & Leistungsfähigkeit den Überblick verlierst.</Text>
            <Text>Ständig sind neue Gesundheitspraktiken im Trend, Main-Stream Experten widersprechen sich am laufenden Bande und mit sich zunehmenden Symptomen suchst du schon seit langem erfolglos nach einer klaren Richtung, um endlich wirkliche Fortschritte zu machen.</Text>
            <Text>Du hast bereits unterschiedliche Trends für dich ausprobiert, sowie Zeit & Energie investiert, aber bisher keine nachhaltige Lösung gefunden, die dir wirklich hilft.</Text>
            <Text>Dies nennen wir das moderne Gesundheits-Hamsterrad - und auch wenn es für die Meisten normal geworden ist, zeigen wir dir mit unserer eigenen Geschichte , dass es auch anders geht!</Text>
            <Space />
          </TextBox>           
        </PageWrapper>
      );
}
