import React from "react";
import styled from "styled-components";
import C15 from "../../images/3C15.png"
import C16 from "../../images/C16.png"

const PageWrapper2 = styled.div`
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
line-height: 200%;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    display: none;
  }
`;

const PageWrapper3 = styled.div`
display: none;
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
line-height: 200%;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    display: block;
  }
`;


const Image = styled.img`
width: 100%;
`;

export default function FC15() {

    return (
        <>
        <PageWrapper2>
           <Image src={C15} />
        </PageWrapper2>
        <PageWrapper3>
           <Image src={C16} />
        </PageWrapper3>
        </>
      );
}
