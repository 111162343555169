import React from "react";
import styled from "styled-components";
import BildFreese from "../images/ReferenzenFreese.png";
import BildNoch3 from "../images/ReferenzenNoch3.png";
import BildDieFitmacher from "../images/ReferenzenDieFitmacher.png";
import BildRapahn from "../images/ReferenzenPapahn.png";
import BildNorsan from "../images/ReferenzenNorsan.png";
import BildDiwi from "../images/ReferenzenDiwi.png";
import BildTrainerakademie from "../images/ReferenzenDeutscheTrainerakademie.png";
import farben from "./GlobalStyles";

const PageWrapper = styled.div`
display: flex;
//background: linear-gradient(to right, grey,10%, beige 70%, grey);
background:linear-gradient(to right,transparent,10%, #ece9e1,90%,transparent);
color: black;
margin: 15px 0px; 
align-items: center;
flex-direction: column;
@media (max-width: 768px) {
    width:100%;
  }
`;

const ImageInfiniteSlider = styled.img`

transition: transform 1s;
width: 200px;

:hover{
  transform: translateZ(20px);
}
`;

const Slider = styled.div`
height: 150px;
margin: auto;
position: relative;
width:100%;
display: grid;
place-items: center;
overflow: hidden;

::before,::after{
 // background: linear-gradient(to right, ${farben.cremeBackground} 0%, rgba(255,255,255,0) 100%);
 background:  ${farben.cremeBackground};
  content: "";
  height: 100%;
  position: absolute;
  z-index:2;

  @media (max-width: 768px) {
    background: transparent;
  }
}

::before{
  top:0;
  left:0;
}

::after{
  top:0;
  right:0;
  transform: rotateZ(180deg);
};


`;

const Slidetrack = styled.div`
display: flex;
width:calc(250px * 50);
animation: scroll 40s linear infinite;

@keyframes scroll{
0% {
  transform: translateX(0);
}
100% {
  transform: translateX(calc(-250px * 10));
}
}

:hover{
  animation-play-state: paused
}
`;

const Slide = styled.div`
width: 250px;
display: flex;
align-items: center;
padding: 15px;
perspective: 100px;
`;

export default function Referenzen() {

    return (
        <PageWrapper>          
          <Slider>
            <Slidetrack>
            <Slide>
              <ImageInfiniteSlider src={BildNorsan}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildRapahn}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildFreese}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildNoch3}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildDieFitmacher}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildDiwi}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildTrainerakademie}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildNorsan}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildRapahn}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildFreese}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildNoch3}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildDieFitmacher}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildDiwi}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildTrainerakademie}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildNorsan}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildRapahn}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildFreese}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildNoch3}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildDieFitmacher}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildDiwi}></ImageInfiniteSlider>
            </Slide>
            <Slide>
              <ImageInfiniteSlider src={BildTrainerakademie}></ImageInfiniteSlider>
            </Slide>
            
            </Slidetrack>
          </Slider>
        </PageWrapper>
      );
}