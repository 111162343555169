import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import Atmung from "../images/1Home7.1Atmung.png"
import EB7 from "../images/2EB7.png"
import EB71 from "../images/2EB71.png"
import EB73 from "../images/2EB73.png"
import EB74 from "../images/2EB74.png"
import EB75 from "../images/2EB75.png"
import EB76 from "../images/2EB76.png"




const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextHeader = styled.h2`
margin: 70px 0px 30px 0px;
text-align: center;

@media (max-width: 768px) {
  margin-top: 40px;
  }
`;



const Text = styled.p`
max-width: 90%;
@media (max-width: 768px) {
    max-width:100%;

  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const Image = styled.img`
    width: 50px;
    height: 50px;
`;

const BigText = styled.div`
font-size: 22px;
font-weight: bold;

@media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 20px 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px 20px;
  }
  `;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 65%;
padding-bottom: 40px;

@media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
  }
`;

const Wrapper2 = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 65%;
padding-bottom: 40px;

@media (max-width: 768px) {
    width: 100%;
    flex-wrap: wrap;
    padding-bottom: 0px;
  }
`;

const NewWrapper = styled.div`
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;


export default function EB3() {

    return (
        <>
        <PageWrapper>
        <ContentWrapper>
            <TextHeader> 
                Wie funktioniert die ReLife Ernährungsberatung?
            </TextHeader>
            <NewWrapper>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={EB7}></Image>
            <Break/>
              <BigText>Check Up</BigText>
              <Break />
              <Text>
                In einer umfassenden Anamnese widmen wir uns deinen Zielen & Symptomen.<br/>
                Wir analysieren gemeinsam mögliche Ursachen und finden “den Fehler im System”.
                </Text>
              
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={EB71}></Image>
            <Break/>
            <BigText>Das „Warum“ verstehen</BigText>
              <Break />
              <Text>
                Uns ist es wichtig, dass du verstehst aus welchen Ursachen deine Beschwerden resultieren. <br/>
                Wir erklären dir die wichtigsten  Zusammenhänge, damit du in Zukunft deine Ernährung selbstständig optimieren kannst.
              </Text>
            </Wrapper>
            </Flex>
            <Flex>
            <Wrapper>
            <Image alt="FU1" src={EB73}></Image>
            <Break/>
              <BigText>Ziele setzen</BigText>
              <Break />
              <Text>
                Gemeinsam setzen wir individuelle Ziele für dich.<br/>
                Diese machen wir mithilfe unserer Coaching Tools messbar, damit du immer weisst wo du gerade stehtst.
              </Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={EB74}></Image>
            <Break/>
            <BigText>Strategieplan</BigText>
              <Break />
              <Text>
                Gemeinsam erstellen wir einen Strategieplan um deine Ziele auch zu erreichen.<br/>
                Diesen setzen wir dann Schritt für in die Praxis um – Alles in deinem Tempo & so wie es für dich passt! 
              </Text>
            </Wrapper>
          </Flex>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={EB75}></Image>
            <Break />
              <BigText>Gewohnheiten tauschen</BigText>
              <Break />
              <Text>
                Gemeinsam tauschen wir schlechte Gewohnheiten gegen neue, gute Gewohnheiten. <br/>
                Diese integrieren wir gemeinsam in deinen Alltag, damit du wirkliche Veränderung in der Praxis erfährst!
              </Text>
            </Wrapper>
            <Wrapper2>
            <Image alt="FU1" src={EB76}></Image>
            <Break/>
            <BigText>Make it easy!</BigText>
              <Break />
              <Text>
                Make it easy. Wir sind der Meinung Ernährung muss einfach sein. <br/>
                Damit du keine Zeit verlierst, kriegst du von uns hilfreiche Praxistipps für deinen Alltag. 
              </Text>
            </Wrapper2>
          </Flex>
          <br/>
          </NewWrapper>
          </ContentWrapper>
        </PageWrapper>
        </>
      );
}
