import React from "react";
import styled from "styled-components";
import C13 from "../images/AboutUs1Header.jpg"
import C131 from "../images/AboutUsMobile.jpg"

const PageWrapper2 = styled.div`
color: black;
align-items: center;
justify-content: center;
width: 100%;
align-self: center;
line-height: 200%;

@media (max-width: 768px) {
    width:100%;
    padding: 0px 0px;
  }
`;


const Image = styled.img`
  width:100%;
  display: flex;

@media (max-width: 768px) {
    display: none;
  }
`;

const ImageMobile = styled.img`
    display: none;
@media (max-width: 768px) {
    display:block;
    width:100%;
    margin-top:80px;
  }
`;

const Text = styled.div`
position: absolute;
top: 43%;
left: 47%;
color: white;
transform: translate(-50%, 50%);
font-size: 66px;
font-weight: bold;

@media (max-width: 768px) {
  top:18%;
  font-size: 40px;
  left: 50%;
  width: max-content;
  }
`;

export default function AboutusPic() {

    return (
        <>
        <PageWrapper2>
           <Image src={C13} />
           <Text>About Us</Text>
           <ImageMobile src={C131} />
        </PageWrapper2>
        </>
      );
}
