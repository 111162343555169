import styled from "styled-components"
import farben from "./GlobalStyles";
import KachelKopf from "../images/PfeilKreis.png";
import Food from "../images/PfeilKreis.png";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
color: black;
align-items: center;
margin-top:40px;
width: 30%;
font-family: ${farben.font1};
@media (max-width: 768px) {
    width:90%;
  }
`;

const KachelWrapper = styled.div`
width: 100%;
box-shadow: 10px 15px 10px grey;

`;

const HeadlineSection = styled.div`
background-image: url(${KachelKopf});
height:140px;
width: 100%;
`;

const Headline = styled.h2`
position: absolute;
align-self: center;
color: white;
top: 0px;
`;

const Subheadline = styled.h3`
position: absolute;
align-self: center;
color: white;
top: 40px;
margin-left: 20px;

`;

const ContentSection = styled.div`
background-image: url(${Food});
height: 70%;
`;

const HeadBox = styled.div`
position: relative;
display: flex;
justify-content: center;
`;

const Haken = styled.div`
color:${farben.greenButtons};
width: 20%;
`;

const BodyBox = styled.div`
display: flex;
margin: 10px ;
`;

function Kachel({headline,subheadline,body}) {
    return (  
        <PageWrapper>
            <KachelWrapper>
                <HeadBox>
                    <HeadlineSection src={KachelKopf} />
                    <Headline>{headline}</Headline>
                    <Subheadline>{subheadline}</Subheadline>
                </HeadBox>
                <ContentSection>
                {body.map((item) => (
                    <BodyBox>
                        <Haken>✅</Haken>
                        <div>{item}</div>
                    </BodyBox>
                ))}
                </ContentSection>
            </KachelWrapper>
        </PageWrapper>
    );
}

export default Kachel;