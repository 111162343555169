import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import PfeilKreis from "../../images/PfeilKreis.png";



const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;
const TextBox2 = styled.div`
width: 90%;
display: flex;
align-items: center;

@media (max-width: 768px) {
    width: 90%;
  }
`;

const TextHeader3 = styled.h2`
text-align: start;
margin-top: 70px;
width: 90%;
@media (max-width: 768px) {
display: flex;
width: 90%;
justify-content: center; 
text-align: center; 
}
`;

const TextHeader2 = styled.h2`
text-align: start;
width: 90%;

@media (max-width: 768px) {
  font-weight: bold;
  font-size: 1.3em;
  
  }
`;


const Text = styled.p`
 width: 85%;
 padding-left: 20px;
`;

const Text2 = styled.p`
 width: 90%;
 padding-left: 20px;

 @media (max-width: 768px) {
  width: 90%;
  padding-left: 0px;
  }
`;

const ContentWrapper = styled.div`
display: flex ;
width: 100%;
align-items:center;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;
    
@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    height: 35px;
    width: 35px;
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;
`;


const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

export default function C5() {

    return (
        <>
        <PageWrapper2>
        <TextHeader2> 
            Vielleicht kennst Du es auch?
        </TextHeader2>
        <ContentWrapper>
            <TextBox2>
                <Image  alt="FU1" src={PfeilKreis}></Image> 
                <Text>Du hast bereits endlose Gesundheitstrends & Diäten für mehr Energie im Alltag, besseren Schlaf, Abnehmen oder Darmgesundheit, etc. ausprobiert, aber kaum bis keine Resultate erzielt?</Text>
            </TextBox2> 
            <TextBox2>
                <Image  alt="FU1" src={PfeilKreis}></Image> 
                <Text>Du fühlst dich energielos, leidest unter Tagesmüdigkeit und hast Schwierigkeiten dich zu konzentrieren</Text>
            </TextBox2>
            <TextBox2>
                <Image  alt="FU1" src={PfeilKreis}></Image> 
                <Text>Du hast einfach zu viel Stress, regelmäßig Kopfschmerzen und schläfst schlecht</Text>
            </TextBox2> 
            <TextBox2>
                <Image  alt="FU1" src={PfeilKreis}></Image> 
                <Text>Du hast Symptome, wie Gelenkschmerzen, Verspannungen und Verdauungsbeschwerden, die dich zunehmend belasten</Text>
            </TextBox2> 
            <TextBox2>
                <Image  alt="FU1" src={PfeilKreis}></Image> 
                <Text>Deine leidest unter den Symptomen von Allergien oder sogar einer Autoimmunerkrankung und wünschst dir deine Lebensqualität zurück</Text>
            </TextBox2>  
        </ContentWrapper>  
        <Text2>
            Viele der genannten Probleme sind für die aller meisten Menschen ganz normaler Alltag und auch uns erging es noch vor einigen Jahren nicht anders als dir.
        </Text2>
        <Text2><b>Daher wissen wir genau wie du dich fühlst und möchten dir zeigen, dass es eine Lösung gibt.</b></Text2>
        <br />
        <TextHeader3>Der einzige Weg zu deiner vollen Leistungsfähigkeit! </TextHeader3>
        </PageWrapper2>
        </>
      );
}
