import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.90);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
`;

const TextHeader = styled.h3`
`;


const Text = styled.p`

`;

const Text2 = styled.p`
text-align: center;
@media (max-width: 768px) {
    width:101%;
  }
`;

const Button2 = styled.a`
  text-decoration: none;  
  font-size: 16px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 30%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

export default function EinleitungII() {

    return (
        <PageWrapper>
          <TextBox>
            <TextHeader>„Mehr Lebensenergie und Leistungsfähigkeit im Alltag durch die optimale Ernährung“. </TextHeader>
            <Text>So lautet das Ziel unserer Coaching-Teilnehmer. Dieses Ziel erreichen sie, indem sie von uns erfahren welche Ernährung zu ihrem individuellen Lebensstil passt & wie Sie die entscheidenden Gewohnheiten zeitsparend in Ihren Alltag integrieren.
            <br/>
            </Text>
            <Text> Durch unsere persönliche <b>Vergangenheit mit chronischen Erkrankungen</b> & über 5 Jahren <b>Leistungsoptimierung</b> im Leistungssport wissen wir genau wie du die Vorteile unterschiedlicher Ernährungsformen für deine volle Potentialentfaltung nutzen kannst.  </Text>
            <br/>
            <Text2>Mach auch du den ersten Schritt zu <b>neuer Lebensenergie</b> & <b>maximaler Leistungsfähigkeit.</b> </Text2>
            <MiddleButton> 
              <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Vereinbare jetzt dein kostenloses Erstgespräch</Button2>
            </MiddleButton>   
          </TextBox>
        </PageWrapper>
      );
}

