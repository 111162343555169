import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Fass from "../../images/1Home12Fass.png"
import { NavLink } from "react-router-dom";

const PageWrapper = styled.div`
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;
position: relative;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
height: 100%;

@media (max-width: 768px) {
width: 100%  ;
}

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}
`;

const Text = styled.p`
width: 100%;
`;



export default function EigenerWeg2() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true});

  const [checked,setChecked]= React.useState(true);

  const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
  

  
  margin-bottom: 10px;
  padding: 10px;
 
  
  }
  
  `;

const Klapptext = styled.input`
display: none;
appearance: none;
border-radius: .45em;
cursor: pointer;

:hover{
color: transparent;
}

::before{
  content: " weniger ⬆ ";
  color: black;
  font-size: 22px;
  white-space: pre-wrap;
}

:checked::before  {
  content:"";
  font-size: 20px;
  border: 2px solid ${farben.green};
  border-radius: .45em;
  padding: .25em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  ${checked ? "display:none" : "display:flex"}
  }
`;

const Klapptext2 = styled.input`
display: none;
appearance: none;
border-radius: .45em;
cursor: pointer;

:hover{
color: transparent;
}

::before{
  content: " mehr... ⬇ ";
  color: black;
  font-size: 20px;
  white-space: pre-wrap;
}

:checked::before  {
  content: "⇩";
  font-size: 20px;
  border: 2px solid ${farben.green};
  border-radius: .45em;
  padding: .25em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  ${checked ? "display:none" : "display:flex"}
  transition: 1s;
  }
`;

const Button2 = styled(NavLink)`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  margin-top: 100px;
  box-shadow: ${farben.boxshadow};
  align-self: center;
  width: 50%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin: auto;
  display:block;
  width: 80%;
  margin-top: 30px;
  }
`;

const TextHeader = styled.h1`
text-align: center;
`;

    return (
      <>
        <PageWrapper>
            <TextHeader> 
            Krankheit als Normalzustand - <br/><br/>
            Warum kaum jemand noch wirklich gesund ist
            </TextHeader>
          <ContentWrapper>
          <NewWrapper>
          <TextBox>
            <TextBox></TextBox>
            <Text><b>Viele Menschen erkennen nicht die frühzeitigen Warnsignale Ihres Körpers</b></Text>
            <Text>Diese werden in unserer heutigen Gesellschaft leider zunehmend normalisiert, was einen stetigen Zuwachs unterschiedlicher Krankheitsbilder in unserer Gesellschaft zur Folge hat.</Text>
            <Text><b>Heute ist es Realität,</b> dass fast jeder 10. Mensch in Deutschland an Depression oder Diabetes leidet, jeder 5. ist übergewichtig und die Zahl der Menschen mit Autoimmunerkrankungen (wie Rheuma, Arthritis oder Morbus Crohn) steigt jedes Jahr um 100.000.</Text>
            <Text>
                <TextHeader> 
                „Das Fass läuft über“
                </TextHeader>
            </Text>
            <Text><b>Der Grund</b> dafür ist die <b>Summe der vielen neuartigen Umwelt- und Lebensstilfaktoren</b> unserer modernen Lebensweise, welche unseren Körper zunehmend überlasten, seine Leistungsfähigkeit einschränken und ihm so nachhaltig schaden.</Text>
            
          </TextBox>           
          <ImgBox>
            <ImageWrapper ref={image2}>
            <Image  alt="FU1" src={Fass}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapper>
          <Text>Auf biochemischer Ebene resultieren alle negativen Einflussfaktoren in den selben gestörten Prozessen 
unseres Stoffwechsels:  Oxidativer Stress, Entzündungsprozesse sowie ein überreiztes Immunsystem.
Heute wissen wir, dass das gestörte Gleichgewicht dieser Prozesse die <b>Grundlage für unsere modernen chronischen Krankheiten</b> (Autoimmun, Arthritis Diabetes, Alzheimer & Demenz, Arteriosklerose,..) bilden.</Text>
            <Text>
                <TextHeader> 
                „Es ist nie zu spät!“
                </TextHeader>
            </Text>
            <Text>Dein Körper hat sich über Millionen von Jahren an die <b>entscheidenden Elemente (ROOTS)</b> gewöhnt und perfekt angepasst.
            Indem Du sie wieder in dein Leben integrierst, stellst du das natürliche und ursprüngliche Gleichgewicht her und erhältst wieder <b>Zugang zur vollen Leistungsfähigkeit</b> deines Körpers. </Text>
            <Text>
                <b>Nur so adressiererst du die Ursache von Beschwerden und bildest gleichzeitig das Fundament zur Entfaltung deines ganzen Potentials </b>
            </Text>
            <Button2 to="/coaching">Zum ReLife-Coaching</Button2>
          </ContentWrapper>  
          
        </PageWrapper>
                  </>
      );
}
