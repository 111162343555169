import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Karate from "../images/1Home9.jpg"
import { NavLink } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
background: linear-gradient(to bottom,transparent,5%,#FFFCF0,95%,transparent);
color: black;
align-items: center;
margin-top: 50px;
padding: 25px;

@media (max-width: 768px) {
    width:100%;
    padding: 0px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  }

`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 60%;
@media (max-width: 768px) {
    width: 80%;
  }
`;

const TextHeader = styled.h1`
`;

const ImgBox = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
  justify-content:center ;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;
    width: 450px;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
  width:90% ;
  height: 100%;
  margin-bottom: 30px;
  }
`;

const Image = styled.img`
height: 100%;
box-shadow: ${farben.boxshadow};

@media (max-width: 768px) {
  width:100% ;
  }
`;

const Text = styled.p`
width: 100%;
`;

const Button = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 50%;
  height: 100%;
  }
`;

const Green = styled.span`
display: flex;
justify-content: center;
color: ${farben.green};
text-align: center;

@media (max-width: 768px) {
  justify-content: flex-start;
  }
`;

const Gap = styled.div`
height: 15px;
`;

const Space = styled.div`
height: 90px;
`;

export default function HomePhilosophie() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true});

    return (
        <PageWrapper>
          <ContentWrapper>
          <NewWrapper>
          <ImgBox>   
            <ImageWrapper ref={image2}>
            <Image  alt="FU1" src={Karate}></Image> 
            </ImageWrapper>  
          </ImgBox>
          
          <TextBox>
          <Space />
            <TextHeader> 
              Deshalb lautet unsere Philosophie: <br/>
              <Gap />
              <Green>„Back To Roots“ <br /></Green>
              <Gap />
              Aber was bedeutet das genau?
            </TextHeader>
            <Text>„Back To Roots“ bedeutet zurück zu den ursprünglichen & elementaren Wurzeln des Menschen.</Text>
            <Text>An diese hat sich unser Körper über Millionen von Jahren angepasst, wobei sie das Fundament für unsere Gesundheit und Leistungsfähigkeit formten. </Text>
            <Text>Im Zuge der modernen Lebensweise haben wir uns immer weiter von diesen Wurzeln entfernt, wodurch wir unsere physische und psychische Gesundheit in großem Maße negativ beeinflussen. </Text>
            <Text>Der Schlüssel zu deiner nachhaltiger Gesundheit und Leistungsfähigkeit liegt darin, die entscheidenden Elemente (ROOTS) für dich individuell in den modernen Lebensstil zu integrieren. </Text>
            <Text>Auf diesem Weg begleiten wir dich!</Text>
            <Button href="https://calendly.com/relife_coaching/45min" target="_blank">Info-Gespräch vereinbaren</Button>
            <Space />  
              
          </TextBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
      );
}
