import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"



const Box = styled.div`
@media (max-width: 768px) {
  min-height: 200px;
  background: linear-gradient(to bottom,transparent,30%,${farben.greenButtons},75%,transparent);
  width: 100%;
}
`;


export default function GreenDevider() {

    return (
        <Box>
              
        </Box>
        
      );
}
