import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import Biohacker from "../../images/1Home9.1Biohacker.png"
import Sportler from "../../images/1Home9.2Sportler.png"
import Beschwerden from "../../images/1Home9.3Beschwerden.png"
import Headline from "../Headline.js";
import SubHeadline from "../Subheadline.js";

const PageWrapper = styled.div`
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 100px 0px 0px 0px;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 10px 0px 0px 0px;
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;

`;

const NewWrapper = styled.div`
align-items: center;
width: 100%;


@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Image = styled.img`
width: 130px;
height: 130px;

&:hover {
  transform: scale(1.45);
  transition: all 0.3s linear;
}

@media (max-width: 768px) {
  width: 130px;
height: 130px;
    
}
`;

const BigText = styled.div`
font-size: 20px;
text-align: center;

@media (max-width: 768px) {
  margin-bottom: 20px;
    
}
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  padding: 20px 20px;;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 25vw;
@media (max-width: 768px) {
    width: 100%;
  }
`;

const Wrap = styled.span`
 @media (max-width: 768px) {
display: flex;
justify-content: center;
text-align: center;
  }
`;

export default function ZielgruppeOverlay() {
    return (
        <PageWrapper>
          <ContentWrapper>
          <Headline> 
            <Wrap>Für wen ist Relife-Coaching?</Wrap> 
          </Headline>
          
          <br/>
          <NewWrapper>
          <Flex>
          <Wrapper>
            <Image alt="FU1" src={Biohacker}></Image>
            
            <BigText>Biohacker und Leistungsoptimierer</BigText>
              <br></br>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={Sportler}></Image>
            
            <BigText>Hobby- und Leistungssportler</BigText>
              <br></br>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={Beschwerden}></Image>
            
            <BigText>Menschen mit chronischen Beschwerden</BigText>
            <br></br>
            </Wrapper>
            </Flex>
          <br/>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
      );
}
