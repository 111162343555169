import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import CoachingOverErnaehrung from "../../images/CoachingOverErnaehrung.png"
import CoachingOverErnaehrungMobile from "../../images/CoachingOverErnaehrungMobile.png"
import { NavLink } from "react-router-dom";


const PageWrapper = styled.div`
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;
position: relative;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
    box-shadow: 0px 10px 13px -6px grey;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 80%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
margin-top: 20px;
@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
align-items: start;
width:60% ;
padding-left: 5%;
@media (max-width: 768px) {
  width: 100% ;
  padding-left: 0%;
  }
`;

const TextHeader = styled.h1`
@media (max-width: 768px) {
  font-size: 1.73em;
  }
`;

const TextHeader2 = styled.div`
font-weight: bold;
`;

const HeaderSpacing = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
`;

const ImgBox = styled.div`
display: flex;
align-items: start;
width: 50%;
@media (max-width: 768px) {
  width:100% ;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
width: 100%;
max-height: 70vh;
position: relative;

@media (max-width: 768px) {
display: none;
}
`;

const Image2 = styled.img`
display:none;

@media (max-width: 768px) {
  display: flex;
  width: 100%;
max-height: 70vh;
position: relative;
margin-top: 30px;
}
`;

const Text = styled.div`

`;

const TextHead = styled.div`
@media (max-width: 768px) {

  margin-top: 30px;
  }
`;

const Button2 = styled(NavLink)`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  margin-top: 70px;
  box-shadow: ${farben.boxshadow};
  align-self: center;
  width: 50%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin: auto;
  display:block;
  width: 80%;
  margin-top: 30px;
  }
`;


export default function Programm2() {

    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <HeaderSpacing>
          <TextHeader> 
           Ernährungsberatung
          </TextHeader>
          
          <TextHeader2> 
            Ernährung ist nicht deine Lösung für Alles - aber die Basis für alle deine Lösungen
          </TextHeader2>
          </HeaderSpacing> 
          <NewWrapper>
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={CoachingOverErnaehrung}></Image> 
            <Image2  alt="FU1" src={CoachingOverErnaehrungMobile}></Image2>
            </ImageWrapper>  
          </ImgBox>
          <TextBox>
            
            <TextHead>Erhalte eine genaue Anleitung wie du deine Ernährung individuell für dich optimierst und so endlich jeden Tag deine beste Version sein kannst! 
            </TextHead>
            <br/>
            <Text>Und Erfahre wie die optimale Ernährung dein Leben bereichert.
             </Text>
            <Button2 to="/ernaehrungsberatung">Zum Coaching</Button2>
          </TextBox>
          
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>


                  </>
      );
}
