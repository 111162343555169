import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;

@media (max-width: 768px) {
    width:100%;
    line-height: 170%;
    margin-bottom: 40px;
  }
`;

const TextBox2 = styled.div`
width: 90%;
display: flex;
justify-content: center;
flex-direction: column;
`;

const TextHeader = styled.h2`
margin-top: 40px;
text-align: center;
margin: 70px 0px 30px 0px;

@media (max-width: 768px) {
    width:90%;
    margin: 0px 0px 30px 0px;

  }
`;

const TextHeader2 = styled.h3`
margin-top: 40px;
text-align: center;
margin: 70px 0px 30px 0px;

@media (max-width: 768px) {
    width:90%;
    margin: 0px 0px 30px 0px;

  }
`;

const Text = styled.p`

`;

const Text2 = styled.p`
 width: 86%;
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;


const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;

@media (max-width: 768px) {
  min-width: 30px;
  }
`;
export default function EB10Function() {

    return (
        <>
         <PageWrapper2>
          
          <TextHeader> 
              Warum ist die Ernährung so wichtig?
          </TextHeader>           
          <TextBox>
            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>
                    Ernährung bildet die Basis für deine gesamte Biochemie und somit die Voraussetzung für den reibungslosen Ablauf unserer Körperfunktionen (Energiestoffwechsel, Immunsystem, Regeneration,..)
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                    Ernährung hat einen enormen Einfluss auf den Verlauf und die Prävention chronischer Krankheiten
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                    Insb. bei den modernen Zivilisationskrankeiten wie z.B. Herz-Kreislauf-Erkrankungen und Diabetes spielt sie sogar eine Ursächliche Rolle                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                    Da unsere Nahrung die Baustoffe für deinen Bewegungsapparat liefert, beeinflusst ihre Qualität die Gesundheit und Leistungsfähigkeit deiner Gelenke                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                    Die richtige Ernährung verbessert deine Gehirnleistung (z.B. Konzentration, Gedächtnis, Aufnahmefähigkeit…) und schützt dich gleichzeitig vor neurodegenerativen Krankheiten wie z.B. Demenz und Alzheimer               
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                    Übergewicht wirkt sich negativ auf sämtliche Bereiche deiner Gesundheit aus – Darauf hat nicht die Bewegung, sondern deine Ernährung den größten Einfluss              
                    </Text>  
                </Schritt1>
            </Schritte>
          </TextBox> 
        </PageWrapper2>
        
        
        </>
      );
}
