import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 10px;
width: 90%;  

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: start;
align-self: center;
padding-bottom: 6px;
width: 90%;  

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const Wrap = styled.div`
margin-left: 20px;
`;

const Wrap2 = styled.div`

width: 90%;
margin-bottom: 70px;

@media (max-width: 768px) {
    margin: 20px 0px 10px 0px;
  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 8px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 15px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 34%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  margin: 10px 0px;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

const Flex = styled.div`
display: flex;
justify-content: space-between;
width: 96%;

@media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

const Form = styled.div`
border-radius: 15px;
background-color: white;
text-align: center;
width: 30%;
padding: 15px 3px;
color: black;
border: ${farben.greenButtons} solid 2px;
font-size: 16px;
box-shadow: ${farben.boxshadow};
@media (max-width: 768px) {
    border: ${farben.greenButtons} solid 1px;
    width: 80%;
    margin-bottom: 20px;
    padding: 5px 3px
  }
`;

const Form2 = styled.div`
border-radius: 15px;
background-color: white;
text-align: center;
width: 30%;
padding: 15px 3px;
color: black;
border: ${farben.greenButtons} solid 2px;
box-shadow: ${farben.boxshadow};
@media (max-width: 768px) {
    border: ${farben.greenButtons} solid 1px;
    width: 80%;
    margin-bottom: 20px;
    padding: 5px 3px
  }

`;

const Form3 = styled.div`
border-radius: 15px;
background-color: white;
text-align: center;
width: 30%;
padding: 15px 3px;
color: black;
border: ${farben.greenButtons} solid 2px;
box-shadow: ${farben.boxshadow};
@media (max-width: 768px) {
    border: ${farben.greenButtons} solid 1px;
    width: 80%;
    padding: 5px 3px
  }
`;

const Z = styled.span`
font-size: 1.5em;
font-weight: bold;
color: ${farben.greenButtons};
`;

export default function EB6Function() {

    return (
        <>
        <PageWrapper2>
            <Flex>
                <Form>
                    Ersttermin + 1x Folgetermin<br/>
                    <Z>219€</Z>
                </Form>
                <Form2>
                    Ersttermin + 3x Folgetermine<br/>
                    <Z>349€</Z>
                </Form2>
                <Form3>
                    Ersttermin + 5x Folgetermine<br/>
                    <Z>579€</Z>
                </Form3>
            </Flex>
        </PageWrapper2>
        <PageWrapper>
            <Wrap>
                *Zusätzlicher Folgetermin: 119 € 
            </Wrap>  
            
        </PageWrapper>
        <PageWrapper2>
            <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Kostenloses Erstgespräch vereinbaren</Button2>
        </PageWrapper2>
        <PageWrapper2>
            <Wrap2>
                Du befindest dich in einer speziellen Lebenssituation und bevorzugst daher eine besonders enge oder auch längere Zusamenarbeit? <br/>
                Kein Problem! - Wir gehen gerne auf deine individuellen Bedürfnisse ein und erörtern gemeinsam mit dir den optimalen Rahmen für unsere Zusammenarbeit! <br/>
                Vereinbare jetzt ein kostenfreies Erstgespräch & lass uns die beste Lösung für dich finden.<br/>
            </Wrap2>
        </PageWrapper2>
        </>
      );
}
