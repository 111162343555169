import React from "react";
import styled from "styled-components";
import Inputfields from "./Inputfields";

const PageWrapper = styled.div`
height: 80vh;
text-align:center;
margin:auto;
background-color: #FEF5EC;
color: #79553C;

@media (max-width: 768px) {
    width:90%;
  }
`;

const Headline = styled.h1`
font-size:4em;
`;

const UmWerbungHerum = styled.div`
display:flex;
justify-content:center ;
`;

const Werbung = styled.div`
border: solid 1px red ;
width: 300px;
margin-top: 20px ;
`;

const Aufteiler = styled.div`
display: flex;
margin-top: 15px;
`;

const InputWrapper = styled.div`
display: flex ;
flex-direction:column ;
justify-content: center;
align-items: center;
width: 50%;
`;

const ImageWrapper = styled.div`
width: 50%;
`;

const Image = styled.img`
width: 50%;
transform: rotate(-17deg);
`;

const Description = styled.h2`
font-size:2em;
`;

const Thintext = styled.p`
font-size:1.5em;
width:90%;
margin:auto;
`;

const Fattext = styled.p`
font-size:1.7em;
font-weight:bold;
`;

export default function SloticBotOnline() {
    return (
        <PageWrapper>
          <UmWerbungHerum>
             <Werbung>Werbung für kostenlosen Call/Newsletter/PDF</Werbung>  
          </UmWerbungHerum>  
          <Headline>Sichere dir deinen kostenlosen Guide</Headline>
          <Thintext>Die kostenlose Anleitung: "Meine eigene Produkttreppe" direkt in dein E-Mail-Postfach. Jetzt kostenlos anmelden!</Thintext>
          <Aufteiler>
          <InputWrapper>
          <Inputfields placeholder="Vorname"></Inputfields>
          <Inputfields placeholder="Nachname"></Inputfields>
          <Inputfields placeholder="*E-Mail-Adresse"></Inputfields>
          </InputWrapper>
          <ImageWrapper>
            <Image alt="FU1" src="https://st4.depositphotos.com/3410019/39041/i/1600/depositphotos_390419374-stock-photo-wedding-invitation-cards-papers-laying.jpg"></Image>
          </ImageWrapper>
          </Aufteiler>
        </PageWrapper>
      );
}
