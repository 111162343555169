import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const Input = styled.input`
  font-size: 16px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  height: 30px;
  text-align: center;
  width: 73%;

  :hover::placeholder {
    color: #255C6A;
;
  }

  ::placeholder {
    color: #255C6A;;
    text-align: center;
    font-size: ${props => (props.size ? "20px" : "16px")};
    font-style: italic;
    opacity:0.5;
  }

  :focus {
    text-align: center;
    border-bottom: #255C6A 3px solid;
    outline:0;
  }

  :hover {
    border-bottom: #255C6A 3px solid;
    color: #255C6A;
  }
`;

function Inputfields({ ...props }) {
  return (
    <>
      <Input {...props} />
    </>
  );
}

export default Inputfields;
