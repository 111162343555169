import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import EB2 from "../images/2EB2.png"


const PageWrapper = styled.div`
display: flex;
flex-direction: column;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.90);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding-bottom: 50px;

@media (max-width: 768px) {
    width:100%;
    padding: 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
`;

const TextHeader = styled.h3`
`;

const Text = styled.p`
width:95%;
@media (max-width: 768px) {
  width: 100%;
  }
`;

const Text2 = styled.div`
width:95%;
@media (max-width: 768px) {
  width: 100%;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  }

`;

const Green = styled.span`
  color: ${farben.greenButtons};
  font-weight: bold;
  font-size: 1.6em;
  height: 30px;
width: 30px;
margin-right: 20px;

@media (max-width: 768px) {
  margin-right: 15px;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 50%;

@media (max-width: 768px) {
  width: 85%;
  }

`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 3px;
  width: 390px;
  }
`;

const Image = styled.img`
width: 100%;
`;
const Schritte2 = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt12 = styled.div`
    display: flex;
    align-items: center;
    margin: 10px;

@media (max-width: 768px) {
  margin: 2px 2px 20px 2px;
  }
  `;

const TextBox2 = styled.div`
width: 90%;
display: flex;
justify-content: center;
line-height: 150%;

@media (max-width: 768px) {
  width: 100%;
  }
`;
export default function EinleitungII() {

    return (
        <PageWrapper>
          <ContentWrapper>
          <NewWrapper>
          <ImgBox>   
            <ImageWrapper>
            <Image  alt="FU1" src={EB2}></Image> 
            </ImageWrapper>  
          </ImgBox>
          <TextBox2>
            <Schritte2>
                <Schritt12>
                <Green>✓</Green> 
                    <Text2>
                    Individuelle Ernährungsanpassung, die wirklich zu dir und deinem Lebensstil passt
                    </Text2>
                </Schritt12>
                <Schritt12>
                <Green>✓</Green> 
                    <Text2>
                    Coaching-Material für deine erfolgreiche Umsetzung im Alltag
                    </Text2>
                </Schritt12>
                <Schritt12>
                <Green>✓</Green> 
                    <Text2>
                    Hilfreiche Praxis-Tools, damit du im Arbeitsalltag Zeit sparst
                    </Text2>
                </Schritt12>
                <Schritt12>
                <Green>✓</Green> 
                    <Text2>
                    Aufklären von Ernährungsmythen, damit du in Zukunft verbreitete Fehler vermeidest                    
                    </Text2>
                </Schritt12>
                <Schritt12>
                <Green>✓</Green> 
                    <Text2>
                    Vermittlung wissenschaftlicher Grundlagen, um Ernährung für dich klar und verständlich zu machen                    
                    </Text2>
                </Schritt12>
            </Schritte2>
          </TextBox2> 
          </NewWrapper>
          </ContentWrapper>  
          <TextBox>
            <TextHeader> Warum ist unser Ansatz so erfolgreich?</TextHeader>
            <Text>Wir haben selbst erfahren, dass langfristige Erfolge nur dann möglich sind, wenn wir die grundlegenden Ernährungsprinzipien wirklich verstehen und diese sinnvoll und individuell in unserem Alltag anwenden. 
            <br/>
            <br/>
            Du erfährst in der Ernährungsberatung die neuesten Erkenntnisse über die evolutionäre Anpassung unseres Körpers und wie du dieses Wissen für dich nutzen kannst. So wird Ernährung für dich endlich wieder einfach und logisch nachvollziehbar, damit du die gleichen Erfolge feiern kannst wie wir.
            </Text>
            <br/>
            
            <TextHeader> Keine Ernährungsdogmen und Ideologien!</TextHeader>
            <Text> Weil eine optimale Ernährung immer individuell ist, haben Ideologien keinen Platz bei uns. Stattdessen nutzen wir die Vorteile unterschiedlicher Ernährungsformen und passen sie an deine Voraussetzungen und Ziele an. </Text>
          </TextBox>
        </PageWrapper>
      );
}

