import HomeImageSlideI from "../images/PfeilKreis.png";
import styled from "styled-components";
import farben from "../components/GlobalStyles";
import MoStory from "../components/MoStory";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
background-color: ${farben.cremeBackground};
color: black;
font-family: ${farben.font1};
align-items: center;
padding: 30px 0px 0px 30px;
@media (max-width: 768px) {
    width:90%;
  }
`;

const ImgBox = styled.img`
display: flex;
justify-content: center;
width: 90%;
height: 70vh;
`;

function OurStoryNewFunction() {
    return (  
        <PageWrapper>
            <h1>OUR STORY</h1>
            <ImgBox src={HomeImageSlideI}></ImgBox>
            <MoStory></MoStory>
        </PageWrapper>
        
    );
}

export default OurStoryNewFunction;