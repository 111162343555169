import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import MainImage from '../components/MainImage';
import styled from "styled-components";
import RootsOverview from '../components/RootsOverview';
import Intro from '../components/Intro';
import Intro2 from '../components/Intro2';
import Strategie from '../components/Strategie';
import EinleitungI from '../components/EinleitungI';
import EinleitungII from '../components/EinleitungII';
import HomeRoots from '../components/HomeRoots';
import ZitatI from '../components/ZitatI';
import HomePhilosophie from '../components/HomePhilosophie';
import ZielgruppeOverlay from '../components/coachingoverviewcomponents/ZielgruppeOverlay';
import HomeHamsterradI from '../components/HomeHamsterradI';
import HomeHamsterradII from '../components/HomeHamsterradII';
import NeuerAbschnitt from '../components/NeuerAbschnitt.js';
import ZitatII from '../components/ZitatII';
import ZitatVII from '../components/ZitatVII';
import EigenerWeg from '../components/EigenerWeg';
import AnwendungderRoots from '../components/AnwendungderRoots';
import CallToAction from '../components/CallToAction';
import AboutUsOverview from '../components/AboutUsOverview';
import Referenzen from '../components/Referenzen';
import ImageSlider from "../components/ImageSlider";
import Irgendwas from "../components/Irgendwas.js";
import Backbaum from "../images/1Home0BaumHintergrund.png"
import CoachingGeldZurück2 from "../components/CoachingGeldZurück2";
import Background from "../images/CoachingOverview.jpg";
import BackgroundMobile from "../images/3C1M.jpg";
import farben from "../components/GlobalStyles.js";
import Overlay from "../components/coachingoverviewcomponents/OverviewOverlay";
import Programm1 from "../components/coachingoverviewcomponents/Programm1";
import Programm2 from "../components/coachingoverviewcomponents/Programm2";
import EigenerWeg2 from "../components/coachingoverviewcomponents/EigenerWeg2";


const PageWrapper = styled.div`
  //background-image: url("https://i.stack.imgur.com/JxR07.png");
  background-image: url(${Background});
  background-position: 0% 0%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  font-family: Sen;
@media (max-width: 768px) {
    background-image: url(${BackgroundMobile});
    background-size: auto;
    background-position: 29% 18%;
    width:100%;
    padding: 0px 0px 0px 0px;
  }
`;

const WhiteBackground = styled.div`
background-color: transparent;
display: flex;
flex-direction: column;

@media (max-width: 768px) {
      background-color: rgba(255, 255, 255,1);
      top: 100%;
      height: 100%;
      width: 100vw;  
}

`;

function Home() {
  return (
    <PageWrapper>
    <Overlay />
    <ZitatVII />
    <WhiteBackground>
    <br/>
    <ZielgruppeOverlay />
    <br/>
    <Referenzen />
    <br/>
    <CoachingGeldZurück2 />
    <br/> 
    <br/>
    <Programm1 />
    <br/>
    <br/>
    <Programm2 />
    <br/>
    <br/>
    <EigenerWeg2 />
    </WhiteBackground>
    </PageWrapper>
  );
}

export default Home;
