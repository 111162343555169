import MoStory from "../components/MoStory";
import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import styled from "styled-components";
import Backbaum from "../images/1Home0BaumHintergrund.png"
import Philosophie from "./Philosophy";
import AboutusOpen from "../components/AboutUsOpen";



const PageWrapper = styled.div`
  //background-image: url("https://i.stack.imgur.com/JxR07.png");
  background-image: url(${Backbaum});
  background-position: 100% 60%;
  background-size: 23% 75%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-family: Sen;
  display: flex;
  flex-direction: column;

@media (max-width: 768px) {
    width:100%;
    flex-direction: column;
    padding: 0px 0px 20px 0px;
    background-size: 50% 50%;
  }
`;

function Home() {
  return (
    <PageWrapper>
    <AboutusOpen />
    <MoStory></MoStory>
    <Philosophie />
    </PageWrapper>
  );
}

export default Home;

           
   