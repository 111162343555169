import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

//const Wave = styled.div`
//  --size: 50px;
//  --p: 25px;
//  --R: 55.9px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;
  
//  height: 400px;
//  margin: 10px 0;
  
  
//-webkit-mask:
//  radial-gradient(var(--R) at left 50% bottom calc(var(--size) + var(--p)),#000 99%,#0000 101%) 
//   left calc(50% - 2*var(--size)) bottom 0/calc(4*var(--size)) 51% repeat-x,
//  radial-gradient(var(--R) at left 50% bottom calc(-1*var(--p)),#0000 99%,#000 101%) 
//   left 50% bottom var(--size)/calc(4*var(--size)) calc(51% - var(--size)) repeat-x,
//  radial-gradient(var(--R) at left 50% top calc(var(--size) + var(--p)),#000 99%,#0000 101%) 
//   left calc(50% - 2*var(--size)) top 0/calc(4*var(--size)) 51% repeat-x,
//  radial-gradient(var(--R) at left 50% top calc(-1*var(--p)),#0000 99%,#000 101%) 
//   left 50% top var(--size)/calc(4*var(--size)) calc(51% - var(--size)) repeat-x;
//  background:linear-gradient(90deg,red,blue);  
 // border: none;


//.box + .box {
//  --d: bottom;
//}
//`;


const PageWrapper = styled.div`
  display: flex;
  //background: linear-gradient(to bottom, grey,1%, transparent 90%, grey);
  background: transparent;
  flex-direction: column;
  color: black;
  font-size: 22px;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 200%;
  margin: auto;
@media (max-width: 768px) {
margin-top: 50px;
  }
`;

const Zitat = styled.section`
  
  font: ${farben.fontQuote};


@media (max-width: 768px) {
    
}
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 60%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;




export default function ZitatI() {

    return (
      <>
        <PageWrapper>
        <Zitatstrich/>
         <Zitat>
            „Wir sind nicht geschaffen für die Welt, die wir geschaffen haben“
         </Zitat>
        <Zitatstrich/> 
        </PageWrapper>
        </>
      );
}
