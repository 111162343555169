import React from "react"
import styled from "styled-components"
import Farben from "../components/GlobalStyles.js"
import Kachel from "../components/Kachel.js";

const StyledDiv = styled.div`

margin:15px 10px;
display: flex;
justify-content: space-evenly;
flex-wrap: wrap;
color: ${Farben.Schrift1};

@media (max-width: 768px) {
    flex-direction:column;
    align-items: center;
  }
`;

const dataErnährungsberatung = ["Anamnese des Gesundheitszustands",  
  "Auswertung des Ernährungsprofils", 
  "Individuelle Ernährungsanpassung",
  "Klare und nachhaltige Vermittlung der Ernährungsprinzipien", 
  "Beratung für Nahrungsergänzung",
  "Ernährungs-Tipps und Unterlagen für die erfolgreiche Umsetzung",
  "Differenzierte und objektive Vermittlung aktueller wissenschaftlicher Erkenntnisse" 
  ]

  const dataTrainingsberatung = [
  "Analyse der aktuellen Trainings - Komponenten", 
  "Vermittlung wissenschaftlicher Trainingsprinzipien",
  "Erstellung Individueller Trainingspläne", 
  "Oder Optimierung des Trainingsplans für: \b Muskelaufbau \b Beweglichkeit und Gelenkgesundheit \b Schnellkraft und Athletik"
  ]

  const dataMobility = [
    "Beweglichkeitsanalyse",
    "Individuelles Mobility-Programm",
    "Kombiniertes Kraft – und Beweglichkeitstraining",
    "Faszientraining",
    "Genaue Anleitung und Korrektur der Bewegungsausführung",
    "Lösungsansätze für Gelenkprobleme",
    "Verletzungsprävention"
  ]
function Leistungen(){
return (
    <StyledDiv>
      <Kachel headline="Ernährungsberatung" subheadline="Für Klarheit bei der Basis des Lebens" body={dataErnährungsberatung}/>
      <Kachel headline="Trainingsberatung" subheadline="Für ein optimales Training" body={dataTrainingsberatung}/>
      <Kachel headline="Mobility Coaching" subheadline="Für bessere Beweglichkeit und gesunde Gelenke" body={dataMobility}/>
      <Kachel headline="Platzhalter I" subheadline="Für Klarheit bei der Basis des Lebens" body={dataErnährungsberatung}/>
      <Kachel headline="Platzhalter II" subheadline="Für Klarheit bei der Basis des Lebens" body={dataErnährungsberatung}/>
      <Kachel headline="Platzhalter III" subheadline="Für Klarheit bei der Basis des Lebens" body={dataErnährungsberatung}/>
    </StyledDiv>
  );}


export default Leistungen;