import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
  display: flex;
  background: linear-gradient(to bottom,transparent,5%,#FFFCF0,95%,transparent);
  flex-direction: column;
  color: black;
  font-size: 22px;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 200%;
@media (max-width: 768px) {

  }
`;

const Zitat = styled.section`
  
  font: ${farben.fontQuote};


@media (max-width: 768px) {
    
}
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 60%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;

const Space = styled.div`
height: 90px;
`;

export default function ZitatIII() {

    return (
        <PageWrapper>
         <Space /> 
         <Zitatstrich/> 
         <Zitat>
            Mit dem Ziel, möglichst viele Menschen aus diesem „Gesundheits-Hamsterrad” zu befreien, <br />
            haben wir ReLife ins Leben gerufen!
         </Zitat>
         <Zitatstrich/>
         <Space />
        </PageWrapper>
      );
}
