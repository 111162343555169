import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;

@media (max-width: 768px) {
    width:100%;
    background: linear-gradient(to bottom,transparent,22%,rgba(255,255,255,0.99),70%,rgba(255,255,255,1));
    padding: 270px 0px 0px 0px;
  }
`;


const Zitat = styled.h3`
  
  font: ${farben.fontQuote};
  font-size: 1.6em;
  line-height: 180%;


@media (max-width: 768px) {
    text-align: center;
    width: 90%;
    font-size: 1.3em;
}
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 60%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;



export default function ZitatVII() {

    return (
        <PageWrapper>
         <Zitatstrich/> 
         <Zitat>
            „Gesundheit ist nicht bloß die Abwesenheit von Krankheit“<br/>
            - sondern der Zugang zu deiner vollen Leistungsfähigkeit
         </Zitat>
         <Zitatstrich/>
        </PageWrapper>
      );
}
