import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.90);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 160px 0px 0px 0px;
    background: linear-gradient(to bottom,transparent,16%,rgba(255,255,255,0.98),35%,rgba(255,255,255,1));
    text-align: center;
      }
`;

const TextBox = styled.div`
width: 90%;
`;

const TextHeader = styled.h3`
margin: 70px 0px 20px 0px;

@media (max-width: 768px) {
  margin: 0px;
      }
`;



const Text = styled.p`
text-align: start;
`;

export default function EinleitungII() {

    return (
        <PageWrapper>
          <TextBox>
            <TextHeader> Nimm die Abkürzung und lerne aus unserer Geschichte!</TextHeader>
            <Text>Erfahre wie du deine Ernährung optimierst & deinem Körper endlich das gibst, was er braucht – damit du jeden Tag deine beste Version sein kannst!
            </Text>
            <br/>
            
          </TextBox>
        </PageWrapper>
      );
}
