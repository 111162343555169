import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";
import C13 from "../images/91HomeSlide.jpg";
import C131 from "../images/91Home1Slidemobile.jpg";

const PageWrapper2 = styled.div`
color: black;
align-items: center;
justify-content: center;
align-self: center;
line-height: 200%;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const TextContainer = styled.div`
text-align: center;
position: absolute;
top: 60%;
left: 50%;
font-size: 2em;
color: white;
transform: translate(-50%, 50%);
width: 75%;

@media (max-width: 768px) {
  top: 20%;
  font-size: 1.2em;
  width: 90%;
  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 6px 25px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: rgba(150,201,48,.9); 
  align-items: flex-start;
  align-self: center;
  width: 50%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons};
  
}

@media (max-width: 768px) {
  margin: 0px 0px 30px 0px;
  width: 80%;
  }
`;

const Image = styled.img`
width: 100%;

@media (max-width: 768px) {
    display: none;
  }
`;

const ImageMobile = styled.img`
    display: none;
@media (max-width: 768px) {
    display:block;
    width:100%;
    margin-top: 20%;
  }
`;

export default function FC13() {

    return (
        <>
        <PageWrapper2>
           <Image src={C13} />
           <ImageMobile src={C131} />
           <TextContainer>
      Ergebnisorientiertes Gesundheits-Coaching &
      <br/> individuelle Lebensstil-Optimierung
      <br/>
      <br/>
      <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Info-Gespräch vereinbaren</Button2>
      </TextContainer>  
        </PageWrapper2>
        </>
      );
}



