import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C2 from "../../images/1Home7.1Atmung.png"
import C3 from "../../images/1Home7.2-Bewegung.png"
import C4 from "../../images/1Home7.3Natur.png"
import C5 from "../../images/1Home7.4Ernährung.png"
import C6 from "../../images/1Home7.5Geist.png"
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 0px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Text2 = styled.span`
font-size: 8pt;
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
margin: 40px 0px;
line-height: 200%;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
  flex-direction:column;
  margin: 10px 0px;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: start;
justify-content: space-evenly;
width: 50%;

@media (max-width: 768px) {
  width: 100%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 55px;
  }
`;

const Image = styled.img`
width: 75px;
height: 75px;

@media (max-width: 768px) {
  width: 55px;
  height: 55px; 
  }

  @media (max-width: 300px) {
  width: 55px;
  height: 55px; 
  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 16px;
  line-height: 150%;
  border: none;
  width: 90%;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  padding: 10px 20px;
  box-shadow: ${farben.boxshadow};


  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 1px;
  display:block;
  width: 80%;
  }
`;


export default function C2Funktion() {

    return (
        <PageWrapper>
          <ContentWrapper>  
            <ImgBox>   
                <ImageWrapper>
                <Image  alt="FU1" src={C2}></Image> 
                </ImageWrapper>
                <ImageWrapper>
                <Image  alt="FU1" src={C3}></Image> 
                </ImageWrapper>  
                <ImageWrapper>
                <Image  alt="FU1" src={C4}></Image> 
                </ImageWrapper>  
                <ImageWrapper>
                <Image  alt="FU1" src={C5}></Image> 
                </ImageWrapper>  
                <ImageWrapper>
                <Image  alt="FU1" src={C6}></Image> 
                </ImageWrapper>  
            </ImgBox>
            <TextBox>
            <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenloses Erstgespräch</Button2>
            <Text2>Die Plätze für das Coaching sind streng limitiert</Text2>
          </TextBox>
          </ContentWrapper>  

        </PageWrapper>
      );
}

