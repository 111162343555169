import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import styled from "styled-components";
import Background from "../images/1ErnährungBackground.jpg"
import BackgroundMobile from "../images/1ErnährungBackgroundMobile.jpg"
import ErnährungsberatungHeadline from "../components/ErnährungsberatungHeadline.js";
import ErnährungsberatungHeadlineII from "../components/ErnährungsberatungHeadlineII.js";
import ErnährungsberatungText1 from "../components/ErnährungsberatungText1.js";
import ErnährungsberatungText2 from "../components/ErnährungsberatungText2.js";
import ErnährungsberatungText3 from "../components/ErnährungsberatungText3.js";
import ErnährungsberatungErfolgsrezept from "../components/ErnährungsberatungErfolgsrezept.js";
import ErnährungsberatungWiedererkennung from "../components/ErnährungsberatungWiedererkennung.js";
import EB2 from "../components/EB2.js";
import EB3 from "../components/EB3.js";
import EB4 from "../components/EB4.js";
import EB5 from "../components/EB5.js";
import EB6 from "../components/EB6.js";
import EB7 from "../components/EB7.js";
import EB8 from "../components/EB8.js";
import EB9 from "../components/EB9.js";
import EB10 from "../components/EB10.js";
import ZitatV from "../components/ZitatV.js";


const PageWrapper = styled.div`
  //background-image: url("https://i.stack.imgur.com/JxR07.png");
  background-image: url(${Background});
  background-position: 0% 0%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  font-family: Sen;
  
  @media (max-width: 768px) {
    width:100%;
    padding: 0px 0px 20px 0px;
    background-image: url(${BackgroundMobile});
    background-position: 10% 30%;
    background-size: contain;
  }
  `;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;

const WhiteBackground = styled.div`
background-color: transparent;
display: flex;
flex-direction: column;

@media (max-width: 768px) {
      background-color: rgba(255, 255, 255,1);
      top: 100%;
      height: 100%;
      width: 100vw;  
}

`;

function EBeratung() {
  return (
    <PageWrapper>
    <ErnährungsberatungHeadlineII />
    <ErnährungsberatungHeadline />
    <ErnährungsberatungText1 />
    <Break/>
    <Break/>
    <ErnährungsberatungErfolgsrezept />  
    <ErnährungsberatungText2 /> 
    <ErnährungsberatungWiedererkennung /> 
    <ErnährungsberatungText3 />
    <Break/>
    <Break/>
    <EB2 />
    <Break/>
    <Break/>
    <EB3 />
    <Break/>
    <Break/>
    <EB4 />
    <EB5 />
    <EB6 />
    <Break/>
    <Break/>
    <EB7 />
    <Break/>
    <Break/>
    <EB8 />
    <Break/>
    <Break/>
    <ZitatV />
    <EB10 />
    <EB9 />
    </PageWrapper>
  );
}

export default EBeratung;
