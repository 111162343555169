import React from "react";
import styled from "styled-components";
import farben from "../components/GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Philo1 from "../images/AboutPhilo1.jpg"
import Philo2 from "../images/AboutPhilo2.jpg"
import Philo3 from "../images/AboutPhilo3.jpg"



const PageWrapper = styled.div`
display: flex;
flex-direction:column ;
width: 100%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;
position: relative;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
    text-align: center;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const NewWrapperReverse = styled.div`
display: flex;
flex-direction: row-reverse;
align-items: center;

@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
  display: none;
  }
`;

const ImgBox2 = styled.div`
display: none;

@media (max-width: 768px) {
  display: flex;
  align-items: center;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
height: 100%;
padding: 0px 20px;

@media (max-width: 768px) {
width: 100%  ;
}

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}
`;

const Text = styled.p`
width: 100%;
@media (max-width: 768px) {
  text-align: start;
  }
`;



export default function Philosophie() {

  const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
  
  margin-bottom: 10px;
  padding: 10px;

  }
  
  `;

const TextHeader = styled.h1`
text-align: center;
`;

    return (
      <>
        <PageWrapper>
            <TextHeader id="Philo"> 
                P h i l o s o p h i e 
            </TextHeader>
          <ContentWrapper>
          <NewWrapper>
          <TextBox>
            <ImgBox2>
            <ImageWrapper >
            <Image  alt="FU1" src={Philo3}></Image> 
            </ImageWrapper>  
          </ImgBox2>
          <Text>
                <TextHeader> 
                „Lernen ist eine lebenslange Tugend”
                </TextHeader>
            </Text>
            
            <Text>
                Ein Lebensmotto für uns Alle & unsere Philosophie bei ReLife. Auf unserer Suche nach Wahrheit, hinterfragen wir, angetrieben von unserem Wissensdurst, regelmäßig unser eigenes Wissen und unsere eigenen Überzeugungen. Wir wollen uns distanzieren von jeglicher Ideologie und sind so auch ganz der Meinung: 
            </Text>
            <Text>
                <TextHeader> 
                 „Gesundheit ist individuell”
                </TextHeader>
            </Text>
            <Text>
                Wir als Menschen sind jeder für sich ganz besonders und individuell - so auch unsere Gesundheit. Wir haben alle unterschiedliche Voraussetzungen, unsere eigene Geschichte und unsere ganz persönlichen Wünsche. Dies nehmen wir uns bei ReLife zu Herzen und auf diese Weise betreuen wir jeden Menschen, der zu uns kommt!
                <br/>Wir betrachten dich und deine Story ganzheitlich & vor allem:
            </Text>
            
          </TextBox>           
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={Philo3}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapper>
          <br/>
          <TextHeader> 
                „Wir nehmen uns Zeit!“
                </TextHeader>
                <br/>
          <NewWrapperReverse>
          <TextBox>
            <Text>
                7,5 Minuten ist die Durchschnittsdauer des deutschen Arztbesuches. Genug für die Symptome, zu wenig für die Ursachen… Traurig aber wahr. Wir möchten mit ReLife zu langfristiger Lebensqualität beitragen. Dafür müssen wir uns jedoch Zeit nehmen und uns den Ursachen von Krankheiten widmen und nicht den Symptomen. Denn auch für jeden guten Heimgärtner gilt:
            </Text>
            <Text>
                <TextHeader> 
                 „Du musst das Unkraut an der Wurzel packen”
                </TextHeader>
            </Text>
            <Text>
             Wenn du Unkraut nur an der Oberfläche beseitigst, kannst du dir ziemlich sicher sein, dass du nach kurzer Zeit doppelt so viel Unkraut vorfinden wirst als zuvor. So ist es auch mit fast allen Krankheiten unserer heutigen Zeit. Statt nur an der Oberfläche zu arbeiten, müssen wir in die Tiefe gehen und richtig hinschauen. Wir müssen unseren Blick auf die Entstehung von Krankheit richten und uns den Ursachen unserer Beschwerden widmen. 
            </Text> 
          </TextBox>           
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={Philo2}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapperReverse>
          <br/>
                <TextHeader> 
                    „Gesundheit bedeutet Verantwortung zu übernehmen“
                </TextHeader>
                <br/>
          <NewWrapper>
          <TextBox>
            <Text>
                Statt eine beliebige Pille einzuschmeißen und die Augen zu verschließen, können wir aus der Betrachtung der Ursachen, Lehren für unseren Alltag ziehen. Dies gibt uns die Möglichkeit Gesundheit, Energie & Glück wieder in unserem Leben zu etablieren. Die neuesten Erkenntnisse der Epigenetik lehren uns heute:
            </Text>    
            <Text>
                <TextHeader> 
                „Wir sind nicht Opfer, sondern Meister unserer Gene!”
                    <br/>- Bruce H. Lipton
                </TextHeader>
            </Text>
            <Text>
                Du hast das Privileg Tag für Tag Entscheidungen für deine Gesundheit & für dich zu treffen. Mit dem richtigen Wissen und den entsprechenden Gewohnheiten können wir so die Veränderung schaffen die wir uns wünschen.
            </Text>
            <Text>
                <TextHeader> 
                „Sei selbst die Veränderung, die du in der Welt sehen willst"
                    <br/>- Mahatma Ghandi
                </TextHeader>
            </Text>
            <Text>
                Mit ReLife wollen wir vorangehen. Wir wollen zu einem Perspektivwechsel in unserer Gesellschaft beitragen, Menschen dazu anregen ihre Prioritäten neu zu ordnen und Wohlstand neu zu definieren. Sich von starren Mustern und Überzeugungen zu lösen und sich zurück zu besinnen auf die wirklich wertvollen Dinge des Lebens. Unsere Gesundheit, den inneren Frieden und die Verbundenheit mit der Welt um uns herum. 
                Zurück ins LEBEN. ReLife.
            </Text>
            
          </TextBox>           
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={Philo1}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapper>    
         </ContentWrapper>  
          
        </PageWrapper>
                  </>
      );
}
