import React from "react";
import { BrowserRouter as Router} from "react-router-dom";
import styled from "styled-components";
import Intro2 from '../components/Intro2';
import EinleitungI from '../components/EinleitungI';
import EinleitungII from '../components/EinleitungII';
import HomeRoots from '../components/HomeRoots';
import ZitatI from '../components/ZitatI';
import HomePhilosophie from '../components/HomePhilosophie';
import Zielgruppe from '../components/Zielgruppe';
import HomeHamsterradI from '../components/HomeHamsterradI';
import HomeHamsterradII from '../components/HomeHamsterradII';
import NeuerAbschnitt from '../components/NeuerAbschnitt.js';
import ZitatII from '../components/ZitatII';
import ZitatIII from '../components/ZitatIII';
import EigenerWeg from '../components/EigenerWeg';
import AnwendungderRoots from '../components/AnwendungderRoots';
import CallToAction from '../components/CallToAction';
import AboutUsOverview2 from '../components/AboutUsOverview2';
import Referenzen from '../components/Referenzen';
import ImageSlider from "../components/ImageSlider";
import Backbaum from "../images/1Home0BaumHintergrund.png"
import Hilfe from "../components/Hilfe";
import HomeBild from "../components/HomeBild";
import ImageSlider2 from "../components/ImageSlider2";



const PageWrapper = styled.div`
  //background-image: url("https://i.stack.imgur.com/JxR07.png");
  background-image: url(${Backbaum});
  background-position: 100% 60%;
  background-size: 23% 75%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  font-family: Sen;

@media (max-width: 768px) {
  background-image: none;  
  width:100%;
    flex-direction: column-reverse;
    padding: 0px 0px 20px 0px;
    background-size: 50% 50%;
  }
`;

function Home() {
  return (
    <PageWrapper>
    <HomeBild></HomeBild>
    <Intro2 />
    <Referenzen />
    <Hilfe />
    <EinleitungI />
    <NeuerAbschnitt />
    <ImageSlider2 />
    <ZitatII />
    <EinleitungII />
    <HomeRoots />
    <ZitatI />
    <HomePhilosophie />
    <Zielgruppe />
    <HomeHamsterradI />
    <HomeHamsterradII />
    <ZitatIII />
    <EigenerWeg />
    <AnwendungderRoots />
    <ZitatII />
    <CallToAction />
    <AboutUsOverview2 />
    </PageWrapper>
  );
}

export default Home;

//<ImageSlider />