import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import C111 from "../../images/C111.png"
import C112 from "../../images/C112.png"
import C113 from "../../images/C113.png"
import C114 from "../../images/C114.png"
import C115 from "../../images/C115.png"
import C116 from "../../images/C116.png"

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
  width:100%;
  flex-direction: column;
  
  padding: 10px 0px;
  }
`;

const PageWrapperII = styled.div`
display: none;
flex-direction: column;
justify-content: center;
background-color: rgba(255,255,255,.4);
color: black;
align-items: center;
padding: 45px 20px;
@media (max-width: 768px) {
  display: flex;
  width:100%;
  flex-direction: column;
  padding: 10px 0px;
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
}
`;

const Image = styled.img`
width: 60px;
height: 60px;
`;



const BigText = styled.div`
font-size: 16pt;
font-weight: bold;
text-align: center;
`;

const BigTextOver = styled.div`
font-size: 16pt;
font-weight: bold;

@media (max-width: 768px) {
    text-align: center;
    
}
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  width: 90%;
  height: 50%;
  margin-bottom: 40px;
//  flex-wrap: wrap;
//  min-width: 300px;

@media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    width: 100%;
  }
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 45%;

@media (max-width: 768px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;

const Text = styled.div`
text-align: center;
width: 80%;

@media (max-width: 768px) {
  margin-bottom: 50px;
  width: 100%;
  }
`;

export default function C12() {
    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <br/>
          <br/>
          <BigTextOver> Was du von uns im ReLife-Coaching erwarten kannst. </BigTextOver>
          <br/>
          <br/>
          <NewWrapper>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C111}></Image>
            
              <BigText>12 Wochen Programm</BigText>
              <br/>
              <Text>
              Bilde in nur 12 Wochen das Fundament für deine nachhaltige Gesundheit und erfahre was es bedeutet sein volles Potential zu nutzen. 
              <br /><br />
              Natürlich stehen wir dir auch nach Ende des Coachings weiterhin für Fragen zur Verfügung.
              </Text>      
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C112}></Image>
            
            <BigText>Coaching Materialien</BigText>
              <br/>
              <Text>Erleichtere dir mit unseren „ReLife-Coaching Materialien“ das Leben und erhalte die perfekte Begleitung für deine erfolgreiche Umsetzung im Alltag.  </Text>
            </Wrapper>
          </Flex>  
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C113}></Image>
            
              <BigText>Ziele setzen und erreichen</BigText>
              <br/>
              <Text>
              Gemeinsam setzen wir deine individuellen Etappen-Ziele und noch viel wichtiger - wir gehen in die Umsetzung! 
              <br /><br />
              Durch wöchentliche Aufgaben und Herausforderungen wirst du das gelernte Wissen direkt in die Praxis bringen und so nach und nach erste Erfolge feiern!
              </Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C114}></Image>
            
            <BigText>12 Live Coachings</BigText>
              <br/>
              <Text>
              Lerne in den wöchentlichen 1:1 Live Video-Calls wie die 5 Roots auf deinen Körper wirken und erfahre wie du diese Schritt für Schritt individuell auf dich und deine Ziele anwendest.
                <br /> <br />
              Jeder Live Call ist ein weiterer Schritt zurück indeine Energie.
              </Text>
            </Wrapper>
          </Flex>  
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C116}></Image>
            
              <BigText>Garantierter Erfolg</BigText>
              <br/>
              <Text>
                Sobald wir uns für eine Zusammenarbeit entscheiden, gibt es keine Ausreden mehr. Aus dem Profisport wissen wir, dass Erfolg mit Konstanz einhergeht - Jeden Tag ein bisschen besser.
                <br /> <br />
                Wir sorgen dafür, dass du am Ball bleibst und wirkliche Veränderung erfährst - Erfolg garantiert!</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C115}></Image>
            
            <BigText>Täglicher Ansprechpartner</BigText>
              <br/>
              <Text>
                Profitiere von unserer tägliche Unterstützung via Whats App, Telegram & Co, und erhalte jederzeit eine Antwort auf deine Fragen, damit du immer genau weißt was zu tun ist. 
                <br /><br/>
                Wie gesagt, wir begleiten dich bei jedem Schritt auf deinem Weg und gestalten deinen Neuanfang gemeinsam. Versprochen!
            </Text>
            </Wrapper>
          </Flex>  
          <br/>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
        
      </> 
        
      );
}
