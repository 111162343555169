import React from "react";
import {useInView} from "react-intersection-observer"
import styled from "styled-components";
import PortraitMo from "../images/HomePortraitMo.png"
import PortraitJoel from "../images/HomePortraitJoel.png"
import PortraitSohrab from "../images/HomePortraitSohrab.png"
import PortraitGawlik from "../images/HomePortraitGawlik.png"
import PortraitFreese from "../images/HomePortraitFreese.png"
import PortraitKeferstein from "../images/HomePortraitKeferstein.png"
import PortraitStefan from "../images/HomePortraitDietsche.png"
import farben from "./GlobalStyles";

const PageWrapper = styled.div`
text-align:center;

margin-top: 30px;

@media (max-width: 768px) {
    margin: 0px;
  }
`;

const PortraitRow = styled.div`
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-row-gap:10px;
  }
`;

const PortraitRow2 = styled.div`
    display: grid;
    grid-template-columns: 30% 20% 30% 20%;
    margin-bottom:30px;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-row-gap:10px;
        margin-bottom:0px;
  }
`;

const PortraitRow3 = styled.div`
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;
    margin-bottom:30px;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-row-gap:10px;
        margin-bottom:30px;
  }
`;

const PortraitRow4 = styled.div`
    display: grid;
    grid-template-columns: 30% 20% 30% 20%;
    margin-bottom:30px;


    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-row-gap:10px;
  }
`;

const GridImage = styled.img`
height:20vh;

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}

@media (max-width: 768px) {
        max-width: 90%;
  }
`;

const GridImage2 = styled.img`
height:25vh;

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}

@media (max-width: 768px) {
        max-width: 90%;
  }
`;


const Text = styled.div`
`;

const Fattext = styled.div`
font-weight: bold;
font-size: 20px;
margin-bottom: 10px;
`;

const Div1 = styled.div`

@keyframes portraitMoIn{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
        margin-top: 30px;
  }
`;

const Div3 = styled.div`

@keyframes portraitJoelIn{
    from{
        transform: translateX(30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
        margin-top: 30px;
  }
`;

const Subline = styled.h2`
display: flex;
justify-content: flex-start;
padding-left:100px;
padding-top: 10px;
padding-bottom: 10px;
color: white;
background: linear-gradient(to right, ${farben.greenButtons}, transparent);
margin: 75px 0px;

@media (max-width: 768px) {
        
  }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

@keyframes portraitTextIn{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const TextWrapper2 = styled.div`
display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;

@keyframes portraitTextIn{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
        align-items: center;
        justify-content: center;
  }
`;

const Headline = styled.div`
align-self: center;
font-size: 22pt;
color:#96C92F;
`;

const Space = styled.div`
display: flex;
justify-content: flex-start;

@media (max-width: 768px) {
        justify-content: center;
      }
`;


const Zitatstrich = styled.hr`
  display: block;
  width: 40%;
  color: #96c92f;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #96c92f, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;
`;

export default function RootsOverview() {

    return (
        <PageWrapper>
            <Headline> <b>Deine Coaches</b> </Headline>
            <br></br>
            <Zitatstrich/>
            <br/>
          <PortraitRow>
              

              
              <>
              <Div1>
              <GridImage2  src={PortraitMo}/>
              </Div1>
              <Space>
              <TextWrapper>
              <Fattext>Mo</Fattext><Text>Fachberater für Ernährungsmedizin<br/>Entgiftungs-/Darm-Immuntherapeut<br />Ex-Beachvolleyballprofi</Text> 
              </TextWrapper>
              </Space>
              </>
   
             
              <>
              <Div3>
              <GridImage2 src={PortraitJoel}/>
              </Div3>
              <Space>
              <TextWrapper>
              <Fattext>Joel</Fattext><Text>Sportwissenschaftler (B.Sc.) <br/>Fachberater für Ernährungsmedizin <br/>Athletik-/Mobility Trainer</Text>
              </TextWrapper>
              </Space>
              </>  
              
          </PortraitRow>
          <Subline>
              Unsere Partner
          </Subline>
          <PortraitRow3>
              
              <>
              <Div3>
              <GridImage  src={PortraitGawlik}/>
              </Div3>
              <Space>
              <TextWrapper2>
              <Fattext>Dr. med. Eckhard Gawlik</Fattext><Text>Facharzt für Ernährungsmedizin <br/>Heilpraktiker</Text> 
              </TextWrapper2>
              </Space>
              </>

              <>
              <Div3>
              <GridImage src={PortraitSohrab}/>
              </Div3>
              <Space>
              <TextWrapper2>
              <Fattext>Sohrab Pahpahn</Fattext><Text>Physiotherapeut <br/> Osteopath<br/>Yogalehrer</Text>
              </TextWrapper2>
              </Space>
              </>  
     
          </PortraitRow3>
          <PortraitRow3>
              
              <>
              <Div1>
              <GridImage  src={PortraitFreese}/>
              </Div1>
              <Space>
              <TextWrapper2>
              <Fattext>Dr. nat. Jens Freese</Fattext>
              <Text>Immunologe <br/>Dipl. Sportwissenschaftler<br/>Gründer des Freese Instituts</Text> 
              </TextWrapper2>
              </Space>
              </>
                
              <>
              <Div1>
              <GridImage src={PortraitStefan}/>
              </Div1>
              <Space>
              <TextWrapper2>
              <Fattext>Dr. med. Stefan Dietsche</Fattext>
              <Text>Zahnarzt <br/>klinische Umweltmedizin &
		            <br/>Umweltzahnmedizin</Text>               
              </TextWrapper2>
              </Space>
              </>  
 
          </PortraitRow3>
          <PortraitRow4>
              <>
              
              </>
          </PortraitRow4>
        </PageWrapper>
      );
}

/*
<Div3>
              <GridImage src={PortraitKeferstein}/>
              </Div3>            
<TextWrapper2>
              <Fattext>Dr. med. Gerrit Keferstein</Fattext><Text>Arzt für  Funktionelle Medizin <br/> Sportwissenschaftler<br/>Gründer des Mojo Instituts</Text>
              </TextWrapper2>
*/