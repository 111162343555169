import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;

@media (max-width: 768px) {
    width:100%;
    line-height: 170%;
    margin-bottom: 40px;
  }
`;

const TextHeader = styled.h2`
margin-top: 40px;
text-align: center;
margin: 70px 0px 30px 0px;

@media (max-width: 768px) {
    width:90%;
    margin: 0px 0px 30px 0px;

  }
`;

const TextHeader2 = styled.h3`
margin-top: 40px;
text-align: center;
margin: 70px 0px 30px 0px;
font-size: 1.5em;

@media (max-width: 768px) {
    width:90%;
    margin: 0px 0px 30px 0px;
    font-size: 1.1em;
  }
`;

const Text = styled.p`

`;

const Text2 = styled.p`
 width: 86%;
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;

@media (max-width: 768px) {
  min-width: 30px;
  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 16px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 50%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  font-size: 16px;
  }
`;

export default function EB8() {

    return (
        <>
        <PageWrapper2>
          
          <TextHeader> 
              Für wen ist die ReLife Ernährungsberatung?
          </TextHeader>  
          <Text2>
                Unsere Plätze in der Ernährungsberatung sind streng limitiert. Daher ist die ReLife Ernährungsberatung ausschließlich für diejenigen gedacht, die wirklich etwas in Ihrem Leben verändern wollen und bereit sind dafür ihre volle Energie zu investieren.
            </Text2>          
          <TextBox>
            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>
                        Du bist bereit die volle Verantwortung für deine Gesundheit zu übernehmen.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Du bist bereit jegliches Wissen, dass du bisher über Ernährung hattest zu verwerfen. 
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Du bist motiviert dir neues Wissen anzueignen und eine ganz neue Perspektive auf Ernährung zu erlangen.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Du bist schnell in der praktischen Umsetzung und bereit neue Gewohnheiten in deinen Alltag zu integrieren.
                    </Text>
                </Schritt1>
            </Schritte>
          </TextBox> 
          <TextHeader2> 
            Wenn du bereit bist Veränderung in dein Leben zu bringen, geben wir 120%, <br/>
            um deine Ziele zu erreichen! – Versprochen!
          </TextHeader2> 
          <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenfreies Gespräch</Button2>
        </PageWrapper2>
        
        </>
      );
}
