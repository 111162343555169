import React from "react";
import {useInView} from "react-intersection-observer"
import styled from "styled-components";
import PortraitMo from "../images/PfeilKreis.png";
import PortraitJoel from "../images/PfeilKreis.png";
import PortraitSohrab from "../images/PfeilKreis.png";
import farben from "./GlobalStyles";

// Kann gelöscht werden

const PageWrapper = styled.div`
color: #255C6A;
text-align:center;
margin:10px 30px;
margin-top: 30px;
@media (max-width: 768px) {
    margin: 0px;
  }
`;

const PortraitRow = styled.div`
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-row-gap:10px;
  }
`;

const PortraitRow2 = styled.div`
    display: grid;
    grid-template-columns: 20% 30% 20% 30%;

    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-row-gap:10px;
  }
`;

const GridImage = styled.img`
height:30vh;
width: 100%;

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}

@media (max-width: 768px) {
        max-width: 90%;
  }
`;


const Text = styled.p`
`;

const Fattext = styled.p`
font-weight: bold;
font-size: 20px;
`;

const Div1 = styled.div`

@keyframes portraitMoIn{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Div2 = styled.div`
    color: #79553C;
    transform: translateY(30%);
    opacity: 0;
`;

const Div3 = styled.div`

@keyframes portraitJoelIn{
    from{
        transform: translateX(30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Div4 = styled.div`
    color: #79553C;
    transform: translateY(30%);
    opacity: 0;
`;

const Subline = styled.h2`
display: flex;
justify-content: flex-start;
padding-left:100px;
padding-top: 10px;
padding-bottom: 10px;
color: white;
background: linear-gradient(to right, ${farben.greenButtons}, white);
margin: 75px 0px;

@media (max-width: 768px) {
        
  }
`;

const TextWrapper = styled.div`

@keyframes portraitTextIn{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const TextWrapper2 = styled.div`
display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 10px;

@keyframes portraitTextIn{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
        align-items: center;
  }
`;

const Headline = styled.div`
align-self: center;
font-size: 22pt;
color:#96C92F;
`;
const Zitatstrich = styled.hr`
  display: block;
  width: 40%;
  color: #96c92f;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #96c92f, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;
`;

export default function AboutUsOverview2() {

  const {ref: portrait, inView: portraitVisible} = useInView({ threshold:0.1, triggerOnce:true});
  const {ref: portrait2, inView: portraitVisible2} = useInView({ threshold:0.5, triggerOnce:true});

    return (
        <PageWrapper>
            <Headline> Deine Coaches </Headline>
            <br></br>
            <Zitatstrich/>
            <br/>
          <PortraitRow>
              

              {portraitVisible ?
              <>
              <Div1 ref={portrait}>
              <GridImage  src={PortraitMo}/>
              </Div1>
              <TextWrapper>
              <Fattext>Mo</Fattext><Text>Beachvolleyballprofi<br/>Fachberater für Ernährungsmedizin<br/>Entgiftungs-/Darm-Immuntherapeut</Text> 
              </TextWrapper>
              </>
              :
              <>
              <Div2 ref={portrait}>
              <GridImage  src={PortraitMo}/>
              <TextWrapper>
              <Fattext>Mo</Fattext><Text>Beachvolleyballprofi<br/>Fachberater für Ernährungsmedizin<br/>Entgiftungs-/Darm-Immuntherapeut</Text> 
              </TextWrapper>
              </Div2>
              </>
              } 
              {portraitVisible ?
              <>
              <Div3 ref={portrait}>
              <GridImage src={PortraitJoel}/>
              </Div3>
              <TextWrapper>
              <Fattext>Joel</Fattext><Text>Sportwissenschaftler (B.Sc.) <br/>Fachberater für Ernährungsmedizin <br/>Athletik-/Mobility Trainer</Text>
              </TextWrapper>
              </>  
            :
            <>
            <Div4 ref={portrait}>
            <GridImage src={PortraitJoel}/>
            
            <TextWrapper>
            <Fattext>Joel</Fattext><Text>Sportwissenschaftler (B.Sc.) <br/>Fachberater für Ernährungsmedizin <br/>Athletik-/Mobility Trainer</Text>
            </TextWrapper>
            </Div4>
            </>  
              }
          </PortraitRow>
          <Subline>
              Unsere Partner
          </Subline>
          <PortraitRow2>
              {portraitVisible2 ?
              <>
              <TextWrapper2>
              <Fattext>Dr. med. Eckhard Gawlik</Fattext><Text>Facharzt für Ernährungsmedizin <br/>Heilpraktiker</Text> 
              </TextWrapper2>
              <Div1 ref={portrait2}>
              <GridImage  src={PortraitSohrab}/>
              </Div1>
              </>
              :
              <>
              <Div2 ref={portrait2}>
              <TextWrapper2>
              <Fattext>Dr. med. Eckhard Gawlik</Fattext><Text>Facharzt für Ernährungsmedizin <br/>Heilpraktiker</Text> 
              </TextWrapper2>
              <GridImage  src={PortraitMo}/>
              </Div2>
              </>
              } 
              {portraitVisible2 ?
              <>
              <TextWrapper2>
              <Fattext>Sohrab</Fattext><Text>Physiotherapeut <br/> Osteopath<br/>Yogalehrer</Text>
              </TextWrapper2>
              <Div3 ref={portrait2}>
              <GridImage src={PortraitSohrab}/>
              </Div3>
              </>  
            :
            <>
            <Div4 ref={portrait2}>
            <TextWrapper2>
            <Fattext>Sohrab</Fattext><Text>Physiotherapeut <br/>Osteopath<br/>Yogalehrer</Text>
            </TextWrapper2>
            <GridImage src={PortraitSohrab}/>
            </Div4>
            </>  
              }
          </PortraitRow2>
          
        </PageWrapper>
      );
}
