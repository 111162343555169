import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C8 from "../../images/C8.jpg"
import { Link } from "react-router-dom";

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;
`;

const TextBox2 = styled.div`
width: 90%;
display: flex;
justify-content: center;
flex-direction: column;
line-height: 200%;
`;

const TextHeader = styled.h2`
margin-top: 40px;
text-align: center;

@media (max-width: 768px) {
    width: 90%;
  }
`;

const TextHeader2 = styled.h2`
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    width: 90%;
`;

const Text = styled.p`
    line-height: 100%;
`;

const Text2 = styled.p`
 width: 90%;
`;

const Text3 = styled.p`
 line-height: 200%;
 margin-bottom: 40px;
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;

const Image = styled.img`
    width: 60%;
    box-shadow: ${farben.boxshadow};

    @media (max-width: 768px) {
      width: 90%;
  }
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 16px;
  padding: 16px ;
  line-height: 150%;
  border: none;
  margin: 10px 0px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 70%;
  padding: 10px;
  }
`;

export default function EB8() {

    return (
        <>
        <PageWrapper2>
          
          <TextHeader> 
              Erhalte endlich die Unterstützung, die dein Körper verdient hat.
          </TextHeader>  
          <Text2>
            Bei der Vielzahl an gegensprüchlicher Informationen und leerer Versprechen die im Internet und den sozialen Medien kursieren,<b> ist es völlig normal, dass du den Durchblick verlierst.</b><br/>
            Erfolglose Programme mit keinen oder nur kurzweiligen Erfolgserlebnissen ohne nachhaltigen Effekt mindern deinen Glauben an wirkliche Veränderung 
          </Text2>    
          <TextHeader2>
                Du kennst es...
            </TextHeader2>      
          <TextBox>

            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>
                        Du hast bereits verschiedenste Gesundheitstrends & Diäten für mehr Energie im Alltag, besseren Schlaf, Abnehmen oder Darmgesundheit, etc. ausprobiert, aber kaum bis keine nachhaltigen Erfolge erzielt?
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Du fühlst dich orientierungslos in dem Meer von widersprüchlichen Gesundheitsempfehlungen die es da draußen gibt?
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Du hast Schwierigkeiten dich an einen festen Plan zu halten, wirkliche Veränderungen in deinem Lebensstil zu schaffen oder dich selbst zur Verantwortung zu ziehen?
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Dir fehlt einfach das Grundverständnis, das dir die Sicherheit gibt um den Sinn neuer Gesundheitstrends und Informationen für dich richtig einzuschätzen zu können. 
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        Du fragst dich was mit deinem Körper nicht stimmt und bist dir unsicher ob du Alles richtig machst, um entscheidenden Fehler im Alltag zu vermeiden.
                    </Text>
                </Schritt1>
            </Schritte>
          </TextBox> 
          <TextBox2>
            <Text3>
                Falls einer oder mehrere dieser Punkte auf dich zutreffen, hast du bisher einfach nicht die tiefgreifende Unterstützung erfahren, die du benötigst um wirklich nachhaltige Veränderung in dein Leben zu bringen.
                <br/>
                Dir wurde nicht das Wissen an die Hand gegeben,  um die Ursachen deiner Beschwerden zu verstehen oder es fehlt dir an den entscheidenden Praxis-Tools um neue Gewohnheiten in deinem Alltag zu schaffen.
            </Text3>
          </TextBox2>  
            <Image src={C8} />
          <TextBox2>
            <Text3>
                <b>Wir sind hier um dir eine Lösung zu bieten und dir zu zeigen, dass es auch anders geht!</b>
                <br/><br/>
                Es ist an der Zeit deine Gesundheit ganzheitlich zu betrachten und die Schritte zu gehen, die notwendig sind um wieder deine volle Leistungsfähigkeit zum vorscheinen zu bringen.
                <br/><br/>
                Als zertifizierte Fachberater für Ernährungsmedizin, Sportwissenschaftler, Atemtrainer und Immunsignaturcoaches nutzen wir in unserem ReLife Coaching die Synnergie der 5 Roots, um die ganze Bandbreite negativer Einflussfaktoren zu adressieren, die dich davon abhalten dein volles Potentail zu entfalten.
                <br/><br/>
                Dabei bieten wir kein ”Programm für Jeden” an , sondern integrieren die 5 Roots individuell in deinen Alltag, sodass sie ihr riesiges Potential für deine Gesundheitsoptimierung entfalten.
                <br/><br/>
            </Text3>
           </TextBox2>   
          <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenfreies Erstgespräch</Button2>
        </PageWrapper2>
        
        </>
      );
}
