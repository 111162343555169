import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Logo from "../images/ReLifeLogo.png";
import farben from "./GlobalStyles.js"


const NavElement = styled.div`
display:flex;
align-items:center;
justify-content:center;
width:192px;
padding:22px;
`;

const NavElement2 = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items:center;
justify-content:center;
width:192px;
padding:22px;
color: black;
`;

const NavElement3 = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items:center;
color: black;
`;

const HomeElement = styled.div`
display:flex;
align-items:center;
justify-content:center;
height: 100%;
padding-left: 15px;

`;

const activeClassName = "active";
const NavBox = styled(NavLink).attrs({
  activeClassName: activeClassName
})`
&.${activeClassName} {
  border-bottom: 4px solid ${farben.greenButtons};
  font-weight: bold;
  }

  :hover{
  border-bottom: 4px solid grey;
}

text-decoration:none;
color:black;
display:flex;
`;

  const NavbarDropdownContent = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    background-color: rgba(256,256,256, 1);
    align-items: flex-start;
    width: 125px;
    padding: 10px 10px 10px 10px;
    transition: 1s;

    :hover{
      padding-top:25px;
    }
  `;


const NavbarDropdown = styled.div`
position: relative;
display: inline-block;
&:hover ${NavbarDropdownContent} {
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}
`;

const Logoimage = styled.img`
    top: 10px;
    position: relative;
    height: 250px;
`;

const Anker = styled.a`
margin: 10px 10px;
color: black;
text-decoration: none ;
`;



function NavBar() {

  const [color1,setColor] = React.useState(false);

const NavBarContainer = styled.div`
background-color: rgba(256,256,256, 0.95);
border-bottom: 3px solid rgba(150,201,47, 1);
position:sticky;
font-size: 16px;
top: 0;
color:black;
display:flex;
z-index: 2;
height: 13vh;
padding-right: 100px;
transition: background-color 0.5s ease-out;

@media (max-width: 768px) {
    display:none;
    flex-direction:column;
    background-color: rgba(256,256,256, 1);
  }

  :hover{
      background-color: rgba(256,256,256, 1);
    }
`;

const tri = false;
  return (
    <>
      <NavBarContainer
      onMouseOver={()=>setColor(true)} 
      onMouseOut={()=>setColor(false)}>
      <NavLink to="/">
      <HomeElement>
          <Logoimage src={Logo}/>
      </HomeElement>
      </NavLink>
      <NavElement> 
        <NavBox to="/newhome">Home</NavBox>
      </NavElement>
      <NavElement> 
        <NavBox to="/coachingoverview/">Coaching</NavBox>
      </NavElement>
      <NavElement> 
        <NavBox to="/ourstory/">About Us</NavBox>
      </NavElement>    
      </NavBarContainer>
    </>
  );
}

export default NavBar;

/*
      <NavElement2> 
        <NavbarDropdown >
      <span>Roots ▼</span>
        <NavbarDropdownContent>
          <NavElement3>
            <NavBox to="/roots">
              <Anker>ROOTS</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="roots/nutrition">
              <Anker>Ernährung</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/mobility">
              <Anker>Bewegung</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/breathing">
              <Anker>Atmung</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/spirit">
              <Anker>Geist</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/circumstances">
              <Anker>Umwelt</Anker>
            </NavBox>
          </NavElement3>
        </NavbarDropdownContent>
    </NavbarDropdown>
      </NavElement2>
*/

/*
<NavElement> 
        <NavBox to="/blog/" activeClassName={tri}>Blog</NavBox>
      </NavElement>
      <NavElement> 
        <NavBox to="/kontakt/">Kontakt</NavBox>
      </NavElement>
*/

/* --- NAV ELEMENT MIT DROPDOWN ---
       <NavElement2> 
        <NavbarDropdown 
          onMouseOver={()=>setColor(true)} 
          onMouseOut={()=>setColor(false)}>
      <span>About Us ▼</span>
        <NavbarDropdownContent>
          <NavElement3>
            <NavBox to="/#Philo">
              <Anker href="#Philo">Philosophie</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/ourstory">
              <Anker>Unsere Story</Anker>
            </NavBox>
          </NavElement3>
        </NavbarDropdownContent>
    </NavbarDropdown>
      </NavElement2>
 */