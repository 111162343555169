import React from "react";
import styled from "styled-components";
import farben from "../components/GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import InstaPic from "../images/InstaPic.png";
import FBPic from "../images/FBPic.png";
import YTubePic from "../images/YTubePic.png";


const PageWrapper = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 50px 0px;
position: relative;
font-family: Sen;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 80%;
line-height: 200%;
padding: 0px 15px;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
    flex-direction:column;
  }
`;

const TextBox = styled.div`

@media (max-width: 768px) {
  margin-top: 80px;  }
`;

const Box = styled.div`
width: 100px;
`;

const Text = styled.p`
line-height: 200%;
`;

const Headline = styled.div`
font-size: 24px;
`;

const TextHeader = styled.h1`
text-align: center;
`;

const Flexbox = styled.div`
display:flex ;
justify-content: flex-start;
@media (max-width: 768px) {
  justify-content: space-evenly;
  }
`;

const Item = styled.img`
text-align: center;
height: 50px;
width: 50px;
`;

const Item2 = styled.img`
text-align: center;
height: 50px;
width: 100px;
`;


export default function GridContentBox() {

    return (
        <>
  <PageWrapper>

<ContentWrapper>
<NewWrapper>
<TextBox>
<Text>
    <TextHeader> 
    Impressum
    </TextHeader>
</Text>
<Headline><b>Du hast eine Frage an uns?</b></Headline> <br/>
<Headline>Dann nimm Kontakt mit uns auf, wir melden uns bei Dir!</Headline> <br/>
<Text>
  <b>Kontakt</b> <br/>
  E-Mail: Info@relife-coaching.com <br/>
  <a href="mailto:Info@relife-coaching.com">Email senden</a>
</Text>
<Flexbox>
  <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
  <Box>
  <Item src={InstaPic}></Item>
  </Box>
  </a>
  <a href="https://www.facebook.com/profile.php?id=100093010752685" target="_blank" rel="noreferrer">
  <Item2 src={FBPic}></Item2>
  </a>
  <a href="https://www.youtube.com/" target="_blank" rel="noreferrer">
  <Item2 src={YTubePic}></Item2>
  </a>
</Flexbox>
<br/>
<Text>
  <b>Impressum:</b> <br/>
  Angaben Gemäss §5 TMG <br/>
  Joel Bier<br/>
  Hansaring 26<br/>
  50670 Köln
</Text>

<Text>
<b>Medizinischer Haftungsausschluss:</b><br/>
Sämtlich Inhalte auf unserer Website dienen der allgemeinen Information zu unseren Dienstleistungen. Alle Informationen auf dieser Seite sind sorgfältig recherchiert, können jedoch in medizinischer Hinsicht keinerlei Anspruch auf Vollständigkeit, Aktualität, Richtigkeit und Ausgewogenheit erheben.
<br/>
Insbesondere sind sie in keiner Weise ein Ersatz für professionelle Diagnosen, Beratungen oder Behandlungen durch ausgebildete und anerkannte Ärzte/Ärztinnen. Genauso wenig dürfen die hier angebotenen Informationen als Grundlage für eigenständige Diagnosen sowie Behandlungen oder Änderungen an einer bereits empfohlenen Behandlung dienen. Dieses gilt insbesondere für die Informationen zu den einzelnen Krankheitsbildern und die Nutzung des Pflege- sowie Aufstehsessels.
<br/>
Konsultieren Sie bei gesundheitlichen Fragen oder Beschwerden immer Ihren behandelnden Arzt/behandelnde Ärztin
</Text>

</TextBox>           
</NewWrapper>
</ContentWrapper>  

</PageWrapper>
        </>
      );
}

