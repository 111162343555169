import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import C102 from "../../images/C102.png"

const PageWrapper = styled.div`
display: none;
flex-direction: column;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
  display:none;  
  width:100%;
  flex-direction: column;
  padding: 10px 0px;
  }
`;

const PageWrapperII = styled.div`
display: none;
flex-direction: column;
justify-content: center;
background-color: rgba(255,255,255,.4);
color: black;
align-items: center;
padding: 45px 20px;
@media (max-width: 768px) {
  display: flex;
  width:100%;
  flex-direction: column;
  padding: 10px 0px;
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Image = styled.img`
width: 150px;
height: 150px;
`;

const BigText = styled.div`
font-size: 16pt;
font-weight: bold;
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 40%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  width: 90%;
  height: 50%;
  margin-bottom: 40px;
//  flex-wrap: wrap;
//  min-width: 300px;
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 45%;

@media (max-width: 768px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;

const Durch = styled.span`
text-decoration: line-through solid red;
`;

const Text = styled.div`
text-align: center;
`;

export default function C9() {
    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <br/>
          <br/>
          <BigText> Was du von uns im ReLife-Coaching erwarten kannst. </BigText>
          <br/>
          <br/>
          <NewWrapper>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C102}></Image>
            <br/>
              <BigText>Verstehe deinen Körper</BigText>
              <br></br>
              <Text>Verstehe die wichtigsten Zusammenhänge zwischen deinem Lebensstil & deiner Gesundheit, damit du in Zukunft genau weisst, was dir gut tut um optimal zu performen.</Text>
              
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C102}></Image>
            <br/>
            <BigText>Erhalte klare Orientierung</BigText>
              <br></br>
              <Text>Erhalte von uns einen roten Faden, der dir Klarheit & Orientierung gibt, damit du dich voll und ganz auf deine Ziele konzentrieren kannst. </Text>
            </Wrapper>
          </Flex>  
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C102}></Image>
            <br/>
              <BigText>Topleistung im Business</BigText>
              <br></br>
              <Text>Verabschiede dich von alten Mustern und schaffe mit unserer Hilfe neue Gewohnheiten, die dir sichtlich gut tun, um wirkliche Veränderung in der Praxis zu erfahren. </Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C102}></Image>
            <br/>
            <BigText>Entfalte dein volles Potentail</BigText>
              <br></br>
              <Text>Lass dich von uns auf deiner Reise begleiten, damit du ohne Umwege deine Ziele erreichst und endlich dein volles Potential entfaltest.</Text>
            </Wrapper>
          </Flex>  
          <br/>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
        
      </> 
        
      );
}
