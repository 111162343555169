import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import {useInView} from "react-intersection-observer";
import Ausschnitt from "../images/1Home5Ausschnitt.png";


const PageWrapper = styled.div`
display: flex;
background-color: transparent;
color: black;
align-items: center;
justify-content: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    display: block;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
    align-items: center;
    width: 100%;
    
  }
`;

const NewWrapper = styled.div`
display: flex;
flex-direction: row-reverse;
align-items: center;
@media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    
  }
`;

const TextBox = styled.div`
width: 60%;
align-self: start;
@media (max-width: 768px) {
    width: 100%;
  }
`;

const TextHeader = styled.h1`
`;

const ImgBox = styled.div`

@media (max-width: 768px) {
  align-items: center;  
}
`;


const ImageWrapper = styled.div`

    @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
width: 60%;
margin-left: 5%;
`;

const Text = styled.p`
`;

const Floated = styled.img`
    float: left;
    height: 120px;
    padding: 10px;
    
`;

export default function EinleitungI() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true});

    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <NewWrapper>
        
          <TextBox>
          <TextHeader> „Wir haben es am eigenen Körper erfahren“</TextHeader>
            <Text>Anders als die meisten Gesundheits-Experten sprechen wir nicht aus der Theorie, sondern aus der Praxis. < br/>
                  Wir haben erfahren, dass selbst chronische Krankheiten durch die Optimierung des Lebensstils keine Rolle mehr spielen müssen.< br/>
                  Wir wissen, jeder kann sein volles Potential entfalten, wenn die richtigen Voraussetzungen geschaffen werden.</Text>
          </TextBox>
          <ImgBox>
          
          <ImageWrapper ref={image2}>
          <Image  alt="FU1" src={Ausschnitt}></Image> 
          </ImageWrapper> 
          
        </ImgBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>

</>
      );
}
