import ImageSlider from "../components/ImageSlider";
import styled from "styled-components";

const ImgBox = styled.img`
width: 90%;
height: 75 vh;
`;

function AboutUs() {
    return (  
        <>
        <h1>About Us</h1>
        <ImgBox src={ImageSlider}></ImgBox>
        <h1>About Us</h1>
        </>
        
    );
}

export default AboutUs;