import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C11 from "../../images/C11.png"

const PageWrapper2 = styled.div`
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding-bottom: 30px;
line-height: 200%;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;
const TextBox2 = styled.div`
width: 80%;
display: flex;
flex-direction: column;
align-items: center;
`;

const TextHeader3 = styled.h2`
text-align: center;
padding-left: 40px;
@media (max-width: 768px) {
    padding: 0px 20px;
  }
`;



const TextHeader4 = styled.h2`
text-align: center;
padding-left: 40px;
margin-top: 60px;
@media (max-width: 768px) {
    padding: 0px 20px;
  }
`;

const Text = styled.p`
padding-left: 40px;
@media (max-width: 768px) {
    padding: 0px 20px;
  }
`;

const ContentWrapper = styled.div`
display: flex ;
width: 100%;
justify-content:center;
font: ${farben.fontQuote};
font-size: 16px;
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
width: 80%;
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;
`;


const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;
    
const Floated = styled.img`
    float: right;
    height: 450px;
    padding: 30px;
    
`;

const Button2 = styled.button`
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 40%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const ShowDesktop = styled.div`
display: block;
@media (max-width: 768px) {
  display: none;
  }
`;

const ShowMobile = styled.div`
display: none;
@media (max-width: 768px) {
  display: flex;
  flex-direction: column;
  }
`;


export default function C6() {

    return (
        <>
        <PageWrapper2>
            <ShowDesktop>
            <TextHeader4>
                Krankheit als Normalzustand - <br />
                Warum kaum jemand noch wirklich gesund ist
            </TextHeader4>
                <Floated src={C11}/>
                <Text>
                <b>Viele Menschen erkennen nicht die frühzeitigen Warnsignale Ihres Körpers</b><br/>
                Diese werden in unserer heutigen Gesellschaft leider zunehmend normalisiert, was einen stetigen Zuwachs unterschiedlicher Krankheitsbilder in unserer Gesellschaft zur Folge hat.
                </Text>
                <Text>
                    <b>Heute ist es Realität,</b> dass fast jeder 10. Mensch in Deutschland an Depression oder Diabetes leidet, jeder 5. ist übergewichtig und die Zahl der Menschen mit Autoimmunerkrankungen (wie Rheuma, Arthritis oder Morbus Crohn) steigt jedes Jahr um 100.000.
                </Text>
                <TextHeader3>
                    „Das Fass läuft über“
                </TextHeader3>
                <Text>
                    <b>Der Grund</b> dafür ist die <b>Summe der vielen neuartigen Umwelt- und Lebensstilfaktoren</b> unserer modernen Lebensweise, welche unseren Körper zunehmend überlasten, seine Leistungsfähigkeit einschränken und ihm so nachhaltig schaden.
                    <br />
                    Auf biochemischer Ebene resultieren alle negativen Einflussfaktoren in den selben gestörten Prozessen unseres Stoffwechsels:  Oxidativer Stress, Entzündungsprozesse sowie ein überreiztes Immunsystem.Heute wissen wir, dass das gestörte Gleichgewicht dieser Prozesse die <b>Grundlage für unsere modernen chronischen Krankheiten</b> (Autoimmun, Arthritis Diabetes, Alzheimer & Demenz, Arteriosklerose,..) bilden.
                </Text>
                </ShowDesktop>
                <ShowMobile>
            <TextHeader3>
                Krankheit als Normalzustand - <br />
                Warum kaum jemand noch wirklich gesund ist
            </TextHeader3>
                
                <Text>
                <b>Viele Menschen erkennen nicht die frühzeitigen Warnsignale Ihres Körpers</b><br/>
                Diese werden in unserer heutigen Gesellschaft leider zunehmend normalisiert, was einen stetigen Zuwachs unterschiedlicher Krankheitsbilder in unserer Gesellschaft zur Folge hat.
                </Text>
                <Text>
                    <b>Heute ist es Realität,</b> dass fast jeder 10. Mensch in Deutschland an Depression oder Diabetes leidet, jeder 5. ist übergewichtig und die Zahl der Menschen mit Autoimmunerkrankungen (wie Rheuma, Arthritis oder Morbus Crohn) steigt jedes Jahr um 100.000.
                </Text>
                
                <TextHeader3>
                    „Das Fass läuft über“
                </TextHeader3>

                <Floated src={C11}/>
                <Text>
                    <b>Der Grund</b> dafür ist die <b>Summe der vielen neuartigen Umwelt- und Lebensstilfaktoren</b> unserer modernen Lebensweise, welche unseren Körper zunehmend überlasten, seine Leistungsfähigkeit einschränken und ihm so nachhaltig schaden.
                    <br />
                    Auf biochemischer Ebene resultieren alle negativen Einflussfaktoren in den selben gestörten Prozessen unseres Stoffwechsels:  Oxidativer Stress, Entzündungsprozesse sowie ein überreiztes Immunsystem.Heute wissen wir, dass das gestörte Gleichgewicht dieser Prozesse die <b>Grundlage für unsere modernen chronischen Krankheiten</b> (Autoimmun, Arthritis Diabetes, Alzheimer & Demenz, Arteriosklerose,..) bilden.
                </Text>
                </ShowMobile>
                <TextHeader3>
                    Es ist nie zu spät!
                </TextHeader3>
                <Text>
                    Dein Körper hat sich über Millionen von Jahren an die <b>entscheidenden Elemente (ROOTS) </b> gewöhnt und perfekt angepasst. <br/>
                    Indem Du sie wieder in dein Leben Integrieren, stellst du das natürliche und ursprüngliche Gleichgewicht her und erhältst wieder <b>Zugang zur vollen Leistungsfähigkeit</b> deines Körpers.<br/><br/> 
                    <b>Nur so adressiererst du die Ursache von Beschwerden und bildest gleichzeitig das Fundament zur Entfaltung deines ganzen Potentials</b> 
                </Text>
        </PageWrapper2>
        </>
      );
}
