import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C3B from "../../images/3C3.png"



const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding-bottom: 30px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;
const TextBox2 = styled.div`
width: 90%;
display: flex;
align-items: center;
flex-direction: column;
`;

const TextHeader3 = styled.h2`
width: 85%;
text-align: start;

@media (max-width: 768px) {
  width: 100%;  }

`;


const Text = styled.p`
 width: 85%;

 @media (max-width: 768px) {
  width: 100%;  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;

@media (max-width: 768px) {
  width: 100%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    max-height: 50vh;
    box-shadow: ${farben.boxshadow};
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;
`;


const MiddleButton = styled.div`
display: flex;
justify-content: center;
`;

export default function EB2() {

    return (
        <>
        <PageWrapper2>
          <ContentWrapper>
          <TextBox2>
          <TextHeader3> 
            Es ist kein Wunder, dass du unter deinen Möglichkeiten bleibst! 
          </TextHeader3>
              <Text>
                Du weißt du bist zu so viel mehr in der Lage, dir fehlt aber die hundertprozentige Energie und das Durchhaltevermögen, um deinen eigenen Erwartungen und Zielen gerecht zu werden.<br /><br />
                Du möchtest mehr vom Leben aber dein Körper will nicht so wie du.<br /><br />
                Auf der Suche nach Besserung, hast du bereits unterschiedliche Praktiken für dich ausprobiert, sowie Zeit & Energie investiert, aber bisher keine Lösung gefunden, die <b>dir wirklich hilft.</b>
              </Text> 
          </TextBox2>
          <ImgBox>   
            <ImageWrapper >
            <Image  alt="FU1" src={C3B}></Image> 
            </ImageWrapper>  
          </ImgBox> 
          </ContentWrapper>  
        </PageWrapper2>
        </>
      );
}
