import React from "react";
import styled from "styled-components";

const HeadImage = styled.img`
  height: 100vh;
  object-fit: cover;
  width: 100vw;
  @media (max-width: 768px) {
    width: 100%;
  }
  display: block;
`;

function MainImage() {
  return (
      <HeadImage
        src={"https://images.unsplash.com/photo-1448375240586-882707db888b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8&w=1000&q=80"}
        alt="Pic1"
      />
  );
}

export default MainImage;
