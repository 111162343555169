import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Hamsterrad from "../images/1Home10Hamsterrad.png"

const PageWrapper = styled.section`
display: flex;
color: green;
height: 75vh;
align-items:center ;
@media (max-width: 768px) {
    width:100%;
    flex-direction: column;
    margin-bottom: 40px;
    height: none;
  }
`;

const TextBox = styled.div`
display: flex;
justify-content: center;
width: 50%;
color: ${farben.green};
font-weight: bold;
font-size: 30px;
@media (max-width: 768px) {
  width: 76%;
  margin:20px 0px 40px 0px;
  text-align: center;
  }

`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
@media (max-width: 768px) {
    width:95%
  }

  &:hover {
  transform: scale(1.03);
  transition: all 0.2s linear;
}
`;




export default function HomeHamsterradII() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true});

    return (
        <PageWrapper>
          <TextBox>
            Befindest du dich selber im Hamsterrad?
          </TextBox>
          <ImageWrapper ref={image2}>
              <Image  alt="FU1" src={Hamsterrad}></Image> 
          </ImageWrapper> 
        </PageWrapper>
      );
}
