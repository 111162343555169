import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js"
import C71 from "../../images/C7.1.jpg"
import C72 from "../../images/C7.2.jpg"
import C73 from "../../images/C7.3.jpg"
import C74 from "../../images/C7.4.jpg"
import C75 from "../../images/C7.5.jpg"
import C76 from "../../images/C7.6.jpg"
import C77 from "../../images/C7.7.jpg"

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
  display:flex;  
  width:100%;
  flex-direction: column;
  padding: 10px 0px;
  background-color: rgba(255, 255, 255, 0.95);
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Image = styled.img`
width: 300px;
height: 200px;

@media (max-width: 300px) {
    width: 250px;
  }
`;

const BigText = styled.div`
font-size: 20px;
font-weight: bold;

`;

const BigTextHeadline = styled.div`
font-size: 1.5em;
font-weight: bold;

@media (max-width: 768px) {
    width: 90%;
    text-align: center;
  }
`;


const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  width: 90%;
  height: 50%;
  margin-bottom: 40px;
//  flex-wrap: wrap;
//  min-width: 300px;
@media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: center;
  }
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 30%;

@media (max-width: 768px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;

const Text = styled.div`
text-align: center;

@media (max-width: 768px) {
    margin-bottom: 30px;
    
  }
`;

export default function C7() {
    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <Break/>
          <Break/>
          <BigTextHeadline> Wobei dir das ReLife Coaching helfen wird: </BigTextHeadline>
          <Break/>
          <Break/>
          <NewWrapper>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C71}></Image>
            <Break/>
              <BigText>Topleistung im Business</BigText>
              
              <Text>Ständig müde, kein klarer Kopf und Schwierigkeiten dich zu konzentrieren? Vermeide Brainfog und gewinne mit unserer Hilfe neuen Fokus und geistige Klarheit in deinem Arbeitsalltag.</Text>
              
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C72}></Image>
            <Break/>
            <BigText>Chronische Beschwerden</BigText>
              
              <Text>Profitiere von unserer Erfahrung und lerne wie du mithilfe der 5 Roots die Ursachen deiner Beschwerden adressierst, um Schritt für Schritt deine Lebensqualität zurückzugewinnen.</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C73}></Image>
            <Break/>
            <BigText>Starkes Imunsystem</BigText>
              
              <Text>Immer wieder krank? - Stärke dein Immunsystem, regeneriere schneller und werde widerstandsfähiger, damit dich Krankheiten nicht ständig ausbremsen und zurückwerfen.</Text>
            </Wrapper>
            </Flex>
            <Flex>
            <Wrapper>
            <Image alt="FU1" src={C74}></Image>
            <Break/>
              <BigText>Anti-Aging</BigText>
              
              <Text>Erfahre wie du dem Alterungsprozess entgegenwirkst und entscheidende Fehler vermeidest, um auch im hohem Alter körperlich & geistig fit zu bleiben.</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C75}></Image>
            <Break/>
            <BigText>Volle Leistungsfähigkeit</BigText>
              
              <Text>Lass dir von uns zeigen, wie du mit modernem Biohacking das volle Potential deines Körpers entfaltest & auf einem Level performst, dass du bisher nicht für möglich gehalten hast.</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C76}></Image>
            <Break/>
            <BigText>Wohlfühlen im eigenen Körper</BigText>
              <Text>Gewinne die Kontrolle über deinen Körper zurück und erfahre wie du dich ein für alle mal von Hautprobleme, Übergewicht, etc. verabschiedest, damit du endlich einen Körper hast, den du liebst.</Text>
            </Wrapper>
          </Flex>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C77}></Image>
            <Break/>
              <BigText>Stressmanagement</BigText>
              <Text>Ständig gestresst, immer weniger belastbar und Alles wird zu viel? Vermeide Burnout, indem du lernst, die moderne Stressfaktoren auszugleichen, sodass Körper und Geist wieder zur Ruhe kommen.</Text>
            </Wrapper>
            </Flex>
          <Break/>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
        
      </> 
        
      );
}
