import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C2 from "../../images/3C2.png"

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 0px 0px;
    text-align: center;
    background: linear-gradient(to bottom,transparent,5%, rgba(255, 255, 255, 0.95),30%, rgba(255, 255, 255, 1));
  }
`;

const TextBox = styled.div`
width: 90%;
`;

const TextHeader = styled.h3`
width: 90%;
`;

const Text2 = styled.p`
margin-top: 10vh;
@media (max-width: 768px) {
  margin-top: 25vh;
  }
`;

const Text = styled.p`
@media (max-width: 768px) {
  text-align: start;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 50%;
@media (max-width: 768px) {
  width: 90%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
width: 100%;
`;

const ContentWrapper2 = styled.div`
display: flex ;
width: 100%;
align-items:center;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const TextBox2 = styled.div`
width: 80%;
display: flex;
align-items: start;

@media (max-width: 768px) {
    width: 90%;
  }
`;

const Green2 = styled.span`
  color: ${farben.greenButtons};
  font-weight: bold;
  font-size: 2em;
  height:30px;
  width: 30px;
`;

const Text3 = styled.div`
 text-align: start;
 padding-left: 20px;
 margin-bottom: 20px;
`;


const Image2 = styled.img`
    height: 35px;
    width: 35px;
`;  

export default function C2Funktion() {

    return (
        <PageWrapper>
            <Text2>Für Biohacker & Leistungsoptimierer, die ihre Gesundheit selbst in die Hand nehmen wollen</Text2>
            <TextHeader>Erfahre wie die 5 entscheidenden Roots deine Gesundheit nachhaltig festigen, um endlich dein volles Potential zu entfalten </TextHeader>
          <ContentWrapper>  
          <ImgBox>   
            <ImageWrapper>
            <Image  alt="FU1" src={C2}></Image> 
            </ImageWrapper>  
          </ImgBox>
           
          <ContentWrapper2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Gewinne Klarheit darüber, wie du deine Leistungsfähigkeit steigerst und endlich beschwerdefrei wirst</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Lerne typische Fehler und negative Gesundheitsfaktoren zu vermeiden, damit dich nichts mehr hemmt, um dein volles Potential entfaltest</Text3>
            </TextBox2> 
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Bilde mit unseren 5 Roots das Fundament für deine Gesundheit, damit du auch im Alter fit und Leistungsfähig bleibst</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Nutze unsere Coaching-Materialien, um das neue Wissen Schritt für Schritt in deinen Alltag zu integrieren, damit du endlich spürbare Erfolge erzielst</Text3>
            </TextBox2>
            </ContentWrapper2>     
        </ContentWrapper> 

        </PageWrapper>
      );
}

   

