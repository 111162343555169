import { HashRouter as Router, Route} from "react-router-dom";
import './App.css';
import Leistungen from "./pages/Leistungen";
import Roots from "./pages/Roots";
import AboutUs from "./pages/AboutUs";
import Blog from "./pages/Blog";
import Kontakt from "./pages/Kontakt";
import DownloadEbook from "./pages/DownloadEbook";
import NavBar from "./components/NavBar";
import OurStory from "./pages/OurStory";
import OurStoryNew from "./pages/OurStoryNew";
import MoStory from "./components/MoStory";
import Geist from "./pages/Geist";
import Umwelt from "./pages/Umwelt";
import NewHome from "./pages/NewHome";
import CoachingOverview from "./pages/CoachingOverview";
import Ernährungsberatung from "./pages/Ernährungsberatung";
import Coaching from "./pages/Coaching";
import styled from "styled-components";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";

const GlobalStyles = styled.div`
color: #2e1e16;
font-family: 'Sen', sans-serif;
font-size: 16px;
margin: 0px;
`;

const Hider = styled.div`
display:none;
@media (max-width: 768px) {
    display: flex;
    height: 300px;
  }
`;

function App() {
  return (
    <>
    <GlobalStyles>
    <Router>
      <ScrollToTop />
      <NavBar />
      <Header /> 
      <Route exact path="/" component={NewHome} />
      <Route path="/leistungen/" component={Leistungen} />  
      <Route path="/aboutus/" component={AboutUs} /> 
      <Route path="/ourstory/" component={OurStory} />
      <Route path="/ourstory2/" component={OurStoryNew} />  
      <Route path="/newhome/" component={NewHome} />
      <Route path="/coachingoverview/" component={CoachingOverview} />
      <Route path="/ernaehrungsberatung/" component={Ernährungsberatung} />
      <Route path="/coaching/" component={Coaching} />
      <Route path="/#Philo/" component={MoStory} />
      <Route path="/kontakt/" component={Kontakt} />
      <Route path="/d0wn10ade800k/" component={DownloadEbook} />
      <Footer />
    </Router>
    </GlobalStyles>
    </>
  );
}

export default App;

/*
<Route path="/nutrition/" component={Nutrition} />
      <Route path="/breathing/" component={Atmung} />
      <Route path="/mobility/" component={Bewegung} />
      <Route path="/spirit/" component={Geist} />
      <Route path="/roots/" component={Roots} />
      <Route path="/blog/" component={Blog} />
      <Route path="/kontakt/" component={Kontakt} />
      <Route path="/spirit/" component={Geist} />
      <Route path="/circumstances/" component={Umwelt} />
*/
