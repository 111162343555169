import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: transparent;
color: black;
align-items: center;
justify-content: center;
padding: 10px 0px 0px 10px;
@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const TextBox = styled.div`
width: 90%;
`;

const TextHeader = styled.h3`
`;

const TextHeaderII = styled.div`
font-weight: bold;
`;

const GreenBold = styled.span`
color: ${farben.green};
font-weight: bold;
font-size: 20px;
`;

const Green = styled.span`
color: ${farben.green};
font-weight: bold;

`;

const Text = styled.div`

`;

const Text2 = styled.div`

`;

export default function EinleitungII() {

    return (
        <PageWrapper>
          <TextBox>
            <TextHeader> Unsere moderne Lebensweise macht dich krank</TextHeader>
            <Text>In unserer schnelllebigen, modernen Welt haben wir uns in kürzester Zeit von unseren Wurzeln entfernt und die Verbindung zu unserem eigenen Ursprung immer weiter verloren. Dieser Verlust bleibt nicht ohne Konsequenzen. So sind heute Stress, chronische Beschwerden & eine verminderte Leistungsfähigkeit für die meisten Menschen ganz normaler Alltag.</Text>
            <br/>
            <TextHeader> Vielleicht geht es dir genauso</TextHeader>
            <Text2> <GreenBold>&#8594;</GreenBold>  du bemühst dich bereits gesundheitsbewusst zu leben, dennoch hast du das Gefühl nicht das volle Potential auszuschöpfen  </Text2>
            <Text2> <GreenBold>&#8594;</GreenBold>  du fragst dich warum du als Kind so viel Energie hattest und wo diese heute geblieben ist</Text2>
            <Text2> <GreenBold>&#8594;</GreenBold>  dich schränken unterschiedliche Beschwerden, wie z.B. chronische Müdigkeit, Gelenk- und Rückenschmerzen, Verdauungsprobleme, etc, zunehmend ein</Text2>
            <br/>
            <Text>Ein schlichtweg unnatürlicher Lebensstil ist die Ursache des Großteils all dieser Beschwerden & Leistungseinbußen, die du in deinem Alltag erfährst. <br/> 
                  Damit du wieder das volle Potential von Körper und Geist für dich nutzen kannst/entfalten kannst, sind wir hier um dir zu zeigen wie du deinen Lebensstil optimierst. </Text>
            <br/>
            <TextHeader>Der einzige Schlüssel zu nachhaltiger Gesundheit & voller Leistungsfähigkeit</TextHeader>
            <TextHeaderII>Optimiere deinen Lebensstil mit unseren 5 Roots</TextHeaderII>
            <Text>Um unser volles Potential zu erreichen, müssen wir Gesundheit ganzheitlich betrachten und die Art und Weise ändern wie wir uns durch das moderne Leben bewegen.</Text>
            <Text>
              Das ursprüngliche Fundament der vollen Leistungsfähigkeit unseres Körpers bilden unsere <Green>5 Roots</Green><br/>
              Die 5 <Green>individuelle</Green> Anwendung dieser Elemente in deinem Alltag ist der Schlüssel zum Erfolg unseres ReLife-Coachings.
            </Text>
          </TextBox>
        </PageWrapper>
      );
}

