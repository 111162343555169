//GlobalStyles
const farben = {fontcolor:"#2e1e16", 
                greenButtons:"#96c930",
                green:"#96c930", 
                cremeBackground:"#FFFCF0",
                opacity:.9, 
                fontsize:"16px", 
                font1:"Sen", 
                fontQuote:"Sen",
                boxshadow:"0px 0px 10px 0px grey",
                fontfamily: "Jost",
                headline: "24px",
                subheadline: "20px",
                
            };
export default farben;