import React from "react";
import styled from "styled-components";
import farben from "../GlobalStyles.js";
import C2 from "../../images/3C2.png"
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
    
  }
`;

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
justify-content: center;
`;

const Green = styled.span`
  color: ${farben.greenButtons};
  font-weight: bold;
  font-size: 1.6em;
  height:30px;
  width: 30px;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 10px;
    font-size: 1.5em;
    min-width: 30px;
  }
`;

const TextHeader = styled.h2`
margin-top: 40px;
text-align: center;
`;

const TextHeader2 = styled.h2`
    margin-top: 40px;
    text-align: center;

    @media (max-width: 768px) {
    width: 90%;
  }
`;

const Text = styled.p`
    line-height: 100%;
`;

const Text2 = styled.p`
 width: 90%;
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt1 = styled.div`
    display: flex;
    align-items: center;
  `;


const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
    flex-direction: column;
  }

`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 50%;
justify-content: flex-end;
@media (max-width: 768px) {
    width: 90%;
    
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    width: 75%;
    @media (max-width: 768px) {
    width: 100%;
  }
`;

const Pfeil = styled.div`
font-size: 20px;
min-width: 50px;

@media (max-width: 768px) {
    min-width: 33px;
  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 16px;
  line-height: 150%;
  border: none;
  padding: 4px;
  margin: 30px 20px;
  width: 280px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const TextBox3 = styled.div`
width: 100%;
display: flex;
align-items: start;

@media (max-width: 768px) {
    width: 90%;
  }
`;

const Text31 = styled.div`
 text-align: start;

 @media (max-width: 768px) {
    margin-bottom: 5px;
  }
`;

const ContentWrapper2 = styled.div`
display: flex ;
line-height: 150%;
width: 50%;
align-items:start;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
    width: 90%;
  }
`;


export default function EB8() {

    return (
        <>
        <PageWrapper2>
          
          <TextHeader> 
              Stell dir vor wie dein Leben aussehen würde, wenn… 
          </TextHeader>    
          <TextBox>
            <Schritte>
                <Schritt1>
                    <Pfeil>➜</Pfeil>
                    <Text>
                        du genau verstehen würdest, warum dein Körper nicht voll leistungsfähig ist und du über das Wissen verfügst, dies endgültig zu ändern.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du morgens mit voller Energie und klarem Kopf aufstehst und es kaum erwarten kannst in den Tag zu starten.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du die Klarheit darüber hättest, was für deine Gesundheit Sinn macht und was nicht?
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du neue Gewohnheiten in deinen Alltag integriert hättest, die dir Zeit sparen, das Leben einfacher machen & dir sichtbar gut tun.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du eine umfassende persönliche Unterstützung hast, die dir alle deine Fragen beantwortet und dich bei jedem Schritt auf deinem Weg begleitet.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du deine chronischen Beschwerden hinter dir lassen könntest, sodass du endlich dein wahres Potential entfalten kannst. 
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du genau wissen würdest, welche Lebensmittel dir und deinem Körper gut tun und du einen persönlichen Strategie-Plan hast, der dir täglich Orientierung bietet.
                    </Text>
                </Schritt1>
                <Schritt1>
                <Pfeil>➜</Pfeil>
                    <Text>
                        du maximale Leistungsfähigkeit und endlose Energie hast um all deinen Wünschen und Zielen nachzugehen.
                    </Text>
                </Schritt1>
            </Schritte>
          </TextBox> 
          <TextHeader2> 
             All das ist möglich und wir zeigen dir wie!
          </TextHeader2> 
          <Text2>
          Lass dir von uns zeigen wie du dich mit unseren 5 Roots in den nächsten 12 Wochen von Beschwerden und Energielosigkeit verabschiedest, um endlich das Leben zu leben, das du dir schon so lange wünschst! 
          <br/>
          Beschwerdefrei, voller Energie und mit der Gewissheit jeden Tag dein volles Potential auszuschöpfen!
          </Text2> 
        </PageWrapper2>
        <PageWrapper>
          <ContentWrapper>  
          <ImgBox>   
            <ImageWrapper>
            <Image  alt="FU1" src={C2}></Image> 
            </ImageWrapper>  
          </ImgBox>
          <ContentWrapper2>
            <TextBox3>
                <Green>✓</Green>
                <Text31>12 Wochen Live Coaching</Text31>
            </TextBox3>
            <TextBox3>
                <Green>✓</Green>
                <Text31>Wissenschaftlich fundiertes Programm</Text31>
            </TextBox3>
            <TextBox3>
                <Green>✓</Green>
                <Text31>12 intensive 1:1 Video-Calls</Text31>
            </TextBox3>
            <TextBox3>
                <Green>✓</Green>
                <Text31>Praxisnahe Coaching Materialen</Text31>
            </TextBox3>
            <TextBox3>
                <Green>✓</Green>
                <Text31>24/7 persönliche Unterstützung via Whats App, Telegram, etc.</Text31>
            </TextBox3>
            <TextBox3>
                <Green>✓</Green>
                <Text31>Garantierte Neuausrichtung deiner Gesundheit</Text31>
            </TextBox3>
            
            </ContentWrapper2> 
            
          </ContentWrapper>  
          <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenfreies Erstgespräch</Button2>
        </PageWrapper>
        
        
        </>
      );
}



