import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import EB8 from "../images/2EB8.jpg"

const PageWrapper2 = styled.div`
display: flex;
flex-direction:column ;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;
width: 90%;  

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);

  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;

@media (max-width: 768px) {
    width:80%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }
`;

const Image = styled.img`
    width: 90%;
    box-shadow: ${farben.boxshadow};
`;

export default function EB5Function() {

    return (
        <>
        <PageWrapper2>
        <ImgBox>   
            <ImageWrapper >
            <Image  alt="FU1" src={EB8}></Image> 
            </ImageWrapper>  
          </ImgBox>
        </PageWrapper2>
        
        </>
      );
}
