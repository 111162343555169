import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import PfeilKreis from "../images/PfeilKreis.png"
import EB4 from "../images/2EB4.jpg"

const PageWrapper = styled.div`
display: flex;
flex-direction: column;

background-color: rgba(255, 255, 255, 0.90);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding: 30px 0px;

@media (max-width: 768px) {
    width:100%;
    padding: 30px 0px 0px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 150%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`;

const NewWrapper = styled.div`
display: flex;
@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  }

`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 90%;
@media (max-width: 768px) {
    width: 100%;
  }
`;

const TextHeader = styled.h2`
width:90%;
text-align: center;
@media (max-width: 768px) {
    text-align: start;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 30%;

@media (max-width: 768px) {
  margin: 30px;
  width: 90%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 450px;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  
  width: 390px;
  }
`;

const Image = styled.img`
    width: 90%;
    box-shadow: ${farben.boxshadow};
`;

const Text = styled.span`

`;

const TextWish = styled.span`
    align-self: start;
    margin-left: 100px;
    @media (max-width: 768px) {
      margin-left: 30px;
      margin-bottom: 20px;
  }
`;

const Button = styled.button`
  font-size: 20px;
  padding: 6px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 30%;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons};
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 100%;
  }
`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
`;

const Schritt1 = styled.div`
    padding-left: 5%;
`;

const SchrittImg = styled.img`
height: 30px;
width: 30px;
margin-right: 20px;

@media (max-width: 768px) {
  margin-right: 15px;
  }
`;

const Schritte2 = styled.div`
    display: flex;
    flex-direction: column;
    width:90%;
`;

const Schritt12 = styled.div`
    display: flex;
    align-items: center;
    margin: 10px;

    @media (max-width: 768px) {
      margin: 2px 2px 20px 2px;
  }
  `;

const TextBox2 = styled.div`
width: 90%;
display: flex;
justify-content: center;
line-height: 150%;
`;

export default function HomePhilosophie() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true});

    return (
        <PageWrapper>
          
          <TextHeader> 
              Erkennst du dich wieder?  <br/>Dann ist die ReLife Ernährungsberatung genau das Richtige für dich!
            </TextHeader>
          <ContentWrapper>
            
          <TextBox>
            <TextWish><b>Du wünscht dir...</b></TextWish>
            
          

          <TextBox2>
            <Schritte2>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Endlich Klarheit darüber zu haben, was wirklich <b>gesund ist und was nicht.</b>
                    </Text>
                </Schritt12>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Zu verstehen wann und warum bestimmte Ernährungsweisen für <b>dich Sinn machen</b> oder nicht.
                    </Text>
                </Schritt12>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Zu wissen, wie du deine Ernährung einfach im <b>Alltag umsetzt</b>
                    </Text>
                </Schritt12>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Deine chronischen <b>Beschwerden und Krankheiten zu lindern</b> oder sogar loszuwerden (mehr Infos)
                    </Text>
                </Schritt12>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Alles richtig zu machen, um <b>gesund älter zu werden</b>
                    </Text>
                </Schritt12>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Dich <b>gesund und fit</b> in deinem Körper zu fühlen
                    </Text>
                </Schritt12>
                <Schritt12>
                <SchrittImg src={PfeilKreis}></SchrittImg>
                    <Text>
                    Endlich zu verstehen, wie du dein <b>Wunschgewicht regulierst</b> 
                    </Text>
                </Schritt12>
            </Schritte2>
          </TextBox2> 
          </TextBox>
          <ImgBox>   
            <ImageWrapper ref={image2}>
            <Image  alt="FU1" src={EB4}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </ContentWrapper>  
        </PageWrapper>
      );
}

