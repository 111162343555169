import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import C71 from "../images/C7.1.jpg"
import C72 from "../images/C7.2.jpg"
import C73 from "../images/C7.3.jpg"
import C74 from "../images/C7.4.jpg"
import C75 from "../images/C7.5.jpg"
import C76 from "../images/C7.7.jpg"
import Headline from "./Headline.js";
import SubHeadline from "./Subheadline.js";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background-color: rgba(255,255,255,.4);
color: black;
align-items: center;
padding: 45px 20px;

@media (max-width: 768px) {
  display:flex;  
  width:100%;
  flex-direction: column;
  padding: 10px 0px;
  background-color: rgba(255, 255, 255, 0.95);
  margin-top: 50px;
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Image = styled.img`
width: 300px;
height: 200px;

@media (max-width: 768px) {
  width: 300px;
height: 200px;
    
}
`;

const BigText = styled.div`
font-size: 20px;
font-weight: bold;
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 40%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;

const Flex = styled.div`
  display: flex;
  justify-content: space-evenly;
  background-color: transparent;
  padding: 20px 20px;
//  flex-wrap: wrap;
//  min-width: 300px;

@media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    margin-bottom: 0px;
    align-items: center;
    padding: 0px 0px;
  }
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
align-items: center;
width: 30%;
text-align: center;

@media (max-width: 768px) {
    width: 90%;
    flex-wrap: wrap;
  }
`;

const Text = styled.div`
line-height: 1;

@media (max-width: 768px) {
    margin-bottom: 30px;
    
  }
`;

const Gap = styled.div`
height: 15px;
`;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;

export default function Hilfe() {
    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <Headline> Wobei wir dir helfen: </Headline>
          <Gap />
          <Zitatstrich/>
          <br/>
          <NewWrapper>
          <Flex>
            <Wrapper>
            <Image alt="FU1" src={C71}></Image>
            <Break/>
              <BigText>Topleistung im Business</BigText>
              
              <Text>Ständig müde, kein klarer Kopf und Schwierigkeiten dich zu konzentrieren? Vermeide Brainfog und gewinne mit unserer Hilfe neuen Fokus und geistige Klarheit in deinem Arbeitsalltag.</Text>
              
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C72}></Image>
            <Break/>
            <BigText>Chronische Beschwerden</BigText>
              
              <Text>Profitiere von unserer Erfahrung und lerne wie du mithilfe der 5 Roots die Ursachen deiner Beschwerden adressierst, um Schritt für Schritt deine Lebensqualität zurückzugewinnen.</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C73}></Image>
            <Break/>
            <BigText>Starkes Imunsystem</BigText>
              
              <Text>Immer wieder krank? - Stärke dein Immunsystem, regeneriere schneller und werde widerstandsfähiger, damit dich Krankheiten nicht ständig ausbremsen und zurückwerfen.</Text>
            </Wrapper>
            </Flex>
            <Flex>
            <Wrapper>
            <Image alt="FU1" src={C74}></Image>
            <Break/>
              <BigText>Anti-Aging</BigText>
              
              <Text>Erfahre wie du dem Alterungsprozess entgegenwirkst und entscheidende Fehler vermeidest, um auch im hohem Alter körperlich & geistig fit zu bleiben.</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C75}></Image>
            <Break/>
            <BigText>Volle Leistungsfähigkeit</BigText>
              
              <Text>Lass dir von uns zeigen, wie du mit modernem Biohacking das volle Potential deines Körpers entfaltest & auf einem Level performst, dass du bisher nicht für möglich gehalten hast.</Text>
            </Wrapper>
            <Wrapper>
            <Image alt="FU1" src={C76}></Image>
            <Break/>
              <BigText>Stressmanagement</BigText>
              <Text>Ständig gestresst, immer weniger belastbar und Alles wird zu viel? Vermeide Burnout, indem du lernst, die moderne Stressfaktoren auszugleichen, sodass Körper und Geist wieder zur Ruhe kommen.</Text>
            </Wrapper>
          </Flex>

          <Break/>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
        
      </> 
        
      );
}