import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import Home3 from "../images/PfeilKreis.png";

// Kann gelöscht werden?

const PageWrapper = styled.div`
display: flex;
background-color: transparent;
color: black;
align-items: center;
@media (max-width: 768px) {
    width:100%;
  }
`;

const TextHeader = styled.h1`
`;

const Image = styled.img`
width: 100%;
`;

export default function EinleitungI() {

    return (
        <PageWrapper>
            <Image  alt="FU1" src={Home3}></Image> 
        </PageWrapper>
      );
}
