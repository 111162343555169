import React from "react";
import ContentBox from "../components/ContenBox";
import ContentBoxReverse from "../components/ContentBoxReverse";
import styled from "styled-components";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
`;


export default function Roots() {

    return (
      <PageWrapper>
        <ContentBox />
        <ContentBoxReverse />
      </PageWrapper>
      );
}
