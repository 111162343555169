import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Logo from "../images/ReLifeLogo.png";
import farben from "./GlobalStyles.js"


const NavElement = styled.div`
display:flex;
align-items:flex-start;
justify-content:flex-start;
width:192px;
padding:22px 0px 22px 122px; 
`;

const NavElement2 = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items:flex-start;
justify-content:flex-start;
width:192px;
padding:22px 0px 22px 122px;
color: black;
`;

const NavElement3 = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items:flex-start;
color: black;
`;

const HomeElement = styled.div`
display:flex;
justify-content:center;
height: 100%;
flex-grow: 1;

@media (max-width: 768px) {
  }

`;

const activeClassName = "active";
const NavBox = styled(NavLink).attrs({
  activeClassName: activeClassName
})`
&.${activeClassName} {
  border-bottom: 4px solid ${farben.greenButtons};
  font-weight: bold;
  
  }

  :hover{
  border-bottom: 4px solid grey;
}

text-decoration:none;
color:black;
display:flex;
`;

  const NavbarDropdownContent = styled.div`
    display: none;
    position: absolute;
    z-index: 1;
    background-color: rgba(256,256,256, 1);
    align-items: flex-start;
    width: 125px;
    padding: 10px 10px 10px 10px;
    transition: 1s;

    :hover{
      padding-top:25px;
    }
  `;


const NavbarDropdown = styled.div`
position: relative;
display: inline-block;
&:hover ${NavbarDropdownContent} {
  display: flex;
  flex-direction: column;
  transition: 0.5s;
}
`;

const Logoimage = styled.img`
    position: relative;
    width: 50px;
    @media (max-width: 768px) {

  }
`;

const Anker = styled.a`
margin: 10px 10px;
color: black;
text-decoration: none ;

`;



function NavBarMobile() {

  const [color1,setColor] = React.useState(false);
  const [checked,setChecked]= React.useState(true);
  console.log(color1)

  console.log("Wert ist "+ checked)

const NavBarContainer = styled.div`
background-color: rgba(256,256,256, 0.95);
border-bottom: 0px solid rgba(150,201,47, 1);
position:sticky;
font-size: 16px;
top: 0;
color:black;
display:flex;
z-index: 2;
height: 0vh;
transition: background-color 0.5s ease-out;

@media (max-width: 768px) {
    display:flex;
    flex-direction:column;
    width: 100%;
  }

`;

const ToggleButton = styled.button`
height: 1px;
width: 23px;
z-index:100;
content:"";
border: none;
font-size: 22px;
align-self: center;
margin-top: 38px;
margin-left: 15px;
position: absolute;
display: flex;
flex-direction: column;
justify-content: space-between;
background: transparent;
`;

const ButtonBar = styled.div`
  display: ${ checked ? "flex" : "none"};
  height: 4px;
  width: 25px;
  border-radius: 100px;
  background: ${farben.green};
  z-index: 5000000000000000;
  position: absolute;
`;

const ButtonBar2 = styled.div`
  display: ${ checked ? "flex" : "none"};
  height: 4px;
  margin-top:7px;
  width: 25px;
  border-radius: 100px;
  background: ${farben.green};
  z-index: 5000000000000000;
  position: absolute;
`;

const ButtonBar3 = styled.div`
  display: ${ checked ? "flex" : "none"};
  height: 4px;
  margin-top:14px;
  width: 25px;
  border-radius: 100px;
  background: ${farben.green};
  z-index: 5000000000000000;
  position: absolute;
`;

const Space = styled.div`
width: 15%;
`;

const Box = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
  width: 75%;
  font-size: 22px;
  color: ${farben.green};
  font-weight: bold;
  z-index: 500000000;
`;

const FirstRow = styled.div`
display: none;

@media (max-width: 768px) {
    background-color: rgba(256,256,256, 1);
    width: 100%;
    display: flex;
    position: sticky;
  }
`;

const Second = styled.div`
  display: ${ checked ? "none" : "flex"};
  flex-direction: column;
  background-color: rgba(256,256,256, 0.95);

`;

const Close = styled.span`
  width: 100%;
  border-radius: 100px;
  color: ${farben.green};
  z-index: 5000000000000000;
  display: ${ checked ? "none" : "flex"};
  font-weight: bold;
  position: absolute;

  @media (max-width:768px){

  }
`;

  return (
    <>
      <NavBarContainer
      onMouseOver={()=>setColor(true)} 
      onMouseOut={()=>setColor(false)}>
      <FirstRow>
      <Box>
      <Space/>  
      <NavLink to="/newhome" onClick={() => setChecked(true)}>
      <HomeElement>
          <Logoimage src={Logo}/>
      </HomeElement>
      </NavLink>
      </Box>
      <div>
      <ToggleButton onClick={() => setChecked(!checked)}>
        <ButtonBar /> <br/>
        <ButtonBar2 />
        <ButtonBar3 />
        <Close>X</Close>
      </ToggleButton> 
        </div>
      </FirstRow>
      <Second>
      <NavElement> 
        <NavBox to="/newhome" onClick={() => setChecked(!checked)}>Home</NavBox>
      </NavElement>
      <NavElement> 
        <NavBox to="/coachingoverview/" onClick={() => setChecked(!checked)}>Coaching</NavBox>
      </NavElement>
      <NavElement> 
        <NavBox to="/ourstory/" onClick={() => setChecked(!checked)}>About Us</NavBox>
      </NavElement>
      </Second>
      </NavBarContainer>
    </>
  );
}

export default NavBarMobile;

/* Roots
      <NavElement2> 
        <NavbarDropdown >
      <span>Roots ▼</span>
        <NavbarDropdownContent>
          <NavElement3>
            <NavBox to="/roots" onClick={() => setChecked(!checked)}>
              <Anker>ROOTS</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="roots/nutrition" onClick={() => setChecked(!checked)}>
              <Anker>Ernährung</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/mobility" onClick={() => setChecked(!checked)}>
              <Anker>Bewegung</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/breathing" onClick={() => setChecked(!checked)}>
              <Anker>Atmung</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/spirit" onClick={() => setChecked(!checked)}>
              <Anker>Geist</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/circumstances" onClick={() => setChecked(!checked)}>
              <Anker>Umwelt</Anker>
            </NavBox>
          </NavElement3>
        </NavbarDropdownContent>
    </NavbarDropdown>
      </NavElement2>
*/

/* Blog
 <NavElement> 
        <NavBox to="/blog/" activeClassName={tri} onClick={() => setChecked(!checked)}>Blog</NavBox>
      </NavElement>
     
*/

/* Kontakt
 <NavElement> 
        <NavBox to="/kontakt/" onClick={() => setChecked(!checked)}>Kontakt</NavBox>
      </NavElement>
*/


/* --- Nav Element mit Dropdown ---
 <NavElement2> 
        <NavbarDropdown 
          onMouseOver={()=>setColor(true)} 
          onMouseOut={()=>setColor(false)}>
      <span>About Us ▼</span>
        <NavbarDropdownContent>
          <NavElement3>
            <NavBox to="/philosophy" onClick={() => setChecked(!checked)}>
              <Anker>Philosophie</Anker>
            </NavBox>
          </NavElement3>
          <NavElement3>
            <NavBox to="/ourstory" onClick={() => setChecked(!checked)}>
              <Anker>Unsere Story</Anker>
            </NavBox>
          </NavElement3>
        </NavbarDropdownContent>
    </NavbarDropdown>
      </NavElement2>
*/