import React from "react";
import styled from "styled-components";
import HomeRoots from "../images/PfeilKreis.png";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import {NavLink} from "react-router-dom";



const PageWrapper = styled.div`
display: flex;
background-color: ${farben.cremeBackground};
color: black;
align-items: center;
padding: 30px 0px 25px 30px;
@media (max-width: 768px) {
    width:100%;
    flex-direction: column-reverse;
    padding: 0px 0px 20px 0px;
  }
`;

const InputWrapper = styled.div`
position: relative;
display: flex ;
flex-direction:column ;
justify-content: center;
align-items: flex-start;
width: 70%;
line-height: 200%;
`;


const ImageWrapper = styled.div`
    display:flex;
    width: 50%;
    justify-content: center;
    color: #79553C;

@keyframes rootsin{
    from{
        transform: translateY(30%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        display: flex;
    }
}
`;

const Image = styled.img`
width: 120%;
height: 75vh;

@media (max-width: 768px) {
    width:100%;
    height: 100%;
    margin-top: 25px;
  }
`;

const Text = styled.p`

`;

const Button = styled.div`
  font-size: 20px;
  border: none;
  border-radius: 10px;
  color: ${farben.cremeBackground};
  border: solid 2px ${farben.greenButtons};
  padding: 10px;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-self: center;

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons};
  cursor: pointer;
}
`;

const BigText = styled.span`
font-size: 28pt;
`;

const ImageWrapper2 = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    color: #79553C;
    transform: translateY(30%);
    opacity: 0;
`;

const NavBox = styled(NavLink)`
  align-self: center;
  text-decoration: none;
`;



export default function RootsOverview() {
  const {ref: image, inView: ImgIsVisible} = useInView({ threshold:0.1, triggerOnce:true, rootMargin: "0px -200px -100px -20px"});

    return (
      <>
        <PageWrapper>
          <InputWrapper>
          <Text><BigText>Back To Roots</BigText> bedeutet zurück zu den ursprünglichen und elementaren Wurzeln des Menschen. <br/>An diese hat sich unser Körper über Millionen von Jahren angepasst, wobei sie die Grundlagen für unsere Gesundheit und Leistungsfähigkeit formten.   </Text>
          <Text>In den letzten Jahrzehnten haben wir uns immer weiter von diesen Wurzeln entfernt, wodurch wir unsere physische und psychische Gesundheit in großem Maße beeinflussen. </Text>
          <Text><BigText>Der Schlüssel</BigText> zur Gesundheit und Leistungsfähigkeit liegt darin, die entscheidenden <b>Elemente (ROOTS)</b> wieder in unseren neuzeitlichen Lebensstil zu integrieren. </Text>
          <Text>Auf diesem Weg wollen wir euch begleiten!</Text>
          <NavBox to="/leistungen/">
            <Button>Zum Coaching</Button>
          </NavBox>
          </InputWrapper>
          {ImgIsVisible ? 
            <ImageWrapper ref={image}>
            <Image  alt="FU1" src={HomeRoots}></Image> 
            </ImageWrapper> :
            <ImageWrapper2 ref={image}>
            <Image alt="FU1" src={HomeRoots}></Image>
            </ImageWrapper2>
            }
        </PageWrapper>
        </>
      );
}

/*import React from "react";
import styled from "styled-components";
import {useInView} from "react-intersection-observer"

const PageWrapper = styled.div`
display: flex;
background-color: #FEFCF0;
color: black;
margin:auto;
align-items: center;
padding: 30px 30px;
@media (max-width: 768px) {
    width:90%;
  }
`;

const ImageWrapper = styled.div`
    display:flex;
    width: 50%;
    justify-content: center;
    color: #79553C;
    transition: transform 4s ease-in translateY(0);
    animation-name: rootsin;
    animation-duration: 5s;

@keyframes rootsin{
    from{
        transform: translateY(30%);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        display: flex;
    }
}
`;

const ImageWrapper2 = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    color: #79553C;
    transform: translateY(30%);
    opacity: 0;
`;

const Image = styled.img`
display: flex;
width: 82%;
`;

const TextWrapper = styled.div`
font-size:1.5em;
width:50%;
`;

const Fattext = styled.p`
font-size: 18px;
margin-left: 10%;
`;

const LinkButtontoRoots = styled.button`
 font-size: 16px;
  border: black 1px solid;
  margin: 10px 20px;
  border-radius: 10px;
  height: 30px;
  text-align: center;
  width: 73%;
  background-color:#ffe0dd ;

`;

export default function RootsOverview() {

  const {ref: image, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true, rootMargin: "-20px -200px -100px -20px"});
  
    return (
        <PageWrapper>
         <TextWrapper>   
          <Fattext>Back To Roots – zurück <br />Und damit die Lösung für ein Gesundheit und L</Fattext>
          <p></p>
          <p></p>
            <LinkButtontoRoots>Roots</LinkButtontoRoots>
          <p></p>
          <p></p>
          <Fattext>Unser Geist, die Bewegung, Ernährung, Atmung und die Natur sind die tragenden Elemente des Lebens und bilden die Basis für die Entfaltung von Energie, Glück und Gesundheit.</Fattext>
          </TextWrapper> 
            {ImgIsVisible ? 
            <ImageWrapper ref={image}>
            <Image  alt="FU1" src="https://zeitakademie-1ad89.kxcdn.com/wp-content/uploads/2015/03/1250x700_2website-element-evolution_1250.jpg"></Image> 
            </ImageWrapper> :
            <ImageWrapper2 ref={image}>
            <Image alt="FU1" src="https://zeitakademie-1ad89.kxcdn.com/wp-content/uploads/2015/03/1250x700_2website-element-evolution_1250.jpg"></Image>
            </ImageWrapper2>
            }
            
          
        </PageWrapper>
      );
}
*/