import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import CoachingLaptop from "../images/1Home14-CoachingJoel.png"
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
justify-content: center;
background: linear-gradient(to bottom,transparent,10%, #FFFCF0,95%,transparent);
color: black;
align-items: center;
padding: 70px 0px 70px 30px;
@media (max-width: 768px) {
    width:100%;
    padding: 50px 0px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
  width: 100%;
  }
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
margin-right: 50px;
width: 50%; 
@media (max-width: 768px) {
  align-items: center;
    justify-content: center;
    width: 85%;  
    margin-right: 0px;
  }

`;

const TextHeader = styled.h1`
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;


const ImageWrapper = styled.div`
    display: flex;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
height: 200px;
@media (max-width: 768px) {
  width: 90%;
  margin-left: 30px;
  height: 100%;
  }
`;

const Text = styled.p`

`;

const Button = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  align-self: center;
  width: 50%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons};
  
}

@media (max-width: 768px) {
  margin: 0px 0px 30px 0px;
  width: 80%;
  }
`;

const Space = styled.div`
height: 40px;
`;

export default function CallToAction() {

    return (
        <PageWrapper>
          <ContentWrapper>
            <Space/>
          <NewWrapper>
          <TextBox>
            <TextHeader> 
              Bist du bereit Verantwortung für deine Gesundheit zu übernehmen?
            </TextHeader>
            <Text>Lass uns gemeinsam deinen Lebensstil optimieren.
            <br/>Mach jetzt den ersten Schritt zu voller Leistungsfähigkeit!
            </Text>
            <Button href="https://calendly.com/relife_coaching/45min" target="_blank">Kostenloses Erstgespräch <br/> vereinbaren</Button>
          </TextBox>
         <ImgBox>
            <ImageWrapper>
              <Image  alt="FU1" src={CoachingLaptop}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapper>
          <Space/>
          </ContentWrapper>  
        </PageWrapper>
      );
}
