import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import {useInView} from "react-intersection-observer";
import EBook1 from "../images/1EBook1.png";
import EBook2 from "../images/2EB6.jpg";


const PageWrapper = styled.div`
display: flex;
justify-content: center;
background: linear-gradient(to bottom,transparent,5%,#FFFCF0,95%,transparent);
align-items: center;
padding: 20px 0px 20px 0px;
@media (max-width: 768px) {
    width:100%;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`;

const NewWrapper = styled.div`
display: flex;
justify-content: center;
@media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }
`;

const TextBox = styled.div`
flex-direction: column;
width: 65%;
padding: 0px 50px 0px 20px;
@media (max-width: 768px) {
    width: 90%;
    padding:0px ;
  }
`;

const TextHeader = styled.div`
font-size: 26px;
margin-top: 90px;
font-weight: bold;

@media (max-width: 768px) {
  margin-top: 0px;
  font-size: 24px;
  width: 90%;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 35%;
@media (max-width: 768px) {
    display: none;
    ;
  }
`;

const Size = styled.div`
font-size: 26px;

@media (max-width: 768px) {
  margin-top: 0px;
  width: 90%;
  font-size: 24px;
  }
`;

const ImgBoxMobile = styled.div`
display: none;
align-items: center;
width: 35%;
@media (max-width: 768px) {
  display: flex;  
  width: 100%;
    ;
  }
`;


const ImageWrapper = styled.div`

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const Image = styled.img`
 width: 100%;

@media (max-width: 768px) {
    
  }
  
`;

const Box = styled.div`
display: flex;

@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const InputWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
`;

const Input = styled.input`
height: 35px;
width: 300px;
border: ${farben.greenButtons} 2px solid;
border-radius: 15px;
font-size: 18px;
margin-top: 10px;
color: darkgray;
text-align: center;

@media (max-width: 768px) {
  width: 90%;
  }
`;

const Button = styled.button`
  text-decoration: none;  
  font-size: 22px;
  border: none;
  margin: 15px 0px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  box-shadow: ${farben.boxshadow};
  padding: 3px 25px;

  &:hover {
  background-color: transparent;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 90%;
  margin: 15px;
  }
`;

const Form = styled.form`
width: 100%;
`;

const Div = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

const FormButtonWrapper = styled.div`
display: flex;
justify-content: center;
width:100%;
`;

const InputFormWrapper = styled.div`
display: flex;
justify-content: center;
`;

const ContentWrapper2 = styled.div`
display: flex ;
width: 100%;
align-items:center;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const TextBox2 = styled.div`
width: 100%;
display: flex;
align-items: start;

@media (max-width: 768px) {
    width: 100%;
  }
`;


const Green2 = styled.span`
  color: ${farben.greenButtons};
  font-weight: bold;
  font-size: 2em;
  height:30px;
  width: 30px;
`;

const Text3 = styled.div`
 text-align: start;
 padding-left: 20px;
 margin-bottom: 20px;
`;

export default function Intro2() {

    return (
      <>
          <PageWrapper>
          <ContentWrapper>
          <TextHeader> 
              Erfahre warum dein Lebensstil über deine Leistungsfähigkeit entscheidet! 
            </TextHeader>
            <br/>
           <Size>
           Hol dir jetzt unser kostenloses E-Book "Wunderwerk Mensch"!
            </Size> 
            <br/>
          <NewWrapper>
          <TextBox>

            <ContentWrapper2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Neuste Erkenntnisse aus 100+ Studien der größten Wissenschafts-Journals</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>5 Wertvolle Praxistipps für deine sofortige Umsetzung im Alltag</Text3>
            </TextBox2> 
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Alle entscheidenden Lebensstilfaktoren auf einen Blick</Text3>
            </TextBox2> 
            
            </ContentWrapper2>     
            <ImgBoxMobile>
            <ImageWrapper>
            <Image  alt="FU1" src={EBook1}></Image> 
            </ImageWrapper> 
          </ImgBoxMobile>
            
            <Box>
              <InputWrapper>
              <Form method="POST" action="https://relife-coaching13889.activehosted.com/proc.php" id="form_13" class="_form _form_13 _inline-form  _dark" target="_blank" novalidate>
  <input type="hidden" name="u" value="13" />
  <input type="hidden" name="f" value="13" />
  <input type="hidden" name="s" />
  <input type="hidden" name="c" value="0" />
  <input type="hidden" name="m" value="0" />
  <input type="hidden" name="act" value="sub" />
  <input type="hidden" name="v" value="2" />
  <input type="hidden" name="or" value="90569f62258b26f64ce406fbb4bd670c" />
  <div class="_form-content">

    <div class="_form_element _x10507400 _full_width _clear" >
      <div class="_html-code">
        <InputFormWrapper>
          Trage dich jetzt ein &amp; erfahre, wie du zum Superhuman wirst!
        </InputFormWrapper>
      </div>
    </div>
      <div class="_form_element _x35257079 _full_width " >
        <div class="_field-wrapper">
          <InputFormWrapper>
            <Input type="text" id="fullname" name="fullname" placeholder="Tippe deinen Vornamen ein" required/>
          </InputFormWrapper>
        </div>
      </div>

        <div class="_field-wrapper">
          <InputFormWrapper>
            <Input type="text" id="email" name="email" placeholder="Tippe deine E-Mail ein" required/>
          </InputFormWrapper>
        </div>

   
      <FormButtonWrapper>
      <Div class="_button-wrapper _full_width">
        <Button id="_form_13_submit" class="_submit" type="submit">
          Gib´s mir!
        </Button>
      </Div>
      </FormButtonWrapper>
      <div class="_clear-element">
      </div>
    </div>

  </Form>
              </InputWrapper>
            
            </Box>
            <br/>
          </TextBox>
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={EBook1}></Image> 
            </ImageWrapper> 
          </ImgBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
        </>
      );
      
}
