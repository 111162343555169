import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.90);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding-bottom: 50px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    background-color: rgba(255, 255, 255, 0.95);
  }
`;

const TextBox = styled.div`
width: 90%;
`;

const TextHeader = styled.h3`
`;

const Text = styled.p`

`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 30%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

const MiddleButton = styled.div`
display: flex;
justify-content: flex-end;
`;


export default function EinleitungII() {

    return (
        <PageWrapper>
          <TextBox>
            <TextHeader>Die Lösung für genau diese Probleme liefert dir unsere Ernährungsberatung!</TextHeader>
            <Text>Um das maximale Potential deiner gesunden Ernährung zu entfalten, greifen wir auf die Vorteile <b>unterschiedlicher Ernährungsprinzipien</b> zurück & <b>optimieren sie für dich.</b>
                <br/>
                Dabei gehen wir auf deine <b>individuellen Wünsche & Vorstellungen</b> ein, damit du beim Thema Ernährung endlich kein Fragezeichen mehr im Kopf hast.
            <br/>
            </Text>
            <Text> Buche jetzt dein kostenloses Infogespräch & lass uns gemeinsam erarbeiten wie du deine Ziele erreichst!</Text>
            <MiddleButton> 
              <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Kontaktiere uns &nbsp;&nbsp;<b>➜</b></Button2>
            </MiddleButton>  
          </TextBox>
        </PageWrapper>
      );
}

