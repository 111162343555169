import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Bullet from "../images/1Bullet.png"
import Bullet2 from "../images/2Bullet.png"
import Bullet3 from "../images/3Bullet.png"
import EB3 from "../images/2EB3.jpg"

const PageWrapper = styled.div`
display: flex;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.90);
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
padding: 30px 0px;

@media (max-width: 768px) {
    width:100%;
    padding: 30px 0px 0px 0px;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.95);
    margin-bottom: 25px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
max-width: 50%;
align-items:start;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
  max-width: 90%;
  }
`;

const NewWrapper = styled.div`
display: flex;
@media (max-width: 768px) {
  flex-direction: column;
  align-items: center;
  }

`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
align-items: start;
width: 100%;
@media (max-width: 768px) {
    width: 90%;
    align-self: center;
  }
`;

const TextHeader = styled.h1`

margin-left: 40px;
@media (max-width: 768px) {
  margin-left: 0px;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 50%;

@media (max-width: 768px) {
    width: 96%;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 90%;
    align-items: center;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 390px;
  }

  @media (max-width: 300px) {
  margin-bottom: 30px;
  width: 250px;
  }
`;

const Image = styled.img`
    width: 90%;
    box-shadow: ${farben.boxshadow};
`;

const Text = styled.span`

`;

const Schritte = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Schritt1 = styled.div`
    padding-left: 5%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      padding-left: 0%;
  }
`;

const Schritt2 = styled.div`
    padding-left: 10%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      padding-left: 0%;
  }
`;

const Schritt3 = styled.div`
    padding-left: 15%;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      padding-left: 0%;
  }
`;

const SchrittImg = styled.img`
height: 50px;
width: 50px;
margin-right: 10px;
`;

export default function HomePhilosophie() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true});

    return (
        <PageWrapper>
          <ContentWrapper>
          <TextBox>
            <TextHeader> 
              Unser Erfolgsrezept - <br/>In 3 Schritten zur optimalen Ernährung
            </TextHeader>
            <Schritte>
                <Schritt1>
                    <SchrittImg src={Bullet}></SchrittImg>
                    <Text><b>Verstehe</b> wann und warum bestimmte Ernährungsweisen für dich Sinn machen</Text>
                </Schritt1>
                <Schritt2>
                     <SchrittImg src={Bullet2}></SchrittImg>
                     <Text><b>Passe</b> deine Ernährung deinem individuellen Lebensstil <b>an</b></Text>
                </Schritt2>
                <Schritt3>
                     <SchrittImg src={Bullet3}></SchrittImg>
                     <Text><b>Integriere</b> das neue Wissen praktisch in deinen Alltag</Text>
                </Schritt3>
            </Schritte>
          </TextBox>
          </ContentWrapper>  
          <ImgBox>   
            <ImageWrapper ref={image2}>
            <Image  alt="FU1" src={EB3}></Image> 
            </ImageWrapper>  
          </ImgBox>
          
        </PageWrapper>
      );
}
