import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Garantie from "../images/3C5.png"

const PageWrapper = styled.div`
background-color: rgba(255, 255, 255, 0.95);
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 35px 0px 50px 0px;

@media (max-width: 768px) {
    width:100%;
    background-color: rgba(255, 255, 255, 0.95);
    box-shadow: 0px 10px 13px -6px grey;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;
@media (max-width: 768px) {
  flex-direction: column;
  }
`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
align-items: start;
width:80% ;
padding-left: 5%;
@media (max-width: 768px) {
  width:85% ;
  }
`;

const TextHeader = styled.h1`
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 20%;
@media (max-width: 768px) {
  width:100% ;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const Image = styled.img`
width: 90%;
height: fit-content;

@media (max-width: 768px) {
  height: 50%;
    width: 50%;
  }

`;

const Text = styled.div`

`;


export default function CGZ2() {

    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <NewWrapper>
          <TextBox>
            <TextHeader> 
                Dein Erfolg steht an erster Stelle!
            </TextHeader>
            <Text>Gegenseitiges Vertrauen und Spaß an der Zusammenarbeit bilden die Grundlage für deinen Erfolg.</Text>
            <Text>Fühlst du dich bei uns nicht gut aufgehoben, erstatten wir dir den vollen Preis – <b>ohne wenn und aber!</b></Text>
            <Text>Mehr Details findest du auf den Coaching-Seiten</Text>
          </TextBox>
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={Garantie}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>


                  </>
      );
}
