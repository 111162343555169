import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import Atmung from "../images/1Home7.1Atmung.png"
import Bewegung from "../images/1Home7.2-Bewegung.png"
import Natur from "../images/1Home7.3Natur.png"
import Ernährung from "../images/1Home7.4Ernährung.png"
import Geist from "../images/1Home7.5Geist.png"
import Headline from "./Headline.js";
import { Link, NavLink } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
background: linear-gradient(to bottom,transparent,5%,#FFFCF0,95%,transparent);
color: black;
align-items: center;
padding: 25px;
flex-wrap: wrap;
margin: 50px 0px;

@media (max-width: 768px) {
    width:100%;
    flex-direction: column;
    padding: 0px;
  }
`;

const ContentWrapper = styled.section`
align-items: center;
display: flex ;
justify-content: center;
width: 100%;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const Space = styled.div`
height: 90px;
`;

const NewWrapper = styled.div`
align-items: center;
width: 100%;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const Image = styled.img`
width: 100px;
height: 100px;

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}

@media (max-width: 768px) {
  width: 75px;
height: 75px;
align-self: center;
    
}

`;

const BigText = styled.div`
font-size: 20pt;
font-weight: bold;
text-align: center;
@media (max-width: 768px) {
  font-size: 16px;
    
}
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 40%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;

const Flex = styled.div`
  display: flex;
  justify-content: space-around;
  background-color: transparent;

  @media (max-width: 768px) {
    display: none;
    flex-direction: column; 
}
`;

const FlexII = styled.div`
  display: none;
  justify-content: space-around;
  background-color: transparent;
  padding: 20px 200px;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;  
    padding : 0px;
    width: 100%;
}
`;

const Wrapper = styled.div`
display:flex ;
flex-direction: column;
@media (max-width: 768px) {
  margin:0px 7px ; 
}

`;

const Button = styled(NavLink)`
  text-decoration: none;
  font-size: 20px;
  padding: 6px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 20%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 50%;
  height: 100%;
  }
`;

const Div = styled.div`
 
display:flex;
flex-direction: row;
justify-content: space-evenly;

@media (max-width: 768px) {
  margin-bottom: 50px;
  }
`;

const Div2 = styled.div`
 
display:flex;
flex-direction: row;
justify-content: space-evenly;
`;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;

export default function ContentBox() {
    return (
        <PageWrapper>
          <ContentWrapper>
            <Space/>
          <Headline> <b>- Roots -</b> </Headline>
          <Break/>
          <BigText> Das Fundament moderner Gesundheit</BigText>
          <Break/>
          <Zitatstrich/>
          <Break/>
          <br/>
          <NewWrapper>
          <Flex>
            <Wrapper>
              <BigText>Atmung</BigText>
              <br/>
              <Image alt="FU1" src={Atmung}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Bewegung</BigText>
              <br/>
              <Image alt="FU2" src={Bewegung}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Umwelt</BigText>
              <br/>
              <Image alt="FU3" src={Natur}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Ernährung</BigText>
              <br/>
              <Image alt="FU4" src={Ernährung}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Geist</BigText>
              <br/>
              <Image alt="FU5" src={Geist}></Image>
            </Wrapper>
          </Flex>
          <FlexII>
          <Div>
            <Wrapper>
              
              <BigText>Atmung</BigText>
              <br/>
              <Image alt="FU1" src={Atmung}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Bewegung</BigText>
              <br/>
              <Image alt="FU2" src={Bewegung}></Image>
            </Wrapper>
            </Div>
            <Div2>
            <Wrapper>
              <BigText>Umwelt</BigText>
              <br/>
              <Image alt="FU3" src={Natur}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Ernährung</BigText>
              <br/>
              <Image alt="FU4" src={Ernährung}></Image>
            </Wrapper>
            <Wrapper>
              <BigText>Geist</BigText>
              <br/>
              <Image alt="FU5" src={Geist}></Image>
            </Wrapper>
            </Div2>
          </FlexII>
          <br/>
          </NewWrapper>
          </ContentWrapper>  
          <Button to="/coachingoverview">Zu unseren Coachings</Button>
          <Space/>
        </PageWrapper>
      );
}
