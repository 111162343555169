import SimpleImageSlider from "react-simple-image-slider";
import HomeImageSlideI from "../images/91HomeSlide.jpg"
import HomeImageSlideII from "../images/91Home1Slide2.jpg"
import HomeImageSlideIII from "../images/91Home1Slide3.jpg"
import HomeImageSlideVI from "../images/91Home1Slide4.jpg"
import HomeImageSlideImobile from "../images/91Home1Slidemobile.jpg"
import HomeImageSlideIImobile from "../images/91Home1Slide2mobile.jpg"
import HomeImageSlideIIImobile from "../images/91Home1Slide3mobile.jpg"
import HomeImageSlideVImobile from "../images/91Home1Slide4mobile.jpg"
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";


const ImageContainer = styled.div`
position: relative;
text-align: center;
color: white;
font-size: 2em;

@media (max-width: 768px) {
    display: none;
  }
`;

const ImageContainer2 = styled.div`
position: relative;
text-align: center;
color: white;
display: none;


@media (max-width: 768px) {
    display: block;
    font-size: 1.5em;
    margin-top: 80px;
  }
`;

const TextContainer = styled.div`
position: absolute;
top: 38%;
left: 50%;
font-size: 1.4em;
color: white;
transform: translate(-50%, 50%);
width: 75%;

@media (max-width: 768px) {
  top: 0%;
  font-size: 1.2em;
  width: 90%;
  }
`;

const Button2 = styled(Link)`
  text-decoration: none;
  font-size: 20px;
  padding: 6px 25px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: rgba(150,201,48,.9); 
  align-items: flex-start;
  align-self: center;
  width: 50%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons};
  
}

@media (max-width: 768px) {
  margin: 0px 0px 30px 0px;
  width: 80%;
  }
`;

function ImageSlider() {
    

const images = [
  { url: HomeImageSlideI},
  { url: HomeImageSlideII },
  { url: HomeImageSlideIII },
  { url: HomeImageSlideVI },
];

const imagesmobile = [
  { url: HomeImageSlideImobile},
  { url: HomeImageSlideIImobile },
  { url: HomeImageSlideIIImobile },
  { url: HomeImageSlideVImobile },
];

return (  
  <>
    <ImageContainer>
      <SimpleImageSlider
        width={"100vw"}
        height={1020}
        images={images}
        showBullets={true}
        showNavs={true}
        slideDuration={5}
        autoPlay={false}
        autoPlayDelay={25}
      />
    <TextContainer>
      Ergebnisorientiertes Gesundheits-Coaching &
      <br/> individuelle Lebensstil-Optimierung
      <br/>
      <br/>
      <Button2 to={{pathname: "https://calendly.com/relife_coaching/45min"}} target="_blank">Info-Gespräch vereinbaren</Button2>

    </TextContainer>  
    </ImageContainer>
    <ImageContainer2>
      <SimpleImageSlider
        width={"100vw"}
        height={"80vh"}
        images={imagesmobile}
        showBullets={false}
        showNavs={false}
        slideDuration={5}
        autoPlay={true}
        autoPlayDelay={5}
      />
    <TextContainer>
      <b>ReLife</b>
      <br></br>
      <br></br>
      Ergebnisorientiertes Gesundheits-Coaching <br />& <br/> individuelle Lebensstil-Optimierung <br/><br/>
      <Button2 to={{pathname: "https://calendly.com/relife_coaching/45min"}} target="_blank"> Info-Gespräch vereinbaren</Button2>

    </TextContainer>  

    </ImageContainer2>
    
    
    </>
    );
}

export default ImageSlider;