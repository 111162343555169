import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Roots2 from "../images/PfeilKreis.png";

const PageWrapper = styled.div`
display: flex;
background-color: ${farben.cremeBackground};
color: black;
align-items: center;
padding: 10px 0px 0px 10px;
@media (max-width: 768px) {
    width:100%;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;
`;

const TextBox = styled.div`
width: 60%;
`;

const ImgBox = styled.div`
width: 40%;
`;

const TextBoxQuote = styled.div`
align-self: center;
`;

const ImageWrapper = styled.div`

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
width: 95%;
`;

const Text = styled.p`

`;

const BigText = styled.div`
font-size: 22pt;
align-self: center;
`;

const ImageWrapper2 = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    color: #79553C;
    transform: translateY(30%);
    opacity: 0;
`;

const Italic = styled.i`
display: flex;
justify-content: flex-end;
`;

export default function ContentBoxReverse() {
  const {ref: image2, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true, rootMargin: "-20px -200px -100px -20px"});

    return (
        <PageWrapper>
          <ContentWrapper>
          <TextBoxQuote>
            <BigText>“Nichts in der Biologie macht Sinn, außer im Licht der Evolution.” </BigText>
            <Italic>- Theodosius Dobzhansky</Italic>
          </TextBoxQuote>
          <NewWrapper>
          <ImgBox>
          {ImgIsVisible ? 
            <ImageWrapper ref={image2}>
            <Image  alt="FU1" src={Roots2}></Image> 
            </ImageWrapper> :
            <ImageWrapper2 ref={image2}>
            <Image alt="FU1" src={Roots2}></Image>
            </ImageWrapper2>
            }
          </ImgBox>
          <TextBox>
            <Text>Neueste Erkenntnisse aus der Epigenetik, Psychologie, Medizin, Neurologie, Trainings – und Ernährungswissenschaften zeigen in großem Umfang den <br></br> enormen Einfluss dieser Elemente <br></br>auf unsere Leben.</Text>
            <Text>Parallel bestätigen sie auch den wertvollen Erfahrungsschatz alter Kulturen und das darin enthaltene Wissen in Praktiken wie Yoga, Atemtechniken oder im Umgang mit Nahrung und der Natur.</Text>
            <Text>Die Bedeutung dieser zentralen Elemente und die Jahrmillionen andauernde Anpassung an diese, sind in den vergangenen Jahrzehnten leider immer weiter in Vergessenheit geraten.</Text>
            <Text>Zum Glück haben wir jedoch selbst die Möglichkeit, die Sensibilität und zugleich das große Potential unseres Körpers zu nutzen. Indem wir uns auf diese zentralen Elemente zurückbesinnen und sie <br></br>wieder in unseren Alltag integrieren.</Text>
          </TextBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
      );
}
