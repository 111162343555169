import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Roots1 from "../images/PfeilKreis.png";

const PageWrapper = styled.div`
display: flex;
background-color: ${farben.cremeBackground};
color: black;
align-items: center;
padding: 30px 0px 0px 30px;
@media (max-width: 768px) {
    width:100%;
    flex-direction: column;
    display: none;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;


`;

const NewWrapper = styled.div`
display: flex;
align-items: center;
height: 75vh;

@media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    
}
`;

const TextBox = styled.div`
width: 45%;
`;

const ImgBox = styled.div`
width: 55%;
`;


const ImageWrapper = styled.div`

@keyframes rootsinfromright{
    from{
        transform: translateX(30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
width: 95%;
`;

const Text = styled.p`

`;

const BigText = styled.div`
font-size: 22pt;
`;

const ImageWrapper2 = styled.div`
    display: flex;
    width: 50%;
    justify-content: center;
    color: #79553C;
    transform: translateY(30%);
    opacity: 0;
`;


export default function ContentBox() {
  const {ref: image, inView: ImgIsVisible} = useInView({ threshold:0, triggerOnce:true, rootMargin: "-20px -200px -100px -20px"});

    return (
        <PageWrapper>
          <ContentWrapper>
          <BigText>Strategie</BigText>
          <NewWrapper>
          <TextBox>
            <Text>Unser Geist, die Bewegung, Ernährung, Atmung und die Umwelt sind die tragenden Elemente des Lebens und bilden die Basis für die Entfaltung von Energie, Glück und Gesundheit.</Text>
            <Text>Die Bedeutung dieser zentralen Elemente und die Jahrmillionen andauernde Anpassung an diese, sind in den vergangenen Jahrzehnten leider immer weiter in Vergessenheit geraten.</Text>
            <Text>Das Ergebnis ist eine zunehmend unglückliche Gesellschaft mit abnehmender psychischer und physischer Gesundheit.</Text>
          </TextBox>
          <ImgBox>
          {ImgIsVisible ? 
            <ImageWrapper ref={image}>
            <Image  alt="FU1" src={Roots1}></Image> 
            </ImageWrapper> :
            <ImageWrapper2 ref={image}>
            <Image alt="FU1" src={Roots1}></Image>
            </ImageWrapper2>
            }
          </ImgBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
      );
}
