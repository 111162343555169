import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import SelbstErlebt from "../images/1Home13SelbstErlebt.jpg"


const PageWrapper = styled.div`
display: flex;
background: linear-gradient(to bottom,transparent,10%, #FFFCF0,95%,transparent);
color: black;
align-items: center;
padding: 10px 0px 30px 30px;
margin-top: 50px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;
@media (max-width: 768px) {
  flex-direction: column-reverse;
  }
`;

const TextBox = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 60%;

@media (max-width: 768px) {
  width:85% ;
  }
`;

const TextHeader = styled.h1`
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
@media (max-width: 768px) {
  justify-content:center ;
  }
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;
    width: 450px;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
  width:90% ;
  height: 100%;
  }
`;

const Image = styled.img`
height: 100%;
box-shadow: ${farben.boxshadow};

@media (max-width: 768px) {
  width:100% ;
  }
`;

const Text2 = styled.p`
margin-top:50px;
`;

export default function AnwendungderRoots() {

const [checked,setChecked]= React.useState(true);

console.log("Wert ist " + checked)

const Klapptext = styled.input`
display: none;
appearance: none;
border-radius: .45em;
cursor: pointer;

:hover{
color: transparent;
}

::before{
  content: " Klicken zum Einklappen... ";
  color: grey;
  font-size: 20px;
  white-space: pre-wrap;
}

:checked::before  {
  content:"";
  font-size: 20px;
  border: 2px solid black;
  border-radius: .45em;
  padding: .25em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  ${checked ? "display:none" : "display:flex"}
  }
`;

const Klapptext2 = styled.input`
display: none;
appearance: none;
border-radius: .45em;
cursor: pointer;

:hover{
color: transparent;
}

::before{
  content: "Klicken zum Weiterlesen...";
  text-decoration:underline;
  color: grey;
  font-size: 20px;
  white-space: pre-wrap;
}

:checked::before  {
  content: " Klicken zum Weiterlesen... ";
  text-decoration:underline;
  font-size: 20px;
  border: 2px solid black;
  border-radius: .45em;
  padding: .25em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  ${checked ? "display:none" : "display:flex"}
  transition: 1s;
  }
`;

const Text = styled.div`
 
 @media (max-width: 768px) {

  height: ${ checked ? "calc(9 * 1em * 1.4)" : "none"};
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;

  ::before{
  content: "";
  position: absolute;
  height: ${ checked ? "calc(5em * 1.4 )" : "none"};
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, #fffcf0);
}

  }

`;

const Text5 = styled.div`
  text-align: start;
 margin-bottom: 20px;
 @media (max-width: 768px) {

}

`;

const ContentWrapper2 = styled.div`
display: flex ;
width: 100%;
align-items:center;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const TextBox2 = styled.div`
width: 80%;
display: flex;
align-items: start;

@media (max-width: 768px) {
    width: 100%;
  }
`;

const Green2 = styled.span`
  color: ${farben.greenButtons};
  font-weight: bold;
  font-size: 2em;
  height:30px;
  width: 30px;
`;

const Text3 = styled.div`
 text-align: start;
 padding-left: 20px;
 margin-bottom: 20px;
`;

const Space = styled.div`
height: 90px;
`;

    return (
        <PageWrapper>
          <ContentWrapper>
          <Space/>
          <NewWrapper>
          <ImgBox>   
            <ImageWrapper>
            <Image  alt="FU1" src={SelbstErlebt}></Image> 
            </ImageWrapper>  
          </ImgBox>
          <TextBox>
            <TextHeader> 
              5 Roots - Die Lösung
            </TextHeader>
            <Text5>
              Durch die Anwendung der 5 ROOTS adressieren wir die ganze Breite negativer Einflussfaktoren, welche die ursprüngliche Leistungsfähigkeit deines Körpers einschränken und dich davon abhalten dein volles Potential auszuschöpfen.
              <br/>
              <br/>
              Dabei fokussieren wir uns auf wissenschaftlich fundierte Themen, wie z.B.
              <br/>
             </Text5> 
            <ContentWrapper2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Qualität und Nährstoffverteilung deiner Nahrung</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Beweglichkeit & Gelenkgesundheit</Text3>
            </TextBox2> 
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Entgiftung & Schadstoffreduktion</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Körperliche Aktivität & Auswirkungen auf den Hormonhaushalt</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Gewichtsregulation</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Stressregulation durch Atem- & Kältetraining</Text3>
            </TextBox2>
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Schlafqualität & viele Weitere</Text3>
            </TextBox2>
            </ContentWrapper2> 
            <Text>
             
              Durch unseren ganzheitlichen Ansatz & die Arbeit in einem Netzwerk von unterschiedlichen Ärzten und Experten nutzen wir in unserem Coaching das gesamte Spektrum an gesundheitsfördernden Möglichkeiten um „dein Fass zu leeren“.
              <br/>
              Indem wir diese gezielt miteinander kombinieren und individuell in deinen Alltag integrieren, entfalten sie ihr riesiges Potential für deine Gesundheitsoptimierung.
              <br />
            </Text>
            <Klapptext type="checkbox" onChange={() => setChecked(!checked)}/>
            <Klapptext2 type="checkbox" onChange={() => setChecked(!checked)}/>
            <Text2>
              <b>
                So helfen wir dir deine Ziele zu erreichen, Körper und Geist noch besser zu verstehen & endlich dein volles Potential zu leben!
              </b>
            </Text2>
          </TextBox>
          </NewWrapper>
          <Space/>
          </ContentWrapper>  
        </PageWrapper>
      );
}
