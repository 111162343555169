import React from "react";
import styled from "styled-components";
import farben from "../components/GlobalStyles.js";
import {useInView} from "react-intersection-observer";
import EBook1 from "../images/1EBook1.png";
import pdf from "../images/1EBook1.png";

const PageWrapper = styled.div`
display: flex;
justify-content: center;
background: linear-gradient(to bottom,transparent,5%,#FFFCF0,95%,transparent);
align-items: center;
padding: 20px 0px 20px 0px;
@media (max-width: 768px) {
    width:100%;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
align-items: center;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
    align-items: center;
    width: 100%;
  }
`;

const NewWrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
@media (max-width: 768px) {
    width: 90%;
    flex-direction: column;
    align-items: center;
  }
`;

const TextBox = styled.div`
flex-direction: column;
@media (max-width: 768px) {
    width: 90%;
    padding:0px ;
  }
`;

const TextHeader = styled.h1`
font-size: 26px;
text-align: center;

@media (max-width: 768px) {
  margin-top: 100px;
  }
`;

const ImgBox = styled.div`
display: flex;
align-items: center;
width: 35%;
@media (max-width: 768px) {
    display: none;
    ;
  }
`;

const ImgBoxMobile = styled.div`
display: none;
align-items: center;
width: 35%;
@media (max-width: 768px) {
  display: flex;  
  width: 100%;
    ;
  }
`;


const ImageWrapper = styled.div`

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const Image = styled.img`
 width: 100%;

@media (max-width: 768px) {
    height: 100%;
  }
  
`;

const Button = styled.button`
  text-decoration: none;  
  font-size: 22px;
  border: none;
  margin: 15px 0px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  box-shadow: ${farben.boxshadow};
  padding: 3px 25px;

  &:hover {
  background-color: transparent;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  width: 90%;
  margin: 15px;
  }
`;

const Anker = styled.a`
text-decoration: none;
color: white;
`;

const ContentWrapper2 = styled.div`
display: flex ;
width: 100%;
align-items:center;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const TextBox2 = styled.div`
width: 100%;
display: flex;
align-items: start;

@media (max-width: 768px) {
    width: 100%;
  }
`;

const Green2 = styled.span`
  color: ${farben.greenButtons};
  font-weight: bold;
  font-size: 2em;
  height:30px;
  width: 30px;
`;

const Text3 = styled.div`
 text-align: start;
 padding-left: 20px;
 margin-bottom: 20px;
`;

function DownloadEbook() {
    
    return (
        <>
            <PageWrapper>
            <ContentWrapper>
            <NewWrapper>
            <TextBox>
              <TextHeader> Herzlichen Glückwunsch! <br/> Du hast es gegschafft!</TextHeader>
              <ContentWrapper2>
              <TextBox2>
                  <Green2>✓</Green2>
                  <Text3>Neuste Erkenntnisse aus 100+ Studien der größten Wissenschafts-Journals</Text3>
              </TextBox2>
              <TextBox2>
                <Green2>✓</Green2>
                <Text3>5 Wertvolle Praxistipps für deine sofortige Umsetzung im Alltag</Text3>
            </TextBox2> 
            <TextBox2>
                <Green2>✓</Green2>
                <Text3>Alle entscheidenden Lebensstilfaktoren auf einen Blick</Text3>
            </TextBox2> 
              </ContentWrapper2>     
              <ImgBoxMobile>
              <ImageWrapper>
              <Image  alt="FU1" src={EBook1}></Image> 
              </ImageWrapper> 
            </ImgBoxMobile>
              
              <br/>
            </TextBox>
            <ImgBox>
              <ImageWrapper>
              <Image  alt="FU1" src={EBook1}></Image> 
              </ImageWrapper> 
            </ImgBox>
            </NewWrapper>
            <Button id="_form_1_submit" class="_submit" type="submit">
          <Anker href={pdf} target="_blank" rel="noreferrer" download="E-Book Wunderwerk Mensch">
            Hier downloaden!
          </Anker>
        </Button>
            </ContentWrapper>  
          </PageWrapper>
          </>
        );
}

export default DownloadEbook;



      
