import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import Sprung1 from "../images/1Home1Sprung.jpg"
import Garantie from "../images/91Home3Geldzurück.png"
import Arrow from "../images/91Arrow.png"
import SubHeadline from "./Subheadline.js";
import { NavLink } from "react-router-dom";

const PageWrapper = styled.div`
display: flex;
color: black;
align-items: center;
padding: 0px 0px 50px 0px;
margin-bottom: 150px;

@media (max-width: 968px) {
    width:100%;
    margin-bottom: 0px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;
`;

const NewWrapper = styled.div`
display: flex;
justify-content: center;

@media (max-width: 968px) {
    width:100%;
    flex-direction: column;
    align-items: center;
  }
`;

const TextBox = styled.div`
flex-direction: column;
width: 40%;
padding: 100px 0px 30px 0px;

@media (max-width: 968px) {
    width: 85%;
    flex-direction: column-reverse;
    padding: 50px 0px 30px 0px;
  }
`;

const ImgBox = styled.div`
padding: 100px 0px 30px 100px;
@media (max-width: 968px) {
    padding: 0px;
    height: 75vh;
  }
`;

const Img = styled.img`
position: relative;
left: -40%;
bottom: -55vh;
height: 150px;

@media (max-width: 968px) {
    display: none;
  
  }
`;

const Img2 = styled.img`
display: none;

@media (max-width: 968px) {
  display: none;
  height: 150px;
  padding-left: 55px;
  }
`;

const Img3 = styled.img`
display: none;

@media (max-width: 968px) {
  display: block;
  height: 150px;
  padding-left: 55px;
  }
`;

const Breaks = styled.div`
@media (max-width: 968px) {
  display: none;
}
`;

const ImageWrapper = styled.div`
    position: absolute;

    @media (max-width: 968px) {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}
`;

const Image = styled.img`
height: 70vh;
box-shadow: ${farben.boxshadow};
@media (max-width: 968px) {
    height: 100%;
    width: 90%;
  }
`;

const ArrowImage = styled.img`
height: 1.5em;
margin-right: 10px;
@media (max-width: 768px) {
 
    
  }
`;

const Text = styled.p`
font-size: 18px;
@media (max-width: 768px) {
  font-size: 16px;
    
}
`;

const Button = styled(NavLink)`
  text-decoration: none;
  font-size: 20px;
  padding: 6px 25px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 60%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 968px) {
  display: none;
  margin-bottom: 30px;
  width: 80%;
  }
`;

const Button2 = styled(NavLink)`
  text-decoration: none;
  font-size: 20px;
  display: none;
  padding: 6px;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 60%;
  box-shadow: ${farben.boxshadow};


  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  }
`;

export default function Intro2() {

    return (
        <PageWrapper>
          <ContentWrapper>
          <NewWrapper>
          <TextBox>
            <SubHeadline> Wir optimieren deinen Lebensstil für nachhaltige Gesundheit und maximale Leistungsfähigkeit!</SubHeadline>
            <Text>
            <br />
            <Breaks>
            <br />
            <br /> 
            </Breaks>   
            <ArrowImage src={Arrow}></ArrowImage><span>           Verstehe was dein Körper braucht</span> <p></p>
            <ArrowImage src={Arrow}></ArrowImage><span>           Optimiere deine Gewohnheiten</span> <p></p>
            <ArrowImage src={Arrow}></ArrowImage><span>           Entfalte dein volles Potential</span></Text>
            <br/>
            <br/>
            <Button to="/coachingoverview">Zu unseren Coachings</Button>
            <br/>
          </TextBox>
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={Sprung1}></Image> 
            </ImageWrapper>
            <Img src={Garantie} /> 
          </ImgBox>
          <Img3 src={Garantie} /> 
          <Button2 to="/coachingoverview">Zu unseren Coachings</Button2>
          <Img2 src={Garantie} /> 
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
      );
}