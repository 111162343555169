import styled from "styled-components";
import { useEffect, useState } from "react";
import Axios from "axios";

// Brauchen wir erst in der Zukunft



function Umwelt() {

    const [rezeptListe, setRezeptListe] = useState([]); 
    const [isLoading, setLoading] = useState(true);

    useEffect(()=>{
        Axios.get("http://192.168.178.40:3003/get").then((response)=>{
            setRezeptListe(response.data)
            console.log(response.data)
            setLoading(false);
        })
        
        

    },[])

const [count, setCount] = useState(1);

let rezepte = [];
    for(var i=1; i<=rezeptListe.length; i++) {
       rezepte.push(i.toString());
    }
    console.log("Was" + rezepte)


if (isLoading) {
    return (
        <>Loading...</>
      );
} else {
return (  
        <>
        Umwelt
        </> 
    );
}


}

export default Umwelt;