import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"
import {useInView} from "react-intersection-observer"
import Fass from "../images/1Home12Fassmobile.png"


const PageWrapper = styled.div`
display: flex;
justify-content: center;
background-color: transparent;
color: black;
align-items: center;
padding: 10px 0px 30px 30px;
margin-top: 50px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const ContentWrapper = styled.section`
display: flex ;
width: 90%;
justify-content: center;
line-height: 200%;
flex-direction: column;
font: ${farben.fontQuote};
font-size: 16px;

@media (max-width: 768px) {
  width: 100%;
  }
`;

const NewWrapper = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
  flex-direction: column;
  }
`;



const TextHeader = styled.h1`
`;

const ImgBox = styled.div`
display: flex;
align-items: center;

@media (max-width: 768px) {
margin-top:50px;  
}
`;


const ImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    height: 500px;

@keyframes rootsinfromleft{
    from{
        transform: translateX(-30%);
        opacity: 0;
    }
    to{
        transform: translateX(0);
        display: flex;
    }
}

@media (max-width: 768px) {
height: 375px;
}
`;

const Image = styled.img`
height: 100%;

@media (max-width: 768px) {
width: 100%  ;
margin-top: 50px;

&:hover {
  transform: scale(1);
  transition: all 0.3s linear;
}
}

&:hover {
  transform: scale(1.15);
  transition: all 0.3s linear;
}
`;

const Text = styled.p`
width: 100%;
`;



export default function EigenerWeg() {

  const [checked,setChecked]= React.useState(true);

  const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media (max-width: 768px) {
  
  height: ${ checked ? "calc(15 * 1em * 2.4)" : "none"};
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
  padding: 30px;
  
  ::before{
  content: "";
  position: absolute;
  height: ${ checked ? "calc(5em * 2.4 )" : "none"};
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, white);
  }
  
  }
  
  `;

const Klapptext = styled.input`
display: none;
appearance: none;
border-radius: .45em;
cursor: pointer;

:hover{
color: transparent;
}

::before{
  content: " Klicken zum Einklappen... ";
  text-decoration: underline;
  color: grey;
  font-size: 22px;
  white-space: pre-wrap;
}

:checked::before  {
  content:"";
  font-size: 20px;
  border: 2px solid ${farben.green};
  border-radius: .45em;
  padding: .25em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  ${checked ? "display:none" : "display:flex"}
  }
`;

const Klapptext2 = styled.input`
display: none;
appearance: none;
border-radius: .45em;
cursor: pointer;

:hover{
color: transparent;
}

::before{
  content: "Klicken zum Weiterlesen...";
  color: grey;
  text-decoration: underline;
  font-size: 20px;
  white-space: pre-wrap;
}

:checked::before  {
  content: "⇩";
  font-size: 20px;
  border: 2px solid ${farben.green};
  border-radius: .45em;
  padding: .25em;
  margin-top: 10px;
}

@media (max-width: 768px) {
  ${checked ? "display:none" : "display:flex"}
  transition: 1s;
  }
`;

    return (
      <>
        <PageWrapper>
          <ContentWrapper>
          <NewWrapper>
          <TextBox>
            <TextHeader> 
              Denn wir haben es selbst erlebt und einen Weg gefunden..
            </TextHeader>
            <TextBox></TextBox>
            <Text>Von rheumatischen Gelenkentzündungen und Energielosigkeit hin zu neuer Lebensenergie, körperlicher Leistungsfähigkeit und geistiger Klarheit in unserem Arbeitsalltag.</Text>
            <Text>Durch unseren persönlichen Weg haben wir gelernt neueste wissenschaftliche Erkenntnisse mit dem Erfahrungsschatz unterschiedlicher Kulturen zu verbinden und sie individuell in unser Leben zu integrieren. 
                  <br/>Davon profitieren unsere Klienten heute, indem wir sie mit unserem Wissen auf ihrem individuellen Weg begleiten.</Text>
            <Text>Der Großteil aller modernen Krankheitsbilder ist multifaktoriell. 
                  <br/>Dies bedeutet, dass die Ursache deiner Symptome nicht, wie weitverbreitet angenommen, auf genau ein einziges Problem zurückzuführen ist. </Text>
            <Text>Viel mehr bringt das Zusammenspiel der vielen neuartigen Umwelt- und Lebensstilfaktoren „das Fass“ zum überlaufen. Diese überlasten deinen Körper zunehmend, schränken deine Leistungsfähigkeit ein und führen letztendlich zu unterschiedlichsten Symptomen.</Text>
            <Text>Auf biochemischer Ebene resultieren diese negativen Einflussfaktoren in den selben gestörten Prozessen unseres Stoffwechsels:  Oxidativer Stress, Entzündungsprozesse sowie ein überreiztes Immunsystem.
                  <br />Heute wissen wir, dass das gestörte Gleichgewicht dieser Prozesse die Grundlage für unsere modernen chronischen Beschwerden und Krankheiten (Autoimmunkrankheiten, Arthritis, Diabetes, Alzheimer & Demenz, Arteriosklerose,..) bilden.</Text>
          </TextBox>
          <Klapptext type="checkbox" onChange={() => setChecked(!checked)}/>
          <Klapptext2 type="checkbox" onChange={() => setChecked(!checked)}/>
            
          <ImgBox>
            <ImageWrapper>
            <Image  alt="FU1" src={Fass}></Image> 
            </ImageWrapper>  
          </ImgBox>
          </NewWrapper>
          </ContentWrapper>  
        </PageWrapper>
                  </>
      );
}
