import React from "react";
import styled from "styled-components";
import C13 from "../../images/3C13.png";
import C131 from "../../images/3C131.png";

const PageWrapper2 = styled.div`
color: black;
align-items: center;
justify-content: center;
width: 90%;    
align-self: center;
line-height: 200%;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;


const Image = styled.img`
width: 100%;

@media (max-width: 768px) {
    display: none;
  }
`;

const ImageMobile = styled.img`
    display: none;
@media (max-width: 768px) {
    display:block;
    width:100%;
  }
`;

export default function FC13() {

    return (
        <>
        <PageWrapper2>
           <Image src={C13} />
           <ImageMobile src={C131} />
        </PageWrapper2>
        </>
      );
}



