import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js";
import { Link } from "react-router-dom";

const PageWrapper2 = styled.div`
display: flex;
flex-direction: column;
width: 90%;
line-height: 200%;
background-color: rgba(255, 255, 255, 0.9);
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding-bottom: 60px;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
    line-height: 170%;
  }
`;

const TextBox = styled.div`
width: 90%;
display: flex;
flex-direction: column;
justify-content: center;
padding-left: 40px;

@media (max-width: 768px) {
  padding-left: 20px;
  }
`;

const TextHeader = styled.h2`
margin: 70px 0px 30px 0px;

@media (max-width: 768px) {
  margin: 0px 0px 30px 0px;
  }
`;

const Text = styled.p`

`;

const ContentWrapper = styled.section`
display: flex ;
width: 100%;
align-items:center;
line-height: 200%;
flex-direction: row;
font: ${farben.fontQuote};
font-size: 16px;
@media (max-width: 768px) {
  line-height: 170%;  }
`;

const Button2 = styled.a`
  text-decoration: none;
  font-size: 20px;
  padding: 16px;
  line-height: 150%;
  border: none;
  margin: 10px 20px;
  border-radius: 10px;
  border: solid 2px ${farben.greenButtons};
  color: white;
  text-align: center;
  background-color: ${farben.greenButtons}; 
  align-items: flex-start;
  width: 30%;
  box-shadow: ${farben.boxshadow};

  &:hover {
  background-color: beige;
  color: ${farben.greenButtons}
}

@media (max-width: 768px) {
  margin-bottom: 30px;
  display:block;
  width: 80%;
  font-size: 16px;
  }
`;

export default function EB9Function() {

    return (
        <>
        <PageWrapper2> 
        <ContentWrapper>              
          <TextBox>
          <TextHeader> 
              Was hat Ernährung mit chronischen Beschwerden und Krankheiten zu tun?
          </TextHeader> 
            <Text>
                Wie wir heute wissen sind unsere modernen chronischen Krankheiten in fast allen Fällen auf weinige gestörte Prozesse des Stoffwechsels zurückzuführen (hier genauer beschrieben). Die gestörten Prozesse kann man durch sämtliche Lebensstilfaktoren sowohl zum Positiven als auch Negativen beeinflussen und somit die Beschwerden lindern oder sogar völlig lösen. Insb. Autoimmunerkrankungen z.B. sind ein Signal dafür, dass der Körper besonders sensibel auf diese Lebensstilfaktoren reagiert. <br/>
                Studien an den letzten Naturvölkern zeigen zum einen, dass unsere modernen Krankheiten (wie z.B. Diabetes, Herz-Kreislauf-Erkrankungen, Rheuma, Demenz,...) kaum bis gar nicht existieren. Zum anderen demonstrieren sie, dass verschiedene Aspekte ihrer Ernährung auch in unserer westlichen Welt eine enorme präventive und therapeutische Wirkung haben. 
            </Text>
            <br/>
            <TextHeader> 
                Warum ist eine optimale Ernährung nur Individuell möglich?
            </TextHeader> 
            <Text>
                Allgemeine und unspezifische Ernährungsberatung, wie sie leider häufig angeboten wird, nimmt keine Rücksicht auf die vielfältigen Vor- und Nachteile der verschiedenen Lebensmittel und unterschiedlichen Ernährungsprinzipien. <br />
                Die optimale Ernährung ist immer individuell! Sie ist abhängig von den genetischen Voraussetzungen, dem Lebensstil und den Lebensumständen, der körperlichen Verfassung bzw. dem Gesundheitszustand und zuletzt natürlich von den persönlichen Zielen. Außerdem muss unterschieden werden zwischen einer generellen ausgewogenen Ernährung und der Ernährung in Form einer effektiven Intervention (z.B. Ketogen) um gezielt Krankheiten und Beschwerden zu therapieren.<br />
                Leider ist diese differenzierte Einordnung nicht sehr verbreitet, da viele Experten und Coaches die Auswirkung von Ernährung auf chronische Beschwerden oder Krankheiten nicht am eigenen Leib erfahren haben und dabei die komplexen wissenschaftliche Erkenntnisse verallgemeinern und damit vereinfachen.<br />
                Außerdem haben sich viele Schlüsse der Ernährungswissenschaft als fehlerhaft erwiesen, da die evolutionäre Anpassung unserer Biologie und Studien an den heutigen Naturvölker in der Vergangenheit nicht berücksichtigt wurden.<br />
                Um unsere langjährigen chronischen Beschwerden loszuwerden mussten wir selbst den verwirrenden Weg durch diese Chaos finden und wissen heute, wie dich diese Unsicherheit überfordert und dir die Motivation nimmt. 
            </Text>
          </TextBox>
          </ContentWrapper>
          <br />
          <br />
          <Button2 href="https://calendly.com/relife_coaching/45min" target="_blank">Buche jetzt dein kostenfreies Gespräch</Button2>
        </PageWrapper2>
        
        </>
      );
}
