import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
  display: flex;
  //background: linear-gradient(to bottom, grey,1%, beige 90%, grey);
  background: transparent;
  flex-direction: column;
  color: black;
  font-size: 22px;
  justify-content: center;
  font-weight: bold;
  width: 100%;
  text-align: center;
  line-height: 200%;
  margin: 15px 0px;
@media (max-width: 768px) {

  }
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 30%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

    @media (max-width: 768px) {
    width: 65%;
  }

`;

const Zitat = styled.section`
  
  font: ${farben.fontQuote};


@media (max-width: 768px) {
    
}
`;

export default function ZitatI() {

    return (
        <PageWrapper>
         <Zitatstrich/> 
         <Zitat>
          „Gesundheit ist nicht bloß die Abwesenheit von Krankheit <br></br>
          - sondern der Zugang zur vollen Leistungsfähigkeit“
         </Zitat>
         <Zitatstrich/>
        </PageWrapper>
      );
}
