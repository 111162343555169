import React from "react";
import styled from "styled-components";
import farben from "./GlobalStyles.js"

const PageWrapper = styled.div`
background-color: rgba(255, 255, 255, 0.9);
display: flex;
flex-direction:column ;
width: 90%;
line-height: 200%;
color: black;
align-items: center;
justify-content: center;
align-self: center;
padding: 20px 0px;

@media (max-width: 768px) {
    
    padding: 10px 0px;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
  }
`;


const Zitat = styled.h2`
  
  font: ${farben.fontQuote};
text-align:center;


@media (max-width: 768px) {
    font-size: 1.1em;
}
`;

const Zitatstrich = styled.hr`
  display: block;
  width: 60%;
  height: 1em;
  border: 0;
  background: 
    linear-gradient(
      to right, 
      transparent, #000000, transparent
    ) center / 100% 3px no-repeat,
    linear-gradient(
      to right, 
      transparent, 
      transparent 50%, 
      transparent 50%, 
      transparent
    ) center / 100% 3px no-repeat;

`;

const Break = styled.br`
@media (max-width: 768px) {
    display: none;
  }
`;

export default function ZitatV() {

    return (
        <PageWrapper>
         <Zitatstrich/> 
         <Zitat>
            „Ernährung ist nicht die Lösung für Alles aber die <Break />
            Basis für alle Lösungen“ 
         </Zitat>
         <Zitatstrich/>
        </PageWrapper>
      );
}
