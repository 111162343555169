import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const PageWrapper2 = styled.div`
display: flex;
color: beige;
background-color: #33251C;
align-items: center;
justify-content: center;
width: 100%;    
align-self: center;
line-height: 200%;
text-align: center;

@media (max-width: 768px) {
    width:100%;
    padding: 10px 0px;
  }
`;

const FooterS = styled.footer`
width: 90%;
`;

const P = styled(NavLink)`
margin: 10px 10px;
color: white;
text-decoration: none;
`;

export default function Footer() {

    const year = new Date().getFullYear();

    return (
        <>
        <PageWrapper2>
            <FooterS>{`Copyright © ReLife Coaching ${year}`}</FooterS>
            <> 
                 <P to="/kontakt">Kontakt</P>
            </>
        </PageWrapper2>
        </>
      );
}


