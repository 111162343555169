import React from "react";
import styled from "styled-components";
import Carousel from "react-elastic-carousel";
import CardForCarousel from "../components/CardForCarousel";
import Karussel1 from "../images/1Karussel1.png"
import Karussel2 from "../images/1Karussel2.png"
import Karussel3 from "../images/1Karussel3.png"
import Karussel4 from "../images/1Karussel4.png"
import farben from "./GlobalStyles";

const Card = styled.span`
  display: flex;
  border-radius: 10px;
  margin: 5px 5px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  .rec.rec-arrow {
    border-radius: 0;
    color: ${farben.green};
    background-color: rgba(255,255,255,0.95);
    
}

.rec.rec-arrow:hover {
    border-radius: 50%;
    background-color: ${farben.green};
    color: rgba(255,255,255,0.95);
}

button.rec-dot{
  visibility: hidden;
}


.rec.rec-arrow:disabled {
    visibility: hidden;
}
`;

const Ha2 = styled.h2`
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(0, 160, 238);
  box-shadow: -6px -9px 22px hsla(300, 15%, 25%, 0.2) inset;
  height: 2em;
`;

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 750, itemsToShow: 3 },
];

export default function Gallerie() {
  return (
    <>
      <Card>
        <Carousel breakPoints={breakPoints}  >
          <CardForCarousel sorc={Karussel1}/>
          <CardForCarousel sorc={Karussel2}/>
          <CardForCarousel sorc={Karussel3}/>
          <CardForCarousel sorc={Karussel4}/>
          </Carousel> 
      </Card>
    </>
  );
}
